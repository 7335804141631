const questions = [
  //C/C++
  {
    question:
      'Jaki typ służy do przechowywania wartości TRUE/FALSE?',
    cat:'C/C++',
    url: '',
    a1: 'bool',
    a2: 'byte',
    a3: 'char',
    a4: 'decimal',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Typ "bool" jest natywnym typem do przechowywania wartości prawda/fałsz mimo, że równie dobrze moglibyśmy się posłużyć 0 i 1 np. w zmiennej całkowitej. W części języków typ ten może nazywać się "boolean".',
  },
  {
    question:
      'Jaki typ służy do przechowywania wartości całkowitych z zakresu 0 do 255?',
    cat:'C/C++',
    url: '',
    a1: 'unsigned char',
    a2: 'unsigned short',
    a3: 'char',
    a4: 'short',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W pytaniu tym chodzi o zakres typów całkowitych w C/C++. Typ char przechowuje liczby w zakresie -128 do 127 a unsigned char w zakresie 0 do 255. Świadomość zakresów i tego, że są inne typy danych niż "int" dobrze świadczy o programiście.',
  },
  {
    question:
      'Czy typy float i double są identyczne?',
    cat:'C/C++',
    url: '/images/floatdouble.jpg',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Typ "double" przechowuje dane w 8 bajtach a float tylko w 4bajtach.',
  },
  {
    question:
      'Czy typy "int" i "signed int" są identyczne?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Zazwyczaj typy są domyślnie "signed". Jeżeli chcemy "unsigned" musimy to napiać.',
  },
  {
    question:
      'Czy typy "int" i "unsigned int" są identyczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Zazwyczaj typy są domyślnie "signed". Jeżeli chcemy "unsigned" musimy to napisać.',
  },
  {
    question:
      'Czy pętle do..while oraz while są identyczne?',
    cat:'C/C++',
    url: '/images/dowhilewhile.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pętla do...while wykona się zawsze przynajmniej raz, ponieważ najpierw wykonywana jest iteracja a dopiero po niej sprawdzany jest warunek.',
  },
  {
    question:
      'Aby wypisać odpowiednio string, znak, liczbę całkowitą ze znakiem oraz liczbę zmiennoprzecinkową za pomocą funkcji "printf()" należy posłużyć się ciągiem formatującym?',
    cat:'C/C++',
    url: '',
    a1: '%s %c %d %f',
    a2: '%f %c %s %u',
    a3: '%d %u %f %c',
    a4: '%c %s %u %x',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Funkcja "printf()" to odpowiednik std::cout z języka C. Pozwala ona wypisywać tekst na ekranie według ciągu formatującego podanego w pierwszym parametrze np. "printf("Wynik: %u", wynik);"',
  },
  {
    question:
      'Strukturą danych typu FILO (First In Last Out) jest?',
    cat:'C/C++',
    url: '',
    a1: 'Stos',
    a2: 'Kolejka',
    a3: 'Lista',
    a4: 'Sterta',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'FILO - First In Last Out. Taką strukturą jest stos. Chodzi tutaj o kolejność umieszczania danych w kolekcji oraz kolejność ich pobierania z niej.',
  },
  {
    question:
      'Która struktura danych może być zaimplementowana przy wykorzystaniu jedynie wymienionych metod?',
    cat:'C/C++',
    url: '/images/stos.png',
    a1: 'Stos',
    a2: 'Tablica',
    a3: 'Kolejka FIFO (First In First Out)',
    a4: 'Drzewo Binarne',
    tag: 'OFICJALNE',
    type: '4A',
    desc: 'Jest to pytanie oficjalne. Tak naprawdę poprawnych odpowiedzi mogło by być wiele, ponieważ kto nam broni nazwać metodę dodającą do kolejki wartość w ten sam sposób jak w przypadku stosu.',
  },
  {
    question:
      'Strukturą danych FIFO (First In First Out) jest?',
    cat:'C/C++',
    url: '',
    a1: 'Kolejka',
    a2: 'Stos',
    a3: 'Lista',
    a4: 'Sterta',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'FIFO - First In First Out. Taką strukturą jest kolejka.',
  },
  {
    question:
      'Określenie kolekcji mianem FILO jest równoznaczne z określeniem jej jako LIFO',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'FILO - First In Last Out. LIFO - Last In First Out. Odwrotny zapis.',
  },
  {
    question:
      'Elementem języka, który pozwala definiować własne typy jest?',
    cat:'C/C++',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'klasa',
    a3: 'struktura',
    a4: 'typ wyliczeniowy enum',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zarówno klasa jak i struktura służy do definicji własnego typu złożonego, który składa się z typów prostych lub innych typów złożonych.',
  },
  {
    question:
      'Słowo kluczowe służące do definiowania typu wyliczeniowego w większości popularnych języków to?',
    cat:'C/C++',
    url: '',
    a1: 'enum',
    a2: 'list',
    a3: 'tuple',
    a4: 'dictionary',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Enum to typ wyliczeniowy w praktycznie każdym stosowanym dzisiaj języku. Pozwala ograniczyć możliwe do wpisania wartości np. do zmiennej.',
  },
  {
    question:
      'Elementem języka, który pozwala określić skończony zestaw wartości możliwy do przypisania zmiennej jest?',
    cat:'C/C++',
    url: '',
    a1: 'enum',
    a2: 'set',
    a3: 'tuple',
    a4: 'makro',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Enum to typ wyliczeniowy w praktycznie każdym stosowanym dzisiaj języku. Pozwala ograniczyć możliwe do wpisania wartości np. do zmiennej.',
  },
  {
    question:
      'Elementem języka C++, który pozwala określić skończony zestaw wartości możliwy do przypisania zmiennej jest?',
    cat:'C/C++',
    url: '',
    a1: 'enum class',
    a2: 'set',
    a3: 'tuple',
    a4: 'select',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Enum Class to typ wyliczeniowy typowy dla C++. Różni się on od podstawowego Enum. Więcej w materiałach.',
  },
  {
    question:
      'Funkcja main w C/C++ zawsze musi zwrócić wartość 0?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie musi. Przyjeło się, że 0 oznacza sukces czyli poprawne wykonanie programu. Przerwanie programy wcześniej z jakiegoś powodu powinno zwracać coś innego.',
  },
  {
    question:
      'W C++ konstrukcje enum oraz enum class działają identycznie?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Różnica jest bardzo duża. Enum jest mapowany na liczbę przez co np. A::Option1==B::Option1 da prawdę mimo, że A i B to różne enumy (0 == 0). W przypadku enum class porównanie takie nie zadziała już na etapie kompilacji bo A i B są różnymi typami. Więcej w materiałach.',
  },
  {
    question:
      'Jeżeli jako typ zwracany podamy "void*" oznacza to, że nic nie zwracamy?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Void* to wskaźnik na typ nie określony. Funkcja zwracająca "void*" zwraca wskaźnik.',
  },
  {
    question:
      'Jeżeli jako typ zwracany podamy "void" oznacza to, że nic nie zwracamy?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Void oznacza, że funkcja nic nie zwraca, ale nie wyklucza istnienia w jej ciele słowa kluczowego return',
  },
  {
    question:
      'Czy w C/C++ możliwe jest zwrócenie z funkcji wartości jeżeli ta funkcja ma typ zwracany "void"?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli przekażemy do funkcji parametr przez wskaźnik lub referencję i go zmodyfikujemy to zobaczymy zmiany po powrocie z funckji mimo, że nie użyliśmy "return".',
  },
  {
    question:
      'Czy w funkcji zwracającej "void" można użyć słowa kluczowego "return"?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Można. Po słowie return nie pada żadna wartość/zmienna.',
  },
  {
    question:
      'W C++ każda zmienna domyślnie przekazywana jest przez wartość?',
    cat:'C/C++',
    url: '/images/copy.jpg',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Oznacza to, że funkcja tworzy sobie kopię tej wartości i na niej pracuje a nie na oryginale. Jeżeli chcemy uzyskać efekt typu referencyjnego i uniknąć kopii musimy użyć wskaźnika lub referencji &.',
  },
  {
    question:
      'Wywołanie funkcji przez samą siebie to?',
    cat:'C/C++',
    url: '',
    a1: 'rekurencja',
    a2: 'iteracja',
    a3: 'callback',
    a4: 'przeciążenie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W przypadku rekurencji ważne jest by kontrolować ilość zagłębień. Stosując rekurencje bardzo łatwo jest przekroczyć rozmiar stosu, ponieważ każda instancja funkcji alokuje tam swoje zmienne lokalne.',
  },
  {
    question:
      'Słowo kluczowe, które pozwala pominąć bieżącą iterację to?',
    cat:'C/C++',
    url: '',
    a1: 'continue',
    a2: 'break',
    a3: 'return',
    a4: 'skip',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Słowo kluczowe przerywające pętle to?',
    cat:'C/C++',
    url: '',
    a1: 'break',
    a2: 'continue',
    a3: 'static',
    a4: 'skip',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Na obrazku widzimy?',
    cat:'C/C++',
    url: '/images/decl.png',
    a1: 'deklaracje funkcji',
    a2: 'definicję funkcji',
    a3: 'wywołanie funkcji',
    a4: 'definicję wskaźnika na funkcję',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Deklaracja to poinformowanie kompilatora, że gdzieś będzie zdefiniowana dana funkcja/zmienna. Definicja zawiera już ciało lub przypisanie wartości w przypadku zmiennych.',
  },
  {
    question:
      'Adres w pamięci nazywany jest',
    cat:'C/C++',
    url: '/images/ptr.png',
    a1: 'Wskaźnikiem',
    a2: 'Referencją',
    a3: 'Delegatem',
    a4: 'Lambdą',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Adres to po prostu liczba. Każdy bajt pamięci ma swój adres.',
  },
  {
    question:
      'Instrukcja C++ "cout" znajduje się w namespace?',
    cat:'C/C++',
    url: '',
    a1: 'std',
    a2: 'console',
    a3: 'common',
    a4: 'system',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'std::cout, std::cin itd',
  },
  {
    question:
      'Instrukcja C++ "cin" znajduje się w namespace?',
    cat:'C/C++',
    url: '',
    a1: 'std',
    a2: 'console',
    a3: 'common',
    a4: 'system',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'std::cout, std::cin itd',
  },
  {
    question:
      'Typ "string" C++ znajduje się w namespace?',
    cat:'C/C++',
    url: '',
    a1: 'std',
    a2: 'string',
    a3: 'common',
    a4: 'system',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'std::cout, std::cin itd',
  },
  {
    question:
      'Program napisany w C/C++ może zawierać więcej niż jedną funkcję "main"?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie może. Możliwe jest jednak nazwanie funkcji wejściowej inaczej i wykorzystanie funkcji main do innego celu.',
  },
  {
    question:
      'Funkcja main w C/C++ przyjmuje jakieś parametry?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak, przyjmuje dokładnie dwa (ale można zostawić pusty nawias). Liczbę całkowitą z ilością argumentów programu int argc i tablicę stringów w formie char** argv. Co to za parametry? Wywołując program z cmd po spacji możemy podać jakieś argumenty. Każdy zapisywany jest jako string w tej tablicy.',
  },
  {
    question:
      'Słowo kluczowe "this" w C/C++ jest wskaźnikiem?',
    cat:'C/C++',
    url: '/images/this.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak, a do wartości odwołujemy się operatorem "->" np. "this->name"',
  },
  {
    question:
      'Ile konstruktorów można umieścić w klasie C++?',
    cat:'C/C++',
    url: '',
    a1: 'dowolną ilość',
    a2: '1',
    a3: '2',
    a4: '3',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Ile destruktorów można umieścić w klasie C++?',
    cat:'C/C++',
    url: '',
    a1: '1',
    a2: '2',
    a3: '3',
    a4: 'dowolną ilość',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy destruktory mogą przyjmować parametry?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Jeżeli w języku C deklarując/definiując funkcję nawias na parametry zostawimy pusty?',
    cat:'C/C++',
    url: '',
    a1: 'funkcja ta przyjmuje nieokreśloną z góry ilość argumentów',
    a2: 'funkcja nie przyjmuje żadnych argumentów',
    a3: 'funkcja przyjmuje jeden parametr',
    a4: 'jest to błąd składniowy',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Pusty nawias oznacza nieokreśloną ilość parametrów. Możemy w takim przypadku podawać ich ile chcemy a później się do nich w odpowiedni sposób odwołać. Jeżeli w nawiasie jest "void" to nie ma parametrów np. void make(void){}',
  },
  {
    question:
      'W strukturze C/C++ wszystkie elementy są domyślnie prywatne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'To jest jedyna różnica względem klas w C++. W C# różnice są dużo większe (!)',
  },
  {
    question:
      'W klasie C++ wszystkie elementy są domyślnie prywatne?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'To jest jedyna różnica względem struktur w C++. W C# różnice są dużo większe (!)',
  },
  {
    question:
      'Jedyną błędną z punktu widzenia języka C++ konstrukcją jest?',
    cat:'C/C++',
    url: '',
    a1: 'print("Hello world");',
    a2: 'printf("Hello world");',
    a3: 'std::cout<<"Hello world";',
    a4: 'cout<<"Hello world";',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C++ nie ma takiej funkcji jak "print()"',
  },
  {
    question:
      'Metodą klasy std::string zwracającą długość łańcuch tekstowego jest?',
    cat:'C/C++',
    url: '',
    a1: 'length()',
    a2: 'len()',
    a3: 'size()',
    a4: 'count()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metodą klasy std::string zwracającą podłańcuch łańcucha tekstowego jest?',
    cat:'C/C++',
    url: '',
    a1: 'substr()',
    a2: 'substring()',
    a3: 'slice()',
    a4: 'cut()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metodą klasy std::string zwracającą indeks pierwszego wystąpienia podłańcucha w łańcuchu tekstowym jest?',
    cat:'C/C++',
    url: '',
    a1: 'find()',
    a2: 'rfind()',
    a3: 'compare()',
    a4: 'filter()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Łańcuch tekstowy (string) w C/C++ może zostać umieszczony między apostrofami?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pojedynczy znak można umieścić między apostrofami. String tylko w cudzysłowach.',
  },
  {
    question:
      'Klasa std::string przeciąża operator []?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak. Zwraca on znak pod danym indeksem. Stringi to gdzieś pod spodem tablice znaków.',
  },
  {
    question:
      'Instrukcje ++a oraz a++ są równoznaczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli zrobimy b = a++ oraz b = ++a to zobaczymy różnice. W pierwszym przypadku najpierw do b przypiszemy a, a potem zwiększymy a. W drugim najpierw następuje inkrementacja a później przypisanie.',
  },
  {
    question:
      'Słowo kluczowe "const" oznacza stałą?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Kompilatory czasami w ramach optymalizacji same oznaczają zmienne jako stałe gdy zobaczą, że dany obiekt jest tylko czytany.',
  },
  {
    question:
      'Instrukcja "#define" służy do tworzenia makr preprocesora?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Przed kompilacją w trakcie działania preprocesora wszystkie miejsca gdzie wykorzystaliśmy makro są podmieniane na jego wartość.',
  },
  {
    question:
      'Makra preprocesora działają w C/C++ tak samo jak stałe?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Makro to nie jest zmienna/stała. Przykład: "#define PI 3.13". Wszystkie wystąpienia PI w kodzie zostaną podmienione na 3.14 jeszcze przed właściwą kompilacją.',
  },
  {
    question:
      'Operatory & oraz && są równoznaczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pierwszy to operator bitowy a drugi to operator logiczny.',
  },
  {
    question:
      'Operatory | oraz || są równoznaczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pierwszy to operator bitowy a drugi to operator logiczny.',
  },
  {
    question:
      'Operatory ^ oraz ~ są równoznaczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pierwszy to bitowa różnica symetryczna (XOR) a drugi to negacja (NOT).',
  },
  {
    question:
      'Operator ^ to operator potęgowania?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jest to operator różnicy symetrycznej (bitowy XOR)',
  },
  {
    question:
      'Który operator wykonuje operację modulo?',
    cat:'C/C++',
    url: '',
    a1: '%',
    a2: '#',
    a3: '!',
    a4: '^',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Który operator wykonuje operację różnicy symetrycznej?',
    cat:'C/C++',
    url: '',
    a1: '^',
    a2: '~',
    a3: '!',
    a4: '|',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem C++ nie jest',
    cat:'C/C++',
    url: '',
    a1: 'extern',
    a2: 'new',
    a3: 'delete',
    a4: 'sizeof',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wszystkie opcje to słowa kluczowe C++. Operatorem nie jest tylko "extern".',
  },
  {
    question:
      'Który rodzaj operatora wymaga trzech operandów?',
    cat:'C/C++',
    url: '',
    a1: 'trynarny',
    a2: 'binarny',
    a3: 'unarny',
    a4: 'żaden z powyższych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jak sama nazwa wskazuje :) Przykład: "int a = b == 2 ? 1 : 4;"',
  },
  {
    question:
      'Operator indeksowania to?',
    cat:'C/C++',
    url: '',
    a1: '[]',
    a2: '()',
    a3: '{}',
    a4: '<>',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W Javie często stosuje się metody do pobierania wartości z pod danego indeksu np. "kolekcja.get(2)".',
  },
  {
    question:
      'Operator statku kosmicznego to?',
    cat:'C/C++',
    url: '',
    a1: '<=>',
    a2: '==',
    a3: '<>',
    a4: '><',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"<=>" jest operatorem statku kosmicznego. Został wprowadzony w C++20 i służy do porównywania obiektów klas.',
  },
  {
    question:
      'Pliki źródłowe języka C++ mają rozszerzenie?',
    cat:'C/C++',
    url: '',
    a1: '.cc',
    a2: '.c',
    a3: '.cs',
    a4: '.h',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Tak, *.cc to również rozszerzenie plików C++, ale i tak każdy pisze *.cpp',
  },
  {
    question:
      'Pliki źródłowe języka C mają rozszerzenie?',
    cat:'C/C++',
    url: '',
    a1: '.c',
    a2: '.cc',
    a3: '.cs',
    a4: '.cpp',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pliki nagłówkowe języka C/C++ mają rozszerzenie?',
    cat:'C/C++',
    url: '',
    a1: '.h',
    a2: '.cc',
    a3: '.cs',
    a4: '.cpp',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Błędnym składniowo zapisem w C/C++ jest?',
    cat:'C/C++',
    url: '',
    a1: 'for();',
    a2: 'for(;;);',
    a3: 'while(1){}',
    a4: 'while(!0){}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Pętla for wymaga w nawiasie minimum dwóch średników.',
  },
  {
    question:
      'Pętlą dostępną w C/C++ nie jest?',
    cat:'C/C++',
    url: '',
    a1: 'foreach',
    a2: 'for',
    a3: 'while',
    a4: 'do..while',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Co nie oznacza, że nie ma w nim pętli o podobnym charakterze: "for(auto x in collection){}"',
  },
  {
    question:
      'Typem, który nie jest dostępny w C/C++ jest?',
    cat:'C/C++',
    url: '',
    a1: 'number',
    a2: 'int',
    a3: 'char',
    a4: 'void',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Które słowo jest słowem kluczowym C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'switch',
    a2: 'ref',
    a3: 'out',
    a4: 'dynamic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Które słowo jest słowem kluczowym C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'typename',
    a2: 'msr',
    a3: 'where',
    a4: 'like',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Które słowo NIE jest słowem kluczowym C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'peek',
    a2: 'if',
    a3: 'extern',
    a4: 'volatile',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"volatile" to słowo kluczowe umieszczane przy definicji zmiennej. Mówi ono kompilatorowi by nie optymalizować operacji na tej zmiennej, a zostawił je tak jak zostały napisane.',
  },
  {
    question:
      'Które słowo NIE jest słowem kluczowym C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'lambda',
    a2: 'class',
    a3: 'goto',
    a4: 'constexpr',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"volatile" to słowo kluczowe umieszczane przy definicji zmiennej. Mówi ono kompilatorowi by nie optymalizować operacji na tej zmiennej, a zostawił je tak jak zostały napisane.',
  },
  {
    question:
      'Język C++ jest nadzbiorem języka C?',
    cat:'C/C++',
    url: '/images/ccpp.jpg',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Wszystkie elementy C są dostępne w C++.',
  },
  {
    question:
      'Wartość "nullptr" to dokładnie to samo co "NULL"?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'NULL to tak naprawdę liczba 0, nullptr to specjalny typ, który ma zastąpić NULL. Ma to znaczenie przy przeciążaniu funkcji, gdyż NULL może zostać potraktowany jako int a nie jako wskaźnik.',
  },
  {
    question:
      'Zapis "const char* a;" oraz "char* const a" są równoznaczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W pierwszym przypadku stała jest wartość, na którą wskazuje wskaźnik. W drugim stały jest adres w zmiennej wskaźnikowej.',
  },
  {
    question:
      'Wartość wyrażenia "!true" w C/C++ jest równoznaczna?',
    cat:'C/C++',
    url: '',
    a1: '0',
    a2: '1',
    a3: 'true',
    a4: 'undefined',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Negacja logiczna prawdy to fałsz.',
  },
  {
    question:
      'Ile razy wykona się pętla "while(999999)" w C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'nieskończenie wiele',
    a2: 'ani razu',
    a3: '1',
    a4: '999999',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda wartość inna niż false/0 to prawda. Pętla wykona się nieskończenie wiele razy. To to samo co while(true){}.',
  },
  {
    question:
      'Ile razy wykona się pętla "while(0)" w C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'ani razu',
    a2: 'nieskończenie wiele',
    a3: '1',
    a4: '999999',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda wartość inna niż false/0 to prawda. Pętla wykona się nieskończenie wiele razy. To to samo co while(true){}.',
  },
  {
    question:
      'Zakładając, że "int a = 2;" wartość przypisana do zmiennej wynosi "int b = a++;"?',
    cat:'C/C++',
    url: '',
    a1: '2',
    a2: '3',
    a3: '0',
    a4: 'wystąpi błąd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najpierw wykonane zostanie przypisanie a potem inkrementacja oryginalnej zmiennej.',
  },
  {
    question:
      'Zakładając, że "int a = 2;" wartość przypisana do zmiennej wynosi "int b = ++a;"?',
    cat:'C/C++',
    url: '',
    a1: '3',
    a2: '2',
    a3: '0',
    a4: 'wystąpi błąd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najpierw następuje inkrementacja a później przypisanie (kłania się kolejność operatorów).',
  },
  {
    question:
      'Struktura i unia w C/C++ to to samo?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Unia to struktura, która przyjmuje rozmiar największego elementu składowego. Pozostałe się na niego nakładają.',
  },
  {
    question:
      'Referencja to to samo co wskaźnik?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Referencja to wskaźnik z okrojonymi możliwościami. Jest bezpieczniejsza, ponieważ nie da się na niej wykonywać działań arytmetycznych',
  },
  {
    question:
      'Iterator to obiekt, który ułatwia przemieszczanie się po kontenerze?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Iterator to obiekt pewnej klasy, który ma ułatwić poruszanie się po kolekcji. Dostarcza np. takie metody jak next(), prev(). Iterator to taka nakładka abstrakcji, która ma ułatwić i poprawić uniwersalność kodu.',
  },
  {
    question:
      'W języku C i C++ możliwe jest pobranie adresu funkcji i zapisanie go we wskaźniku?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Istnieje w C/C++ coś takiego jak wskaźnik na funkcję. Funkcja: "int add(int a, int b);" wskaźnik: "int(addPtr*)(int,int) = add;"',
  },
  {
    question:
      'Funkcja main w C/C++ przyjmuje dwa argumenty. Pierwszym z nich jest liczba całkowita określająca ilość parametrów przekazanych do pliku exe z konsolii + 1?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '+1 ponieważ pierwszym parametrem jest ścieżka do tego exeka',
  },
  {
    question:
      'Operatorem pobrania adresu w C/C++ jest?',
    cat:'C/C++',
    url: '',
    a1: '&',
    a2: '&&',
    a3: '|',
    a4: '*',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przykład: "int* a_ptr = &a;"',
  },
  {
    question:
      'Aby pobrać adres tablicy w C/C++ należy posłużyć się operatorem?',
    cat:'C/C++',
    url: '',
    a1: 'żadnym z pozostałych',
    a2: '&',
    a3: '|',
    a4: '*',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Nie jest konieczenie użycie operatora "&", ponieważ nazwa tablicy jest wskaźnikiem sama w sobie. Podobnie jest z adresem funkcji.',
  },
  {
    question:
      'Operatorem wyłuskania w C/C++ jest?',
    cat:'C/C++',
    url: '',
    a1: '*',
    a2: '&',
    a3: '|',
    a4: '%',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wyłuskanie to pobranie wartości, na którą wskazuje wskaźnik: "int a = *a_ptr;"',
  },
  {
    question:
      'Operatorem bitowym nie jest?',
    cat:'C/C++',
    url: '',
    a1: '&&',
    a2: '&',
    a3: '|',
    a4: '^',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem logicznym nie jest?',
    cat:'C/C++',
    url: '',
    a1: '&',
    a2: '&&',
    a3: '||',
    a4: '!',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem logicznym nie jest?',
    cat:'C/C++',
    url: '',
    a1: '<<',
    a2: '<=',
    a3: '>=',
    a4: '<',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem zasięgu jest?',
    cat:'C/C++',
    url: '',
    a1: '::',
    a2: '()',
    a3: '->',
    a4: '.',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem trynarnym jest?',
    cat:'C/C++',
    url: '',
    a1: '?:',
    a2: '::',
    a3: '->',
    a4: '<=>',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem unarnym jest?',
    cat:'C/C++',
    url: '',
    a1: '!',
    a2: '>=',
    a3: '<=',
    a4: '==',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Słowo kluczowe "static" w C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'powoduje, że element klasy jest wspólny dla wszystkich obiektów i jest przypisany do klasy',
    a3: 'powoduje, że zmienna lokalna zachowuje się jak zmienna globalna',
    a4: 'powoduje, że element globalny (zmienna, funkcja) jest widoczna tylko w pliku gdzie została zdefiniowana',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Słowo kluczowe "static" ma trzy funkcje.',
  },
  {
    question:
      'Słowem kluczowym służący do zwalniania zaalokowanej pamięci w C++ jest?',
    cat:'C/C++',
    url: '',
    a1: 'delete',
    a2: 'free',
    a3: 'volatile',
    a4: 'static',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Dynamiczne tworzenie obiektu w C/C++ odbywa się za pomocą konstrukcji?',
    cat:'C/C++',
    url: '/images/stackheap.jpg',
    a1: 'MyClass* myObj = new MyClass();',
    a2: 'MyClass myObj = new MyClass();',
    a3: 'MyClass myObj = MyClass();',
    a4: 'MyClass* myObj = MyClass();',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Operator "new" zwraca wskaźnik!',
  },
  {
    question:
      'W C++ dla każdego wystąpienia operatora "new" powinno pojawić się wystąpienie operatora "delete"?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli nie zwolnimy pamięci dynamicznie alokowanej to następuje jej wyciek. Nie zawsze jest to groźne, ale zwalnianie pamięci powinno być obowiązkowym nawykiem.',
  },
  {
    question:
      'Czy kod: "int size = 10; int tablica[size];" jest poprawny?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Przy takiej definicji tablicy rozmiar musi być stałą. Próbujemy tu zdefiniować tablicę statyczną. Jej rozmiar musi być znany w czasie KOMPILACJI.',
  },
  {
    question:
      'Czy kod: "int size = 10; int* tablica = new int[size];" jest poprawny?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Przy dynamicznej alokacji tablicy rozmiar może być zmienny. Próbujemy tu zdefiniować tablicę dynamiczną.',
  },
  {
    question:
      'W C++ tablica statyczna alokowana jest w następujący sposób?',
    cat:'C/C++',
    url: '',
    a1: 'int tablica[10];',
    a2: 'int* tablica = new int[10];',
    a3: 'int** tablica = new int*[10];',
    a4: 'int* tablica = new int[10];',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Tablica statyczna nazywana jest statyczną, ponieważ ze względu na miejsce gdzie jest alokowana w pamięci jej rozmiar musi być znany w momencie kompilacji.',
  },
  {
    question:
      'W C++ tablica dynamiczna alokowana jest w następujący sposób?',
    cat:'C/C++',
    url: '',
    a2: 'int* tablica = new int[10];',
    a1: 'int tablica[10];',
    a3: 'int* tablica = new[10];',
    a4: 'int tablica = new int[10];',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Tablica dynamiczna alokowana jest na stercie. Tak zaalokowaną pamięć trzeba zwalniać.',
  },
  {
    question:
      'W C++ tablica dynamiczna zwalniana jest w następujący sposób?',
    cat:'C/C++',
    url: '',
    a2: 'delete[] tablica;',
    a1: 'delete tablica;',
    a3: 'free[] tablica;',
    a4: 'free tablica;',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Tablica dynamiczna alokowana jest na stercie. Tak zaalokowaną pamięć trzeba zwalniać.',
  },
  {
    question:
      'Do rzucania wyjątków w C++ służy słowo kluczowe?',
    cat:'C/C++',
    url: '',
    a1: 'throw',
    a2: 'new',
    a3: 'raise',
    a4: 'catch',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C++ jest to throw. W C# podobnie. Python używa słowa raise.',
  },
  {
    question:
      'Konstrukcja języka C++ służąca do łapania rzucanych wyjątków to?',
    cat:'C/C++',
    url: '',
    a1: 'try..catch..',
    a2: 'try..except..',
    a3: 'try..handle..',
    a4: 'try..finally..',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C++, C#, Javie służy do tego blok "try...catch...". W pythonie zamiast catch wystepuje except.',
  },
  {
    question:
      'Operacje powodujące wystąpienie wyjątku',
    cat:'C/C++',
    url: '',
    a1: 'powinny być umieszczone w bloku try',
    a2: 'powinny być umieszczone w bloku exception',
    a3: 'powinny być umieszczone w bloku throw',
    a4: 'powinny być umieszczone w bloku catch',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W bloku "catch" decydujemy co zrobić gdy ten wyjątek nastąpi.',
  },
  {
    question:
      'Pusty konstruktor jest automatycznie generowany przez kompilator w C++?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Kompilatory generują tylko pusty podstawowy konstruktor bez argumentów.',
  },
  {
    question:
      'Konstruktor kopiujący jest automatycznie generowany przez kompilator w C++?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Kompilatory generują tylko pusty podstawowy konstruktor bez argumentów.',
  },
  {
    question:
      'Konstruktor posiada typ zwracany?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Konstruktor to metoda o nazwie takiej jak klasa bez typu zwracanego. W pythonie konstruktor to __init__(self)',
  },
  {
    question:
      'Metoda oznaczona jako "friend" w C++ ma dostęp do prywatnych pól klasy?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Funkcja zaprzyjaźniona zachowuje się jak składowa klasy.',
  },
  {
    question:
      'Konstruktor kopiujący powinien przyjmować obiekt, który chcemy skopiować w formie referencji typu "const T&"?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Referencja powoduje, że nie jest tworzona zbędna kopia obiektu, a "const" informuje, że nie musimy się martwić o to, że obiekt kopiowany zostanie przypadkiem zmodyfikowany.',
  },
  {
    question:
      'W C++ każda definicja klasy musi być zakończona nie tylko klamrą, ale też średnikiem?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak, ponieważ po klamrze kończącej klasę możemy od razu tworzyć jej instancje.',
  },
  {
    question:
      'C++ jest językiem interpretowanym?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Komputer 64 bitowy może przyjąć dowolną ilość ramu?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Komputery 64 bitowe też mają limit, jednak jest on tak duży, że ludzie zaczęli twierdzić, że go nie ma. W teorii możemy uzyskać 2 ^ 64 (potęga) bajtów.',
  },
  {
    question:
      'Pętla "while(file->EOF){}" będzie wykonywać się do momentu?',
    cat:'C/C++',
    url: '',
    a1: 'aż wskaźnik pozycji w pliku wskaże jego koniec',
    a2: 'aż wskaźnik pozycji w pliku wskaże jego początek',
    a3: 'aż wskaźnik pozycji w pliku wskaże koniec aktualnej linii',
    a4: 'żadne z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'EOF to skrót od End Of File',
  },
  {
    question:
      'W C++ wartość liczbową można wypisać w systemie szesnastkowym za pomocą "cout":',
    cat:'C/C++',
    url: '',
    a1: 'std::cout << std::hex << n << "\n;"',
    a2: 'std::cout << std::dec << n << "\n";',
    a3: 'std::cout << std::oct << n << "\n";',
    a4: 'std::cout << std::bin << n << "\n";',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Jaki plik nagłówkowy trzeba dołączyć do kodu by można było użyć "cout" i "cin"?',
    cat:'C/C++',
    url: '',
    a1: 'iostream',
    a2: 'string',
    a3: 'stdio',
    a4: 'cstdio',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Jaki plik nagłówkowy trzeba dołączyć do kodu by można było użyć w C++ stringów',
    cat:'C/C++',
    url: '',
    a1: 'string',
    a2: 'cstring',
    a3: 'stdlib',
    a4: 'stdint',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C++ aby wylosować liczbę z zakresu 1 do 100 należy posłużyć się instrukcją?',
    cat:'C/C++',
    url: '',
    a1: 'rand() % 100 + 1;',
    a2: 'rand() % 100;',
    a3: 'rand() % 99 + 1;',
    a4: 'rand() % 99;',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Funkcja "rand()" zwraca wartość od 0 od "RAND_MAX". Funkcja modulo ogranicza maksymalny przedział (łatwo sobie to wyobrazić jak ktoś rozumie modulo) a wartość dodawan przesuwa zakres o pewien offset.',
  },
  {
    question:
      'W C++ string można zdefiniować za pomocą konstrukcji?',
    cat:'C/C++',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'std::string text = "Ala ma kota";',
    a3: 'const char* text = "Ala ma kota";',
    a4: 'char text[12] = "Ala ma kota";',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Oprócz typu "string", który na marginesie znajduje się w przestrzenii "std" w C++ można używać składni C. String to tablica znaków zakończona 0, aby program wiedział gdzie kończy się string. Obie konstrukcje powyżej dodają 0 automatycznie. Wersja ze wskaźnikiem nie pozwala modyfikować stringu.',
  },
  {
    question:
      'W C++ string można zdefiniować za pomocą konstrukcji?',
    cat:'C/C++',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'std::string text = "Ala ma kota";',
    a3: 'const char* text = "Ala ma kota";',
    a4: 'char text[12] = {\'A\',\'l\',\'a\',\' \',\'m\',\'a\',\' \',\'k\',\'o\',\'t\',\'a\', 0};',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Oprócz typu "string", który na marginesie znajduje się w przestrzenii "std" w C++ można używać składni C. String to tablica znaków zakończona 0, aby program wiedział gdzie kończy się string. Obie konstrukcje powyżej dodają 0 automatycznie. Wersja ze wskaźnikiem nie pozwala modyfikować stringu.',
  },
  {
    question:
      'Która z poniższych konstrukcji nie pozwala zmodyfikować napisu np. poprzez podmianę elementu tablicy operatorem []?',
    cat:'C/C++',
    url: '',
    a1: 'const char* text = "Ala ma kota";',
    a2: 'char text[12] = "Ala ma kota";',
    a3: 'std::string text = "Ala ma kota";',
    a4: 'char text[12] = {\'A\',\'l\',\'a\',\' \',\'m\',\'a\',\' \',\'k\',\'o\',\'t\',\'a\', 0};',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Stringi typu "const char*" są umieszczane w pamięci w sekcji tylko do odczytu. Jeżeli napiszemy cout<<"Hello"; to ten napis też jest typu "const char*".',
  },
  {
    question:
      'Co to jest funkcja inline w C++?',
    cat:'C/C++',
    url: '',
    a1: 'funkcja, która zostanie wklejona w miejsce wywołania zamiast normalnego wywołania',
    a2: 'funkcja jednolinijkowa',
    a3: 'funkcja, która nic nie zwraca',
    a4: 'funkcja optymalizowana mocniej niż ma to miejsce normalnie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to funkcja, która zostanie wklejona w miejsce wywołania zamiast normalnego wywołania. Normalne wywołanie wiąże się ze skokiem, alokacją stosu itp. Małe funkcje można ustawić na inline.',
  },
  {
    question:
      'W C++ funkcja biblioteki "cmath" służąca do potęgowania to?',
    cat:'C/C++',
    url: '',
    a1: 'pow(x,y);',
    a2: 'power(x,y);',
    a3: 'power(x);',
    a4: 'pow(x);',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Funkcja pow w pierwszym parametrze przyjmuje wartość a w drugim stopień potęgi.',
  },
  {
    question:
      'W C++ funkcja biblioteki "cmath" służąca do obliczania wartości logarytmu naturalnego to?',
    cat:'C/C++',
    url: '',
    a1: 'log(x);',
    a2: 'l(x);',
    a3: 'hypot(x);',
    a4: 'cbrt(x);',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C++ funkcja biblioteki "cmath" służąca do obliczania wartości bezwzględnej to?',
    cat:'C/C++',
    url: '',
    a1: 'abs(x);',
    a2: 'aps(x);',
    a3: 'mod(x);',
    a4: 'unsigned(x);',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C++ funkcje biblioteki "cmath" "ceil()" oraz "floor()" są równoznaczne?',
    cat:'C/C++',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '"ceil" zaokrągla do najbliższej liczby całkowitej w góre natomiast "floor" zaokrągla do najbliższej liczby całkowitej mniejszej niż podana wartość.',
  },
  //numeric systems
  {
    question:
      'Zapisane w kodzie szesnastkowym składowe RGB koloru #AA41FF po przekształceniu do kodu dziesiętnego wynoszą kolejno',
    cat:'Systemy Liczbowe',
    url: '',
    a1: '170,65,255',
    a2: '160,65,255',
    a3: '160,64,255',
    a4: '170,64,255',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Liczba 0xAA to w systemie binarnym?',
    cat:'Systemy Liczbowe',
    url: '',
    a1: '0b10101010',
    a2: '0b11001100',
    a3: '0b00110011',
    a4: '0b01010101',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda cyfra w hex to nybel. Każdy nybel to 4 bity w systemie binarnym. 0xA == 0b1010 więc 0xAA to 0x10101010',
  },
  {
    question:
      'Liczba 0xFF to w systemie binarnym?',
    cat:'Systemy Liczbowe',
    url: '',
    a1: '0b11111111',
    a2: '0b11011101',
    a3: '0b10111011',
    a4: '0b01000100',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '0xFF to maksymalna wartość do zapisania w unsigned char/byte.',
  },
  {
    question:
      'Liczba 256 to w systemie szesnastkowym?',
    cat:'Systemy Liczbowe',
    url: '',
    a1: '0xFF',
    a2: '0x0FF',
    a3: '0x100',
    a4: '0x255',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przekraczamy maksymalną wartość unsigned bajtu (0xFF). Jeżeli typem jest unsigned bajt wpisanie do niego 256 da 0. Jeżeli jest to większy typ normalnie zobaczmy 0x100',
  },
  {
    question:
      'Liczba 0b10000000 to w systemie szesnastkowym?',
    cat:'Systemy Liczbowe',
    url: '',
    a1: '0x80',
    a2: '0x7F',
    a3: '0x88',
    a4: '0x89',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to często używana liczba. Dobrze pamiętać. 0x80 to 0b10000000 a 0x7F to 0b01111111.',
  },
  {
    question:
      'Jeżeli najmniej znaczący bit w liczbie jest ustawiony na 1 to liczba jest parzysta.',
    cat:'Systemy Liczbowe',
    url: '/images/msblsb.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli bit najbardziej po prawej jest 1 to liczba jest nieparzysta.',
  },
  {
    question:
      'Wartość wyrażenia !5 w C++ to?',
    cat:'Systemy Liczbowe',
    url: '',
    a1: 'false',
    a2: 'undefined',
    a3: '1',
    a4: '0xE',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda liczba inna niż 0 to prawda. ! to logiczna negacja. Z całości wychodzi 0 czyli fałsz.',
  },
  {
    question:
      'Wartość wyrażenia 0xDEADC0DE jest zapisana w systemie oznaczanym jako?',
    cat:'Systemy Liczbowe',
    url: '',
    a1: 'HEX',
    a2: 'DEC',
    a3: 'OCT',
    a4: 'BIN',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Prefix 0x służy do zapisu liczb szesnastkowych. Prefix 0b służy do zapisu liczb binarnych. Liczby w systemie ósemkowym poprzedza się po prostu cyfrą 0.',
  },
  {
    question:
      'Kodowanie liczb ujemnych odbywa się za pomocą metody',
    cat:'Systemy Liczbowe',
    url: '',
    a1: 'Dopełnień do 2',
    a2: 'Dopełnień do 1',
    a3: 'Znak moduł',
    a4: 'IEEE 754',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'U2 to najlepsza metoda zapisu liczb ujemnych. IEEE 754 to standard zapis liczb ułamkowych.',
  },
  {
    question:
      'Kodowanie liczb zmiennoprzecinkowych odbywa się za pomocą standardu',
    cat:'Systemy Liczbowe',
    url: '',
    a1: 'IEEE 754',
    a2: 'IEEE 1284',
    a3: 'IEEE 1394',
    a4: 'U2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'IEEE 1284 to standard starego złącza LPT. IEEE 1394 to standard starego złącza FireWire. U2 to system uzupełnień do 2 służący do zapisu liczb ujemnych.',
  },
  //algorytmy
  {
    question:
      'Złożoność obliczeniowa to ilość głównych operacji do wykonania względem ilości danych wejściowych?',
    cat:'Algorytmy',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Przez pojęcie głównych operacji rozumiemy zazwyczaj ilość iteracji lub przejść funkcji.',
  },
  {
    question:
      'Przykładem algorytmu typu dziel i zwyciężaj jest?',
    cat:'Algorytmy',
    url: '',
    a1: 'quick-sort',
    a2: 'insert-sort',
    a3: 'alg. dijkstra',
    a4: 'alg kruskala',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Algorytm quick-sort dzieli zbiór danych na mniejsze części i je sortuje.',
  },
  {
    question:
      'Przykładem algorytmu zachłannego jest?',
    cat:'Algorytmy',
    url: '',
    a1: 'alg. dijkstra',
    a2: 'insert-sort',
    a3: 'select-sort',
    a4: 'quick-sort',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy graf na obrazku jest skierowany?',
    cat:'Algorytmy',
    url: '/images/rzadgrafu.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Strzałki na krawędziach mówią czy graf jest skierowany.',
  },
  {
    question:
      'Czy graf na obrazku jest skierowany?',
    cat:'Algorytmy',
    url: '/images/grag.svg',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Strzałki na krawędziach mówią czy graf jest skierowany.',
  },
  {
    question:
      'Czy graf na obrazku jest drzewem?',
    cat:'Algorytmy',
    url: '/images/notree.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Drzewo to graf, w którym nie ma cyklu. Wymogiem jest również to by z każdego wierzchołka dało się dojść do każdego innego.',
  },
  {
    question:
      'Czy graf na obrazku jest drzewem?',
    cat:'Algorytmy',
    url: '/images/tree.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Drzewo to graf, w którym nie ma cyklu. Wymogiem jest również to by z każdego wierzchołka dało się dojść do każdego innego.',
  },
  {
    question:
      'Przedstawiając algorytm za pomocą bloków, blok start/stop narysujemy w kształcie?',
    cat:'Algorytmy',
    url: '',
    a1: 'elipsy',
    a2: 'prostokąta',
    a3: 'równoległoboku',
    a4: 'trójkąta',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Prostokąty zawierają operacje. Równoległoboki zawierają operacje wejścia wyjścia. Trójkątów jako tako nie stosuje się w schematach blokowych algorytmów.',
  },
  {
    question:
      'Najgorszą z punktu widzenia wydajności złożonością algorytmu jest?',
    cat:'Algorytmy',
    url: '',
    a1: 'O(n!)',
    a2: 'O(n^2)',
    a3: 'O(1)',
    a4: 'O(n)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najlepiej wyobrazić sobie wykresy tych funkcji. Im bardziej płaski tym lepiej.',
  },
  {
    question:
      'Najlepszą z punktu widzenia wydajności złożonością algorytmu jest?',
    cat:'Algorytmy',
    url: '',
    a1: 'O(1)',
    a2: 'O(n^2)',
    a3: 'O(n^3)',
    a4: 'O(nlogn)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najlepiej wyobrazić sobie wykresy tych funkcji. Im bardziej płaski tym lepiej.',
  },
  {
    question:
      'Który z wymienionych algorytmów działających na tablicy jednowymiarowej ma złożoność obliczeniową O(n^2)?',
    cat:'Algorytmy',
    url: '',
    a1: 'sortowanie bąbelkowe',
    a2: 'wyszukiwanie binarne',
    a3: 'sortowanie kopcowe',
    a4: 'wypisywanie elementów',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Bloczek z ilustracji to blok?',
    cat:'Algorytmy',
    url: '/images/warunek.png',
    a1: 'warunku',
    a2: 'operacji',
    a3: 'petli',
    a4: 'wejścia-wyjścia',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Bloczek z ilustracji to bok?',
    cat:'Algorytmy',
    url: '/images/ioblock.png',
    a1: 'wejścia-wyjścia',
    a2: 'warunku',
    a3: 'operacji',
    a4: 'petli',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Algorytm można przedstawić za pomocą?',
    cat:'Algorytmy',
    url: '',
    a1: 'każdym z wymienionych sposobów',
    a2: 'opisem słownym',
    a3: 'pseudokodem',
    a4: 'schematem blokowym',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Pseudokod to mniej lub bardziej podobna do rzeczywistego kodu forma zapisu.',
  },
  {
    question:
      'Co oznacza bloczek na obrazku?',
    cat:'Algorytmy',
    url: '/images/warunek.png',
    a1: 'warunek logiczny',
    a2: 'wprowadzenie danych',
    a3: 'łącznik',
    a4: 'dołączenie podprogramu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Rodzajem algorytmu nie jest?',
    cat:'Algorytmy',
    url: '',
    a1: 'alg. kroczący',
    a2: 'alg. sekwencyjny',
    a3: 'alg. iteracyjny',
    a4: 'alg. rekurencyjny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Algorytm na obrazku to?',
    cat:'Algorytmy',
    url: '/images/algsek.png',
    a1: 'alg. iteracyjny',
    a2: 'alg. sekwencyjny',
    a3: 'alg. blokowy',
    a4: 'alg. rekurencyjny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //programowanie obiektowe
  {
    question:
      'Zasadą programowania obiektowego, która mówi aby trzymać jak najwięcej elementów klasy jako prywatne jest?',
    cat:'Obiektowe',
    url: '',
    a1: 'Hermetyzacja',
    a2: 'Abstrakcja',
    a3: 'Polimorfizm',
    a4: 'Przyjaźń',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Na zewnątrz wystawiamy najmniejsze konieczne API klasy.',
  },
  {
    question:
      'Wielopostaciowość nazywana jest?',
    cat:'Obiektowe',
    url: '',
    a1: 'polimorfizmem',
    a2: 'dziedziczeniem',
    a3: 'abstrakcją',
    a4: 'wirtualizacją',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Zależnością klasy jest?',
    cat:'Obiektowe',
    url: '',
    a1: 'inna klasa',
    a2: 'każde pole klasy',
    a3: 'każda metoda klasy',
    a4: 'żadne z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zależność to inna klasa. W klasie umieszcza się obiekt innej klasy (jej zależności) by ta mogła skorzystać z jej możliwości.',
  },
  {
    question:
      'Metoda abstrakcyjna musi być zaimplementowana przez klasę dziedziczącą?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Metoda abstrakcyjna lub czysto wirtualna nie ma ciała. Musi być zatem zaimplementowana.',
  },
  {
    question:
      'Możliwe jest utworzenie obiektu klasy abstrakcyjnej?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie można, ponieważ klasa taka ma metody bez ciała.',
  },
  {
    question:
      'Możliwe jest utworzenie obiektu interfejsu (C#)?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie można, ponieważ interfejs jest odpowiednikiem klasy abstrakcyjnej.',
  },
  {
    question:
      'W C# interfejs i klasa abstrakcyjna działają w identyczny sposób?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Klasa może dziedziczyć z dokładnie z jednej klasy, ale za to z wielu interfejsów. Interfejsy nie mogą zawierać żadnej implementacji - tylko nagłówki. W klasie abstrakcyjnej możemy mieć pola oraz część metod w formie zaimplementowanej.',
  },
  {
    question:
      'Można utworzyć referencje/wskaźnik/zmienną typu klasy abstrakcyjnej?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Mamy tu na myśli zmienną typu abstrakcyjnego, do której przypisywany jest obiekt normalnej klasy dziedziczącej z tej abstrakcyjnej.',
  },
  {
    question:
      'Z ilu klas może dziedziczyć klasa w C#/Java/Python?',
    cat:'Obiektowe',
    url: '',
    a1: '1',
    a2: '2',
    a3: 'dowolnej ilości',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Dokładnie z jednej. Ma to znaczenie przy polimorfiźmie.',
  },
  {
    question:
      'Z ilu klas może dziedziczyć klasa w C++?',
    cat:'Obiektowe',
    url: '',
    a1: 'dowolnej ilości',
    a2: '2',
    a3: '1',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C++ można dziedziczyć z wielu klas, ale utrudnia to temat polimorfizmu.',
  },
  {
    question:
      'Przekazywanie zależności do klasy poprzez konstruktor nazywamy?',
    cat:'Obiektowe',
    url: '',
    a1: 'wstrzykiwaniem zależności',
    a2: 'odwracaniem zależności',
    a3: 'dziedziczeniem zależności',
    a4: 'żadnym z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to Dependency Injection.',
  },
  {
    question:
      'Metoda ustawiająca prywatne pole to?',
    cat:'Obiektowe',
    url: '',
    a1: 'setter',
    a2: 'getter',
    a3: 'metoda wirtualna',
    a4: 'metoda abstrakcyjna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C# można do tego celu wykorzystać właściwość (konstrukcja z get i set)',
  },
  {
    question:
      'Metoda zwracająca prywatne pole to?',
    cat:'Obiektowe',
    url: '',
    a1: 'getter',
    a2: 'setter',
    a3: 'metoda wirtualna',
    a4: 'metoda abstrakcyjna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C# można do tego celu wykorzystać właściwość (konstrukcja z get i set)',
  },
  {
    question:
      'Konstruktor?',
    cat:'Obiektowe',
    url: '',
    a1: 'w typowych przypadkach powinien być funkcją typu public',
    a2: 'może być funkcja wirtualną',
    a3: 'lista argumentów musi być zawsze pusta',
    a4: 'a dostęp tylko do danych prywatnych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Możliwe jest utworzenie konstruktora prywatnego chociażby w celu utworzenia singletona.',
  },
  {
    question:
      'Jak nazywamy składniki, z których zbudowane są obiekty?',
    cat:'Obiektowe',
    url: '',
    a1: 'pola i metody',
    a2: 'klasy i metody',
    a3: 'metody i funkcje',
    a4: 'funkcje i stałe',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C# dochodzą nam jeszcze właściwości (get, set).',
  },
  {
    question:
      'Na czym polega hermetyzacja?',
    cat:'Obiektowe',
    url: '',
    a1: 'Na zabezpieczeniu składników obiektu przez ich bezpośrednią modyfikacją',
    a2: 'Na zabezpieczeniu konstruktora klasy przed niepowołanym wywołaniem',
    a3: 'Na zabezpieczaniu składników klasy przed niepotrzebnym zajmowaniem pamięci',
    a4: 'Na deklarowaniu obiektów danej klasy przy pomocy metod wirtualnych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Na czym polega dziedziczenie w programowaniu obiektowym?',
    cat:'Obiektowe',
    url: '',
    a1: 'Na tworzeniu nowej klasy na podstawie jednej lub kilku już istniejących klas',
    a2: 'Na łączeniu obiektów',
    a3: 'Na kopiowaniu cech obiektu przez metodę wirtualną',
    a4: 'Na usunięciu z istniejącej klasy zbędnych elementów',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy pole i właściwość w terminologii C# to to samo?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Właściwości to konstrukcje z get/set. Pełnią one rolę getterów i setterów na pola.',
  },
  {
    question:
      'Interfejs to konstrukcja znana np. z języka C# Jest ona podobna do?',
    cat:'Obiektowe',
    url: '',
    a1: 'klasy abstrakcyjnej',
    a2: 'zwykłej klasy',
    a3: 'uni',
    a4: 'struktury',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Interfejs można porównać do kontraktu, który mówi jakie metody musi zaimplementować klasa dziedzicząca. Klasa abstrakcyjna działa tak samo z tym, że ona może mieć już część metod zaimplementowaną.',
  },
  {
    question:
      'Obiekt to instancja klasy?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Albo struktury.',
  },
  {
    question:
      'Słowo kluczowe "sealed" w C# blokuje możliwość dziedziczenia z klasy?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Słowo kluczowe "virtual" oznacza metodę jako zależną od typu obiektu a nie typu referencji/wskaźnika/zmiennej?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'To słowo kluczowe jest istotne z punktu widzenia polimorfizmu.',
  },
  {
    question:
      'Klasa pochodna ma dostęp do wszystkich elementów klasy bazowej?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Ma dostęp do elementów publicznych oraz chronionych.',
  },
  {
    question:
      'Klasa dziedzicząca dziedziczy prywatne składowe klasy bazowej?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Elementy prywatne są dziedziczone, ale nie można się do nich bezpośrednio odwołać wewnątrz klasy dziedziczącej.',
  },
  {
    question:
      'Klasy oferują możliwość przeciążania wszystkich operatorów?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie da się przeciążyć operatora trynarnego ?: (C++). W C# lista niemożliwych do przeciążenia operatorów jest dłuższa i zawiera między innymi operatory z serii +=, -=, ...',
  },
  {
    question:
      'Gdy argumentem funkcji jest struktura/obiekt?',
    cat:'Obiektowe',
    url: '',
    a1: 'aby funkcja zmieniła oryginał, argumentem musi być wskaźnik do struktury/obiektu',
    a2: 'funkcja może zmienić oryginał struktury/obiektu',
    a3: 'funkcja może zmienić tylko kopię struktury/obiektu',
    a4: 'żadna z pozostałych odpowiedzi nie jest poprawna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Jaką klasę nazywamy abstrakcyjną?',
    cat:'Obiektowe',
    url: '',
    a1: 'Zawierająca przynajmniej jedną metodę czysto wirtualną',
    a2: 'Taką, która jest jednocześnie klasą bazową i pochodną',
    a3: 'Zawierającą wszystkie trzy specyfikatory dostępu',
    a4: 'Zawierającą 2 wersje przeciążonego konstruktora',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda czysto wirtualna to metoda bez implementacji/ciała.',
  },
  {
    question:
      'Metoda wirtualna…',
    cat:'Obiektowe',
    url: '',
    a1: 'Może być przedefiniowana w klasie pochodnej',
    a2: 'Nie posiada definicji',
    a3: 'Nigdy nie jest dziedziczona',
    a4: 'Zawsze korzysta z rekurencji',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda wirtualna to metoda, która może zostać nadpisana, ale nie musi.',
  },
  {
    question:
      'Metoda czysto wirtualna…',
    cat:'Obiektowe',
    url: '',
    a1: 'Nie posiada definicji',
    a2: 'Nie zwraca',
    a3: 'Nigdy nie jest dziedziczona',
    a4: 'Zawsze korzysta z rekurencji',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to metoda abstrakcyjna bez ciała. Metoda wirtualna może mieć ciało, które będzie nadpisane bądź nie.',
  },
  {
    question:
      'Jak wywołać metodę obiektu za pomocą wskaźnika wskazującego na ten obiekt?',
    cat:'Obiektowe',
    url: '',
    a1: 'wskaźnik->metoda();',
    a2: 'wskaźnik.metoda();',
    a3: 'metoda->wskaźnik();',
    a4: 'metoda.wskaźnik();',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli mamy do czynienia ze wskaźnikiem to do jego składowych odwołujemy się za pomocą operatora "->"',
  },
  {
    question:
      'Na czym polega polimorfizm?',
    cat:'Obiektowe',
    url: '',
    a1: 'Wskaźnik/Referencję klasy bazowej można powiązać z obiektami klas pochodnych',
    a2: 'Konstruktor klasy bazowej staje się wirtualny',
    a3: 'Klasy pochodne są czysto abstrakcyjne',
    a4: 'Wskaźnik klasy bazowej jest dziedziczony przez klasy pochodne',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy klasa pochodna dziedziczy z klasy bazowej konstruktor?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Konstruktory i destruktory nie są dziedziczone. Jednak klasa pochodna ma do nich dostęp (może je wywołać co jest zalecane).',
  },
  {
    question:
      'Czy klasa pochodna dziedziczy z klasy bazowej destruktor?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Konstruktory i destruktory nie są dziedziczone. Jednak klasa pochodna ma do nich dostęp (może je wywołać co jest zalecane).',
  },
  {
    question:
      'Czy elementy statyczne klasy mają dostęp do elementów niestatycznych?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Aby odwołać się do zwykłego elementu klasy element statyczny potrzebuje instancji. Sam z siebie nie ma takiej możliwości.',
  },
  {
    question:
      'Czy elementy nie statyczne klasy mają dostęp do elementów statycznych?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak jest to możliwe. Elementy statyczne są przypisane do klasy.',
  },
  {
    question:
      'Czy setter może wykonywać dodatkową logikę oprócz ustawiania pola?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak. Przykład z cenami. Mamy prywatne pole cena brutto. Do niego mamy trzy gettery (netto, brutto, tara), które oprócz przypisania.',
  },
  {
    question:
      'Czy getter może wykonywać dodatkową logikę oprócz zwracania pola?',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak. Przykład z cenami. Mamy prywatne pole cena brutto. Do niego mamy trzy gettery (netto, brutto, tara), które oprócz zwrócenia wykonują odpowiednie przeliczenie.',
  },
  {
    question:
      'Czy klasa może dziedziczyć sama z siebie?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Oczywiście, że nie.',
  },
  {
    question:
      'Czy w C# interfejs może zawierać pola?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie może. W C# interfejs oprócz metod może zawierać właściwości.',
  },
  {
    question:
      'Czy słowo kluczowe this może mieć wartość NULL?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie jest to możliwe.',
  },
  {
    question:
      'Czy słowa kluczowego "this" można użyć w metodzie statycznej?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Słowo kluczowe "this" odnosi się do danej instancji klasy. W przypadku statyczność operujemy na elementach przypisanych do klasy a nie do obiektu. Nie ma więc mowy o this.',
  },
  {
    question:
      'Czy klasa może dziedziczyć sama z siebie?',
    cat:'Obiektowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Oczywiście, że nie',
  },
  {
    question:
      'Ile pól zawiera klasa na obrazku?',
    cat:'Obiektowe',
    url: '/images/classtemplate.png',
    a1: '4',
    a2: '2',
    a3: '1',
    a4: '3',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Językiem obsługującym wieloklasowe dziedziczenie jest?',
    cat:'Obiektowe',
    url: '',
    a1: 'C++',
    a2: 'C',
    a3: 'C#',
    a4: 'Javascript',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Tylko C++ obsługuje wieloklasowe dziedziczenie.',
  },
  {
    question:
      'Cztery filary programowania obiektowego to?',
    cat:'Obiektowe',
    url: '',
    a1: 'Dziedziczenie, Polimorfizm, Hermetyzacja, Abstrakcja',
    a2: 'Public, Protected, Private, Interfejs',
    a3: 'Public, Protected, Private, Implementacja',
    a4: 'Konstruktor, Destruktor, Abstrakcja, Iteracja',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku C# mamy operator == oraz funkcję Equals(). Na czym polega różnica między nimi?',
    cat:'Obiektowe',
    url: '/images/equals.jpg',
    a1: 'operator == porównuje tylko referencje a Equals() także zawartość obiektu',
    a2: 'operator == porównuje zawartość obiektu a Equals() referencje',
    a3: 'obie metody porównania są identyczne',
    a4: 'wszystkie pozostałe',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Najważniejszą cechą klasy abstrakcyjnej jest:',
    cat:'Obiektowe',
    url: '',
    a1: 'brak możliwości stworzenia obiektu tej klasy',
    a2: 'brak możliwości dziedziczenia z tej klasy',
    a3: 'brak możliwości hermetyzacji tej klasy',
    a4: 'brak możliwości dziedziczenia przez tę klasę',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Destruktory klas powinno się definiować jako virtualne',
    cat:'Obiektowe',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Działa tu ta sama zasada co w przypadku polimorfizmu na normalnych metodach. Kompilator musi wiedzieć, który konstruktor wywołać jeżeli referencja/wskaźnik jest typu bazowego.',
  },
  //C#
  {
    question:
      'Język C# jest podobny do języka?',
    cat:'C#',
    url: '/images/csharp.jpeg',
    a1: 'Java',
    a2: 'Javascript',
    a3: 'C',
    a4: 'C++',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'C# to w wielu miejscach kopiuj wklej z Javy i odwrotnie.',
  },
  {
    question:
      'C# oferuje obsługę wskaźników?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak, ale kod taki trzeba oznaczyć słowem kluczowym "unsafe"',
  },
  {
    question:
      'W C# funkcja main jest statyczna?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak jest. Przyjmuje argument w formie tablicy parametrów podawanych po spacji przy wywołaniu z konsoli nazwa.exe param1 param2 itd',
  },
  {
    question:
      'W C# typy referencyjne są usuwane z pamięci manualnie przez programistę?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Usuwaniem takich obiektów zajmuje się Garbage Collector.',
  },
  {
    question:
      'W C# typy referencyjne są usuwane z pamięci przez garbage collector?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Garbage Collector liczy ile jest aktywnych referencji na dany obiekt w pamięci. Jeżeli nie ma żadnej to go usuwa.',
  },
  {
    question:
      'Plik źródłowy języka C# ma rozszerzenie?',
    cat:'C#',
    url: '',
    a1: '.cs',
    a2: '.cc',
    a3: '.c',
    a4: '.sc',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C# "Console.Write()" wstawia enter na końcu?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Console.WriteLine() wstawia enter na końcu. Możemy jednak wrzucić do Write() string ze znakiem "\\n"',
  },
  {
    question:
      'W C# "Console.WriteLine()" wstawia enter na końcu?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Console.WriteLine() wstawia enter na końcu. ',
  },
  {
    question:
      'W C# "Console.ReadLine()" zwraca wartość w formie tekstowej?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Funkcja ta ma typ zwracany "string". Dlatego jeżeli pobieramy od użytkownika liczby trzeba wartość parsować.',
  },
  {
    question:
      'W C# funkcja decimal.Parse() działa tak samo jeżeli podamy do niej string zawierający liczbę z przecinkiem oraz liczbę z kropką?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Funkcja ta domyślnie oczekuje od nas liczby z przecinkiem. Jeżeli nasza liczba ułamkowa jest zapisana za pomocą kropki należy to wskazać programowi w drugim parametrze.',
  },
  {
    question:
      'W C# da się napisać metodę poza klasą?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'C# jest typowo obiektowym językiem. Nie da się napisać funkcji poza klasą.',
  },
  {
    question:
      'W C# da się napisać klasę w klasie?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak, możliwe jest pisanie klas w klasie. Klasy takie są dostępne tylko wewnątrz klasy nadrzędnej.',
  },
  {
    question:
      'W C++ da się napisać klasę w klasie?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tak, możliwe jest pisanie klas w klasie. Klasy takie są dostępne tylko wewnątrz klasy nadrzędnej.',
  },
  {
    question:
      'Każda zmienna w C# przekazywana jest przez wartość do funkcji itp?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W C# istnieją typy wartościowe oraz typy referencyjne. Jeżeli typ jest wartościowy to jego zmienna jest przekazywana przez wartość.',
  },
  {
    question:
      'W C# klasy i struktury są równoznaczne i zachowują się tak samo?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Klasy definiują typy referencyjne. Struktury definiują typy wartościowe.',
  },
  {
    question:
      'Struktura definiuje typ referencyjny w języku C#?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W C# struktury i klasy różnią się właśnie tym, że struktura definiuje typ wartościowy a klasa referencyjny. Jeżeli zależy nam na przekazywaniu przez wartość to używamy struktur. Jeżeli chcemy referencje to klas.',
  },
  {
    question:
      'Klasa definiuje typ referencyjny w języku C#?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W materiałach znajduje się cały PDF na ten temat.',
  },
  {
    question:
      'Delegat w języku C# to?',
    cat:'C#',
    url: '',
    a1: 'specjalny typ do przechowywania referencji na funkcję',
    a2: 'specjalny typ do przechowywania typów prostych',
    a3: 'specjalny typ pełniący rolę iteratora po kolekcji',
    a4: 'specjalny typ pełniący rolę wskaźnika do poruszania się po pliku',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Delegat to mechanizm podobny do wskaźników na funkcję w C/C++. W materiałach znajduje się PDF na ten temat.',
  },
  {
    question:
      'Delegaty w C# to mechanizm podobny do wskaźników na funkcję?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Delegat to konstrukcja do przechowywania referencji na funkcje. Mechanizm ten jest podobny do wskaźników na funkcję. W materiałach znajduje się PDF na ten temat.',
  },
  {
    question:
      'Delegaty w C# to typy referencyjne?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W materiałach znajduje się PDF na ten temat.',
  },
  {
    question:
      'Typem delegata w C# NIE jest?',
    cat:'C#',
    url: '',
    a1: 'Task<T>',
    a2: 'Action<T>',
    a3: 'Func<T>',
    a4: 'Predicate<T>',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W materiałach znajduje się PDF na ten temat.',
  },
  {
    question:
      'W C# delegat "Func<int, double, bool>" zwraca?',
    cat:'C#',
    url: '',
    a1: 'bool',
    a2: 'double',
    a3: 'int',
    a4: 'nic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Func to delegat do funkcji, które coś zwracają. Ostatni parametr generyczny mówi jaki to typ. Pozostałe typy to typy argumentów.',
  },
  {
    question:
      'W C# delegat "Action<string, int, int>" zwraca?',
    cat:'C#',
    url: '',
    a1: 'void',
    a2: 'bool',
    a3: 'int',
    a4: 'string',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Action to delegat do funkcji, które nic nie zwracają (void). Wszystkie typy w nawiasie <> to typy argumentów.',
  },
  {
    question:
      'W C# delegat "Predicate<int>" zwraca?',
    cat:'C#',
    url: '',
    a1: 'bool',
    a2: 'double',
    a3: 'int',
    a4: 'nic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Predicate zawsze zwraca typ bool.',
  },
  {
    question:
      'Typem danych wykorzystywanym do precyzyjnego przechowywania liczb ułamkowych w C# jest?',
    cat:'C#',
    url: '',
    a1: 'decimal',
    a2: 'double',
    a3: 'float',
    a4: 'dynamic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C# "var" a "dynamic" oznacza dokładnie to samo?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '"var" jest podmieniane na odpowiedni typ w momencie kompilacji. "dynamic" powala dynamicznie określić typ jak program już działa".',
  },
  {
    question:
      'W C# "const" oraz "readonly" oznacza dokładnie to samo?',
    cat:'C#',
    url: '/images/readonly.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Zmienna "readonly" może zostać podmieniona przez konstruktor. To słowo kluczowe stosuje się do pól przechowujących obiekty zależności klasy (obrazek).',
  },
  {
    question:
      'Czy C# możliwe jest zapisane do zmiennej "readonly"?',
    cat:'C#',
    url: '/images/readonly.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Zmienna "readonly" może zostać podmieniona przez konstruktor. To słowo kluczowe stosuje się do pól przechowujących obiekty zależności klasy (obrazek).',
  },
  {
    question:
      'Oznaczenie w C# klasy jako "sealed" blokuje możliwość dziedziczenia z niej?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Słowo kluczowe "sealed" blokuje możliwość dziedziczenia z klasy.',
  },
  {
    question:
      'Jeżeli chcemy w C# parsować na double string z liczbą ułamkową to ma znaczenie czy użyjemy w niej kropki czy przecinka?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Funkcji parsującej trzeba wskazać rodzaj separatora.',
  },
  {
    question:
      'Aby poznać długość stringa w C# należy posłużyć się?',
    cat:'C#',
    url: '',
    a1: '.Length',
    a2: '.Length()',
    a3: '.Count',
    a4: '.Count()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Konstrukcja alokująca tablicę w C# to?',
    cat:'C#',
    url: '',
    a1: 'int[] tablica = new int[5];',
    a2: 'int tablica[] = new int[5];',
    a3: 'int[] tablica = new int(5);',
    a4: 'int tablica[] = new int(5);',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Konstrukcja alokująca tablicę 2D w C# to?',
    cat:'C#',
    url: '',
    a1: 'int[,] tablica2 = new int[5,2];',
    a2: 'int[][] tablica2 = new[5][2] int;',
    a3: 'int[][] tablica2 = new int[5,2];',
    a4: 'int[][] tablica2 = new[5][2] int;',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '[,] konstrukcja jest poprawna w C#. W C++ była by to konstrukcja [][].',
  },
  {
    question:
      'Tablica postrzępiona w C# to?',
    cat:'C#',
    url: '',
    a1: 'Tablica 2D gdzie każda podtablica jest innego rozmiaru',
    a2: 'Tablica 2D gdzie każda podtablica jest równa',
    a3: 'Tablica 2D gdzie każda podtablica ma nieparzystą ilość elementów',
    a4: 'Każda tablica',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli podtablice tablicy dwuwymiarowej mają różne rozmiary to jest to tablica postrzępiona.',
  },
  {
    question:
      'String w C# jest typem referencyjnym?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Rekord w C# to?',
    cat:'C#',
    url: '',
    a1: 'niemutowalny byt podobny do klasy, który przechowuje tylko dane we właściwościach',
    a2: 'byt podobny do klasy, który przechowuje tylko dane we właściwościach',
    a3: 'niemutowalny byt podobny do klasy, który przechowuje tylko metody',
    a4: 'mutowalny byt podobny do klasy, który przechowuje tylko dane we właściwościach',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Rekordy to klasy na dane, których nie da się modyfikować po zainicjowaniu.',
  },
  {
    question:
      'Tablica w C# jest typem referencyjnym?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'List<T> w C# jest typem referencyjnym?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'List to klasa a klasy definiują typy referencyjne.',
  },
  {
    question:
      'Enum w C# jest typem referencyjnym?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W C# typy referencyjne alokowane są na stercie a wartościowe na stosie?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Obiekt klasy zostanie zaalokowany za stercie, a obiekt struktury na stosie.',
  },
  {
    question:
      'W C# referencja na typ referencyjny i tak jest na stosie?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Obiekt typu referencyjnego tworzony jest na stercie, ale zmienna z adresem jest i tak na stosie jeżeli jest utworzona w funkcji.',
  },
  {
    question:
      'W C# typ wartościowy staje się typem referencyjnym jeżeli jest składową typu referencyjnego?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli int jako typ prosty zostaje umieszczony w formie pola wewnątrz klasy to naturalnie staje się typem referencyjnym.',
  },
  {
    question:
      'Boxing to proces sztucznego opakowania typu wartościowego w typ referencyjny?',
    cat:'C#',
    url: '/images/boxing.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Możemy na przykład zapakować inta do typu object. Dostajemy dzięki temu obiekt referencyjny.',
  },
  {
    question:
      'Właściwość w C# to odpowiednik gettera i settera z innych języków?',
    cat:'C#',
    url: '/images/set.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Właściwości to "syntactic sugar" na zwykłe funkcyjne gettery i settery.',
  },
  {
    question:
      'Jeżeli właściwość w C# ma tylko zadeklarowaną opcję get to jest tylko getterem?',
    cat:'C#',
    url: '/images/get.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli w dymku z podpowiedzią widzimy tylko get to jest to tylko getter.',
  },
  {
    question:
      'Jeżeli właściwość w C# ma tylko zadeklarowaną opcję set to jest tylko setterem?',
    cat:'C#',
    url: '/images/set.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli w dymku z podpowiedzią widzimy tylko set to jest to tylko setter.',
  },
  {
    question:
      'Jeżeli właściwość w C# ma słowo kluczowe "init" w miejscu "set" oznacza to, że może ona być użyta jako setter raz w czasie inicjalizacji?',
    cat:'C#',
    url: '/images/init.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Mechanizm pozwalający programowi czytać informacje o samym sobie to?',
    cat:'C#',
    url: '',
    a1: 'Refleksja',
    a2: 'Lustro',
    a3: 'Asemblacja',
    a4: 'Instancjonowanie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Refleksje pozwalają na wiele jak np. czytanie prywatnych pól, czytanie listy plików w projekcie itp.',
  },
  {
    question:
      'Mechanizm pozwalający pisać w C# wydajne zapytania do kolekcji nazywany jest?',
    cat:'C#',
    url: '',
    a1: 'LINQ',
    a2: 'LI',
    a3: 'Enumerator',
    a4: 'Enumerable',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Klasa w C# służąca do tworzenia stringów to?',
    cat:'C#',
    url: '',
    a1: 'StringBuilder',
    a2: 'BuildString',
    a3: 'String',
    a4: 'Strings',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'StringBuilder buduje string w bardziej optymalny sposób. Ma to duże znaczenie gdy np. doklejamy coś do stringa w pętli.',
  },
  {
    question:
      'Parametry generyczne umieszczamy zazwyczaj w?',
    cat:'C#',
    url: '',
    a1: '<>',
    a2: '()',
    a3: '[]',
    a4: '{}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Parametrem generycznym jest zawsze typ. Np. "Func<int, int>"',
  },
  {
    question:
      'Parametry generyczne służą do?',
    cat:'C#',
    url: '/images/genericsharp.png',
    a1: 'wszystkie pozostałe',
    a2: 'dynamicznego precyzowania typów stosowanych w metodzie/klasie',
    a3: 'precyzowania jakiego typu elementy przechowuje kolekcja',
    a4: 'określania typów argumentów w przypadku deklaracji delegata',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Parametrem generycznym jest zawsze typ. Np. "Func<int, int>"',
  },
  {
    question:
      '"Indexer" to konstrukcja języka C#, która?',
    cat:'C#',
    url: '/images/indexer.png',
    a1: 'jest odpowiednikiem przeciążonego operatora []',
    a2: 'jest odpowiednikiem przeciążonego operatora (type)',
    a3: 'jest odpowiednikiem przeciążonego operatora &',
    a4: 'żadna z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Indexer to konstrukcja języka C# wyglądem i zachowaniem przypominająca właściwość. W przypadku innych języków można ją porównać do przeciążenia operatora [].',
  },
  {
    question:
      'W C# interfejsy nazywa się zaczynając od litery I?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Taką konwencję przyjęli programiści C#.',
  },
  {
    question:
      'W C# List<T> nie różni się niczym od zwykłej tablicy?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Lista to typ na wyższym poziomie abstrakcji. Oferuje bogatsze API niż zwykła tablica.',
  },
  {
    question:
      'Metoda LINQ First() działa tak samo jak FirstOrDefault()?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli kolekcja typu T będzie pusta First() spowoduje błąd, a FirstOrDefault() zwróci domyślną wartość danego typu T czyli np. 0 dla int albo null dla typu referencyjnego.',
  },
  {
    question:
      'W C# List<T> to to samo co IEnumerable<T>?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'List dziedziczy z interfejsu IEnumerable. Jeżeli posługujemy się referencją typu IEnumerable możliwe jest odłożenie w czasie pewnych operacji na kolekcji.',
  },
  {
    question:
      'Do czego służy metoda ToString() w C#?',
    cat:'C#',
    url: '',
    a1: 'Zwrócenia reprezentacji tekstowej obiektu',
    a2: 'Parsowania obiektu na tekst',
    a3: 'Zwrócenia nazwy obiektu',
    a4: 'Zwrócenia typu obiektu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'TO NIE JEST PARSOWANIE NA STRING',
  },
  {
    question:
      'Do czego służy metoda Equals() w C#?',
    cat:'C#',
    url: '',
    a1: 'metoda ta zależnie od implementacji użytkownika sprawdza czy dwa obiekty danej klasy są równe',
    a2: 'metoda ta sprawdza czy obiekt zajmuje w pamięci wskazaną ilość bajtów',
    a3: 'metoda ta sprawdza czy adresy dwóch obiektów są równe',
    a4: 'metoda ta sprawdza czy referencje dwóch obiektów są równe',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metoda klasy string, która sprawdza czy napis jest nullem lub jest pusty to?',
    cat:'C#',
    url: '',
    a1: 'IsNullOrEmpty()',
    a2: 'NullOrEmpty()',
    a3: 'IsEmpty()',
    a4: 'IsNull()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'IsNullOrEmpty() to statyczna składowa typu String. Dzięki niej nie musimy pisać ifów sprawdzających czy zmienna jest nullem lub ma długość 0',
  },
  {
    question:
      'Gdy "a = null" instrukcja "Console.WriteLine(a is { });" wypisze?',
    cat:'C#',
    url: '',
    a1: 'false',
    a2: 'true',
    a3: '0',
    a4: 'kompilacja zakończy się błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"a is {}" to odpowiednik "a != null"',
  },
  {
    question:
      'Gdy "a = new object()" instrukcja "Console.WriteLine(a is { });" wypisze?',
    cat:'C#',
    url: '',
    a1: 'true',
    a2: 'false',
    a3: '0',
    a4: 'kompilacja zakończy się błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"a is {}" to odpowiednik "a != null"',
  },
  {
    question:
      'Słowo kluczowe języka C# "async" w nagłówku funkcji oznacza?',
    cat:'C#',
    url: '',
    a1: 'operację asynchroniczą',
    a2: 'operację synchroniczną',
    a3: 'funkcję wywołania zwrotnego',
    a4: 'funkcję dotykającą UI',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda taka jest asynchroniczna.',
  },
  {
    question:
      'Słowo kluczowe języka C# "await" automatycznie powoduje?',
    cat:'C#',
    url: '',
    a1: 'że metoda staje się asynchroniczna',
    a2: 'że metoda w danym miejscu może się zakończyć',
    a3: 'że metoda otwiera w danym miejscu połączenie sieciowe',
    a4: 'że metoda staje się synchroniczną',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli w metodzie pada słowo kluczowe "await" to metoda staje się asynchroniczna. Trzeba ją oznaczyć jako metodę "async" albo po prostu zwrócić typ Task<T>.',
  },
  {
    question:
      'W C# typem zwracanym z metod asynchronicznych jest?',
    cat:'C#',
    url: '',
    a1: 'Task<T>',
    a2: 'Future<T>',
    a3: 'Promise<T>',
    a4: 'Thread<T>',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W C# jest to Task, w Dart Future, w JS Promise.',
  },
  {
    question:
      'Typy wartościowe można przekazać w formie referencji za pomocą?',
    cat:'C#',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'słowa kluczowego "ref"',
    a3: 'słowa kluczowego "out"',
    a4: 'boxingu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C# konwencja nazw stosuje zazwyczaj?',
    cat:'C#',
    url: '',
    a1: 'odmiany cammelCase',
    a2: 'odmiany snake_case',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Testy w C# nazywa się snake_casem. cammelCase jest słabym rozwiązaniem gdy nazwa składa się z dużej ilości słów.',
  },
  {
    question:
      'Aby użyć wskaźników w C# należy oznaczyć funkcję słowem kluczowym?',
    cat:'C#',
    url: '',
    a1: 'unsafe',
    a2: 'volatile',
    a3: 'virtual',
    a4: 'static',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najczęściej dodaje się "unsafe" do nagłówka funkcji np. "public static unsafe void Main(){}"',
  },
  {
    question:
      'Klasą nadrzędną dla wyjątków w C# jest?',
    cat:'C#',
    url: '',
    a1: 'Exception',
    a2: 'Throwable',
    a3: 'Except',
    a4: 'Error',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chcąc stworzyć własny wyjątek budujemy klasę dziedziczącą z Exceptions.',
  },
  {
    question:
      'Pojęcie statycznego polimorfizmu oznacza?',
    cat:'C#',
    url: '',
    a1: 'przeciążanie funkcji',
    a2: 'nadpisywanie funkcji',
    a3: 'dziedziczenie',
    a4: 'wielokrotne dziedziczenie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chodzi o zwykłe przeciążanie funkcji (ta sama nazwa, różne argumenty).',
  },
  {
    question:
      'Słowo kluczowe służące do nadpisywania metod klasy bazowej to?',
    cat:'C#',
    url: '',
    a1: 'override',
    a2: 'virtual',
    a3: 'extern',
    a4: 'static',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Referencja w porównaniu do wskaźnika?',
    cat:'C#',
    url: '',
    a1: 'nie pozwala na arytmetykę na adresie',
    a2: 'pozwala na arytmetykę na adresie',
    a3: 'jest konstrukcją składniową',
    a4: 'oznacza obiekt jako stałą',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Referencja jest dużo bezpieczniejsza. Większość błędów bezpieczeństwa w oprogramowaniu wywodzi się z niepoprawnego użycia wskaźników lub innego niedopatrzenia z nimi związanego.',
  },
  {
    question:
      'Która pętla nie jest dostępna w C#?',
    cat:'C#',
    url: '',
    a1: 'for(auto el in collection){}',
    a2: 'foreach(var el in collection){}',
    a3: 'while(a > 0) {}',
    a4: 'for(int i = 0;i<10;i++){}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Skrót LINQ oznacza?',
    cat:'C#',
    url: '',
    a1: 'Language Integrated Query',
    a2: 'Link List Inner Query',
    a3: 'Lazy Integration Query',
    a4: 'Linked Integrated Query',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metodą LINQ, która zwraca wszystkie elementy kolekcji, dla których podany callback zwrócił prawdę to?',
    cat:'C#',
    url: '',
    a1: 'Where()',
    a2: 'Select()',
    a3: 'ToList()',
    a4: 'Filter()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metody Linq First() oraz FirstOrDefault() działają identycznie?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'First() w przypadku braku dopasowania rzuca wyjątek. FirstOrDefault() w takim przypadku zwraca domyślną wartość dla danego typu przechowywanego w kolekcji.',
  },
  {
    question:
      'Metoda rozszerzająca to metoda?',
    cat:'C#',
    url: '',
    a1: 'statyczna, która zachowuje się jakby była częścią obiektu',
    a2: 'zwykła metoda, która zachowuje się jakby była częścią obiektu',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jest to metoda zdefiniowana w innej klasie jako statyczna, ale wyświetla się jako składowa klasy, którą rozszerza.',
  },
  {
    question:
      'Interpolacji stringów w C# można dokonać składnią?',
    cat:'C#',
    url: '',
    a1: '$"Ala ma {count} kotów!"',
    a2: 'f"Ala ma {count} kotów!"',
    a3: '#"Ala ma {count} kotów!"',
    a4: '&"Ala ma {count} kotów!"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to element języka eliminujący formatowanie stringów za pomocą ich konkatenacji ze zmiennymi.',
  },
  {
    question:
      'Na obrazku zaznaczono',
    cat:'C#',
    url: '/images/attribute.png',
    a1: 'atrybuty',
    a2: 'modyfikatory',
    a3: 'tagi',
    a4: 'tokeny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Atrybut, atrybuty takie w C# można definiować samemu poprzez stworzenie klasy dziedziczącej z "System.Attribute".',
  },
  {
    question:
      'W C# za operacje na plikach odpowiada między innymi klasa "File"?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W C# metoda klasy "File" stosowana do tworzenia plików nazywa się?',
    cat:'C#',
    url: '',
    a1: 'Create()',
    a2: 'CreateFile()',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W C# metoda klasy "File" stosowana do usuwania plików nazywa się?',
    cat:'C#',
    url: '',
    a1: 'Delete()',
    a2: 'Remove()',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W C# metody klasy File o nazwie "ReadAllLines()" oraz "ReadAllText()" działają tak samo?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pierwsza zwraca tablicę z wierszami, druga cały plik w formie stringa.',
  },
  {
    question:
      'W C# wyjątek "DirectoryNotFoundException" rzucany np. przez "File.Create()" oznacza?',
    cat:'C#',
    url: '',
    a1: 'że nie można znaleźć katalogu, składowego ścieżki',
    a2: 'ścieżka jest w niepoprawnym formacie',
    a3: 'ścieżka jest nullem',
    a4: 'plik już istnieje',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W C# za operacje na katalogach odpowiada między innymi klasa "Directory"?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W C# metoda klasy "Directory" stosowana do usuwania katalogów nazywa się?',
    cat:'C#',
    url: '',
    a1: 'Delete()',
    a2: 'Remove()',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W C# metoda klasy "Directory" stosowana do tworzenia katalogów nazywa się?',
    cat:'C#',
    url: '',
    a1: 'CreateDirectory()',
    a2: 'Create()',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Dobrą praktyką jest sprawdzenie czy katalog/plik istnieje przed wykonaniem na nim operacji?',
    cat:'C#',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie każda funkcja zrobi to za nas, a jeżeli tego nie zrobi to możemy spodziewać się wyjątku.',
  },
  {
    question:
      'Czy deklarowanie zmiennych jako "var" w C# to dobra praktyka?',
    cat:'C#',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Powinno się pisać typ precyzyjnie. Ułatwia to później analizę kodu.',
  },
  {
    question:
      'W C# wyjątek "NullReferenceException" oznacza, że?',
    cat:'C#',
    url: '',
    a1: 'zmienna referencyjna, na której wykonaliśmy operację jest nullem',
    a2: 'zmienna referencyjna, na której wykonujemy operacje jest typu, który nie przyjmuje wartości null',
    a3: 'zmienna referencyjna, na której wykonujemy operacje jest typu, który przyjmuje wartości null',
    a4: 'zmienna referencyjna właśnie została ustawiona na null',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli do stringa przypiszemy null i sprawdzimy jego długość za pomocą .Length() to dostaniemy ten wyjątek. Jeżeli nam taki wyjątek leci to znaczy, że próbujemy wykonać jakąś akcję na wartości null.',
  },
  {
    question:
      'W C# wyjątek "ArgumentNullException" oznacza, że?',
    cat:'C#',
    url: '',
    a1: 'zmienna podana do funkcji jest nullem',
    a2: 'zmienna podana do funkcji jest typu, który może przyjąć null',
    a3: 'zmienna podana do funkcji została w pewnym momencie jej wykonania ustawiona na null',
    a4: 'zmienna podana do funkcji jest typu, który nie przyjmuje wartości null',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Korzystając z funkcji, które dostarcza język lub jakaś biblioteka musimy wziąść pod uwagę, że ich parametry mogą przyjmować null lub nie.',
  },
  //WPF/XAMARIN/ASP.NET
  {
    question:
      'Skrót "WPF" oznacza?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'Windows Presentation Foundation',
    a2: 'Windows Power Framework',
    a3: 'Windows Presentation Framework',
    a4: 'Windows Power Foundation',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Do budowania układu siatki w WPF i Xamarin służy tag?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'GRID',
    a2: 'TABLE',
    a3: 'COLUMN',
    a4: 'ROW',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Do nadawania obramowania elementom w WPF służy tag?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'Border',
    a2: 'Frame',
    a3: 'Padding',
    a4: 'nie istnieje taki tag',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W WPF tagiem budującym listę rozwijaną jest?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'ComboBox',
    a2: 'ListView',
    a3: 'SelectList',
    a4: 'ScrollList',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W WPF właściwością tagów odpowiedzialnych za wszelkiego rodzaju listy, która pozwala zdefiniować listę elementów jest?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'ItemSource',
    a2: 'ItemList',
    a3: 'DataSource',
    a4: 'Items',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pierwszym argumentem funkcji MessageBox.Show() jest?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'treść komunikatu',
    a2: 'tytuł okienka z komunikatem',
    a3: 'referencja na rodzica',
    a4: 'enum określający rodzaj przycisków dostępnych w okienku',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Trzecim argumentem funkcji MessageBox.Show() jest?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'enum określający rodzaj przycisków dostępnych w okienku',
    a2: 'treść komunikatu',
    a3: 'tytuł okienka z komunikatem',
    a4: 'referencja na rodzica',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W WPF oraz Xamarin do elementów interfejsu odwołujemy się za pomocą?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'atrybutu name',
    a2: 'atrybutu id',
    a3: 'atrybutu class',
    a4: 'atrybutu title',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Język do budowania interfejsu użytkownika w WPF oraz Xamarin nazywa się?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'XAML',
    a2: 'XML',
    a3: 'Blazor',
    a4: 'Razor',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Windows Forms to to samo co WPF?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Windows Forms to starsza technologia, jednak wciąż mocno używana. Windows Forms opiera się o graficzny edytor a nie o język XAML.',
  },
  {
    question:
      'W WPF niedostępny jest layout typu?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'TablePanel',
    a2: 'StackPanel',
    a3: 'DockPanel',
    a4: 'Wszystkie wymienione',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W WPF dostępnym typem layoutu jest?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'Wszystkie wymienione',
    a2: 'CanvasPanel',
    a3: 'StackPanel',
    a4: 'DockPanel',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby w WPF można było przypisać element do pola siatki GRID element musi być umieszczony wewnątrz bloku <Grid></Grid>?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Aby w WPF można było przypisać element do kolumny siatki należy posłużyć się atrybutem "Grid.Column"?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Aby w WPF można było połączyć kolumny siatki należy posłużyć się atrybutem "Grid.ColSpan"?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Analogia do tabel w HTML.',
  },
  {
    question:
      'Aby w WPF można było połączyć wiersze siatki należy posłużyć się atrybutem "Grid.RowSpan"?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Analogia do tabel w HTML.',
  },
  {
    question:
      'Rozszerzenie pliku, w którym definiujemy interfejs użytkownika dla aplikacji Xamarin/WPF to?',
    cat:'WPF/XAMARIN/ASP.NET',
    url: '',
    a1: '*.xaml',
    a2: '*.xml',
    a3: '*.xml.cs',
    a4: '*.xaml.cs',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //GIT
  {
    question:
      'Polecenie "git reset --hard" oraz git reset --soft" działają tak samo?',
    cat:'GIT',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Hard powoduje całkowitą utratę zmian. Soft zostawia zmiany jako "dodane". Mixed zostawia zmiany jako "do dodania."',
  },
  {
    question:
      'Jakiego przełącznika należy użyć z poleceniem "git reset" aby cofnięte zmiany wylądowały na "stage"?',
    cat:'GIT',
    url: '',
    a1: '--soft',
    a2: '--mixed',
    a3: '--hard',
    a4: '--medium',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przełącznik "--hard" powoduje całkowitą utratę zmian. "--soft" zostawia zmiany jako "dodane na stage". "--mixed" zostawia zmiany jako "do dodania na stage".',
  },
  {
    question:
      'Polecenie "git rebase" oraz git merge" działają tak samo?',
    cat:'GIT',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Merge łączy gałęzie. Rebase pozwala zmienić commit na bazie którego został utworzony dany branch.',
  },
  {
    question:
      'Listę plików/katalogów, które są ignorowane przez system kontroli wersji git należy umieścić w pliku?',
    cat:'GIT',
    url: '',
    a1: '.gitignore',
    a2: '.gitattribute',
    a3: '.gitsettings',
    a4: '.gitconfig',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie "git stash" cofa projekt do ostatniego commita a zmiany są tracone?',
    cat:'GIT',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Polecenie to cofa do ostatniego commita, ale zmiany są odkładane na bok, możemy je przywrócić poleceniem "git stash pop".',
  },
  {
    question:
      'Aby w systemie git przeglądać historię commitów należy posłużyć się poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git log',
    a2: 'git status',
    a3: 'git stash',
    a4: 'git diff',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie systemu GIT dodające wszystkie pliki na stage to?',
    cat:'GIT',
    url: '',
    a1: 'git add .',
    a2: 'git add *',
    a3: 'git add $',
    a4: 'git add all',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Nie zaleca się używania "git add ." jeżeli w projekcie nie ma pliku ".gitignore".',
  },
  {
    question:
      'Aby w systemie git przeglądać historię commitów w formie list należy dodać do "git log"?',
    cat:'GIT',
    url: '',
    a1: '--oneline',
    a2: '--verbose',
    a3: '--overview',
    a4: '--list',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby w systemie git stwierdzić jakie pliki zostały zmodyfikowane od ostatniego commita należy użyć polecenia?',
    cat:'GIT',
    url: '',
    a1: 'git status',
    a2: 'git log',
    a3: 'git stash',
    a4: 'git rebase',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Ewentualnie polecenie "git diff" może nam pokazać precyzyjnie co i gdzie zostało zmienione.',
  },
  {
    question:
      'Inicjalizacja repozytorium lokalnego git odbywa się poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git init',
    a2: 'git start',
    a3: 'git add',
    a4: 'git rebase',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Commita w systemie GIT wykonujemy poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git commit -m "Message"',
    a2: 'git commit -log "Message"',
    a3: 'git commit message "Message"',
    a4: 'git add commit -m "Message"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli nie użyjemy przełącznika "-m" GIT użyje domyślnego edytora tekstu aby zapytać nas o komentarz.',
  },
  {
    question:
      'Nową gałąź w systemie GIT tworzymy poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git branch "nazwa"',
    a2: 'git checkout "nazwa"',
    a3: 'git link "nazwa"',
    a4: 'git add branch "nazwa"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Gałąź w systemie GIT usuwamy poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git branch -d "nazwa"',
    a2: 'git delete "nazwa"',
    a3: 'git drop "nazwa"',
    a4: 'git gone "nazwa"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Przełączanie gałęzi w systemie git odbywa się za pomocą?',
    cat:'GIT',
    url: '',
    a1: 'git checkout "nazwa"',
    a2: 'git branch "nazwa"',
    a3: 'git switch "nazwa"',
    a4: 'git load "nazwa"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W git odnośnik do zdalnego repozytorium dodajemy poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git remote add origin "link"',
    a2: 'git remote add "link"',
    a3: 'git add remote "link"',
    a4: 'git add remote origin "link"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W git odnośnik do zdalnego repozytorium usuwamy poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git remote remove origin',
    a2: 'git remote remove',
    a3: 'git remove remote',
    a4: 'git remove remote origin',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Wypychanie zmian do zdalnego repozytorium z poziomu konsoli GIT odbywa się poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git push origin nazwa_brancha',
    a2: 'git push nazwa_brancha',
    a3: 'git pull origin nazwa_brancha',
    a4: 'git remote push nazwa_brancha',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pobieranie zmian ze zdalnego repozytorium z poziomu konsoli GIT odbywa się poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git pull origin nazwa_brancha',
    a2: 'git fetch nazwa_brancha',
    a3: 'git get origin nazwa_brancha',
    a4: 'git sync push nazwa_brancha',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Cofanie zmian wprowadzonych w konkretnym commicie odbywa się poleceniem?',
    cat:'GIT',
    url: '',
    a1: 'git revert',
    a2: 'git restore',
    a3: 'git reset',
    a4: 'git rewind',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Portal GITHUB to to samo co GIT?',
    cat:'GIT',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'GITHUB to portal do przechowywania repozytoriów. Dzięki portalu takim jak GITHUB możliwa jest współpraca wielu programistów poprzez GIT.',
  },
  {
    question:
      'Pojęcie "fork" oznacza w terminologii GIT połączenie dwóch gałęzi?',
    cat:'GIT',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pojęcie "fork" oznacza stworzenie własnej kopii projektu na bazie czyjegoś repozytorium na githubie.',
  },
  {
    question:
      'Aby połączyć gałęzie w GIT należy użyć polecenia?',
    cat:'GIT',
    url: '',
    a1: 'git merge "nazwa"',
    a2: 'git rebase "nazwa"',
    a3: 'git checkout "nazwa"',
    a4: 'git load "nazwa"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Modyfikacji komentarza dodanego do ostatniego commita dokonać można za pomocą?',
    cat:'GIT',
    url: '',
    a1: 'git commit --amend',
    a2: 'git commit --modify',
    a3: 'git commit -m',
    a4: 'git commit --change',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //testy jednostkowe
  {
    question:
      'Testy jednostkowe to?',
    cat:'Testy',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'kod testujący inny kod',
    a3: 'kod weryfikujący wynik działania metod/funkcji',
    a4: 'kod wywołujący testowany kod w kontrolowanych warunkach',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Testy jednostkowe powinny być?',
    cat:'Testy',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'szybkie',
    a3: 'niezależne od siebie',
    a4: 'powtarzalne',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Akronim FIRST oznacza?',
    cat:'Testy',
    url: '',
    a1: 'Fast/Isolated/Repeatable/Self-validating/Timely',
    a2: 'Fast/Isolated/Recursive/Self-validating/Timely',
    a3: 'Fast/Iterable/Repeatable/Self-validating/Timely',
    a4: 'Fast/Isolated/Repeatable/Self-validating/Throwable',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Są to cechy jakie musi spełniać każdy test jednostkowy',
  },
  {
    question:
      'Testy jednostkowe składają się z trzech głównych faz w kolejności?',
    cat:'Testy',
    url: '',
    a1: 'arrange/act/assert',
    a2: 'assert/arrange/act',
    a3: 'assert/act/arrange',
    a4: 'act/assert/arrange',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Arrange przygotowuje kod testowany, Act wykonuje testowaną akcję, Assert porównuje wynik z wartością oczekiwaną.',
  },
  {
    question:
      'Frameworkiem do pisania testów jednostkowych w języku C++ jest?',
    cat:'Testy',
    url: '',
    a1: 'Google Tests',
    a2: 'JUnit',
    a3: 'NUnit',
    a4: 'XUnit',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Frameworkiem do pisania testów jednostkowych w języku C# jest?',
    cat:'Testy',
    url: '',
    a1: 'XUnit',
    a2: 'PyTest',
    a3: 'JUnit',
    a4: 'Google Tests',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Można jeszcze używać NUnita.',
  },
  {
    question:
      'Modułem umożliwiającym testy w języku JavaScript i środowisku NodeJS jest?',
    cat:'Testy',
    url: '',
    a1: 'jest',
    a2: 'jtest',
    a3: 'ntest',
    a4: 'google tests',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Błędnym stwierdzeniem o klasie Assert w języku C# jest to, że?',
    cat:'Testy',
    url: '',
    a1: 'Refaktoryzuje kod',
    a2: 'Sprawdza prawdziwość danego wyrażenia',
    a3: 'Wykorzystuje się ją przy testowaniu',
    a4: 'Udostępnia metody statyczne',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Assert to zestaw metod porównujących/testujących.',
  },
  {
    question:
      'TDD (Test Driven Development) to?',
    cat:'Testy',
    url: '/images/TDD.png',
    a1: 'Metodologia pisania oprogramowania gdzie testy pisze się przed kodem testowanym',
    a2: 'Wzorzec projektowy',
    a3: 'Metodologia pisania oprogramowania gdzie testy wykorzystuje się do dokumentowania kodu',
    a4: 'Żadne z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'TDD to takie na pierwszy rzut oka dziwne podejście, które polega na tym by najpierw wymyślić test do nie istniejącego kodu a później sam kod. Jak się tego używa to nabiera to więcej sensu.',
  },
  {
    question:
      'W TDD najpierw piszemy testy a później testowany kod?',
    cat:'Testy',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W TDD najpierw piszemy test do kodu, który nie istnieje a później sam kod. Nabiera to więcej sensu jak się w tym trochę popisze :)',
  },
  {
    question:
      '"Mock" to pojęcie określające?',
    cat:'Testy',
    url: '',
    a1: 'atrapę obiektu',
    a2: 'pewien rodzaj asercji',
    a3: 'tag oznaczający metodę jako test',
    a4: 'żaden z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Atrapa obiektu, służy do pomijania pewnych funkcjonalności. Jeżeli klasa A korzysta z klasy B w formie zależności w czasie testu możemy podrzucić do A atrapę B by część kodu znajdująca się w klasie B nie wykonała się.',
  },
  {
    question:
      '"Mock" tworzony jest w celu?',
    cat:'Testy',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'wyłączenia części kodu znajdującego się w klasie, której atrapę tworzymy',
    a3: 'zmiany zachowania pewnego kodu, który wykorzystuje jako zależność klasę, której atrapę tworzymy',
    a4: 'wygenerowania pustej implementacji jakiegoś interfejsu polimorficznego',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Atrapa obiektu, służy do pomijania pewnych funkcjonalności. Jeżeli klasa A korzysta z klasy B w formie zależności w czasie testu możemy podrzucić do A atrapę B by część kodu znajdująca się w klasie B nie wykonała się.',
  },
  {
    question:
      'Nie powinno się testować automatycznie kontrolerów MVC?',
    cat:'Testy',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Metody kontrolera przyjmują żądanie, wywołują jakąś logikę i zwracają status lub widok. Testujemy bezpośrednio logikę.',
  },
  {
    question:
      'Nie powinno się testować funkcji podpinanych pod eventy/callbacki GUI?',
    cat:'Testy',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Takie funkcję powinny tylko wywoływać logikę znajdującą się w innych funkcjach. Powinniśmy testować te inne funkcje.',
  },
  {
    question:
      'Każda metoda klasy powinna być przetestowana automatycznie?',
    cat:'Testy',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Im więcej testów tym lepiej. Ale nie testujemy prywatnych rzeczy oraz metod, które są trywialne.',
  },
  {
    question:
      'Da się przetestować bezpośrednio metody prywatne?',
    cat:'Testy',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie powinno się testować metod prywatnych bezpośrednio, co nie oznacza, że się nie da.',
  },
  {
    question:
      'Test, który raz przechodzi pozytywnie a raz negatywnie to dobry test?',
    cat:'Testy',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Wniosek się da z tego wyciągnąć, ale zachowanie takie jest sprzeczne z zasadami testowania.',
  },
  //wzorce i zasady programowania
  {
    question:
      'Zasada, mówiąca o tym by unikać powtórzeń w kodzie to?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'DRY',
    a2: 'KISS',
    a3: 'YAGNI',
    a4: 'SOLID',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'DRY - Dont Repeat Yourself, KISS - Keep It Simple Stupid, YAGNI - You Arent Gona Need It, SOLID - 5 zasad programowania obiektowego',
  },
  {
    question:
      'Zasada mówiąca o tym by utrzymywać kod w jak najprostszej formie to?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'KISS',
    a2: 'DRY',
    a3: 'YAGNI',
    a4: 'SOLID',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'KISS - Keep It Simple Stupid, DRY - Dont Repeat Yourself, YAGNI - You Arent Gona Need It, SOLID - 5 zasad programowania obiektowego',
  },
  {
    question:
      'Zasada mówiąca o tym by nie zostawiać w kodzie nieużywanych fragmentów implementacji?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'YAGNI',
    a2: 'KISS',
    a3: 'DRY',
    a4: 'SOLID',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'YAGNI - You Arent Gona Need It, KISS - Keep It Simple Stupid, DRY - Dont Repeat Yourself, SOLID - 5 zasad programowania',
  },
  {
    question:
      'Zbiór pięciu zasad dobrego kodu obiektowego to?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'SOLID',
    a2: 'FIRST',
    a3: 'YAGNI',
    a4: 'CQRS',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pierwsza zasada SOLID mówi o tym by?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'każda metoda/klasa zajmowała się jedną konkretną rzeczą',
    a2: 'referencje miały typ bazowy a nie typ pochodny',
    a3: 'każda klasa/metoda była otwarta na rozszerzenie a zamknięta na modyfikację',
    a4: 'interfejsy polimorficzne utrzymywać jak najmniejsze',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda metoda powinna zajmować się jedną konkretną rzeczą, na którą wskazuje jej nazwa. Podobnie sytuacja wygląda z całymi klasami.',
  },
  {
    question:
      'Druga zasada SOLID mówi o tym by?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'każda klasa/metoda była otwarta na rozszerzenie a zamknięta na modyfikację',
    a2: 'każda metoda/klasa zajmowała się jedną konkretną rzeczą',
    a3: 'referencje miały typ bazowy a nie typ pochodny',
    a4: 'interfejsy polimorficzne utrzymywać jak najmniejsze',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Rozszerzając klasę/metodę o nowe funkcjonalności nie powinniśmy modyfikować istniejących już w niej elementów. Jeżeli musimy to zrobić to trzeba wziąć pod uwagę fakt, że coś można było zrobić lepiej.',
  },
  {
    question:
      'Piąta zasada SOLID mówi o tym by?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'interfejsy polimorficzne utrzymywać jak najmniejsze',
    a2: 'każda klasa/metoda była otwarta na rozszerzenie a zamknięta na modyfikację',
    a3: 'każda metoda/klasa zajmowała się jedną konkretną rzeczą',
    a4: 'referencje miały typ bazowy a nie typ pochodny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli klasa B dziedziczy z klasy A to obiekt powinniśmy trzymać w referencji typu A np. "A obj = new B()".',
  },
  {
    question:
      'Piąta zasada SOLID nazywana jest?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'dependency inversion',
    a2: 'dependency injection',
    a3: 'dependency infection',
    a4: 'dependency isolation',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chodzi w tym przypadku o inwersję a nie wstrzykiwanie.',
  },
  {
    question:
      'Zasada SOLID mówiąca, że zamiast jednej klasy zawierającej 2000 linii kodu lepsze jest 20 małych klas?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'S',
    a2: 'L',
    a3: 'I',
    a4: 'O',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Single Responsiblity Principle - zasad pojedynczej odpowiedzialności',
  },
  {
    question:
      'We wzorcu MVC na akcję użytkownika reaguje?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'kontroler',
    a2: 'widok',
    a3: 'model',
    a4: 'UI',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Kontroler zajmuje się sterowaniem i przetwarzaniem żądań.',
  },
  {
    question:
      'Pochodną wzorca MVC jest?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'MVVM Model View ViewModel',
    a3: 'HMVC Hierarchical Model View Controller',
    a4: 'MVP Model View Presenter',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Skrót SPA oznacza?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Single-Page Application',
    a2: 'Simple-Page Application',
    a3: 'Super-Page Application',
    a4: 'Super-Pure Application',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'SPA wczytuje całą stronę do przeglądarki a później tylko odświeża swoje bloki komunikując się najczęściej z REST API.',
  },
  {
    question:
      'Strona typu SPA bazuje na?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'przeładowywaniu pojedynczych elementów strony',
    a2: 'zakładkach i podstronach',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'SPA wczytuje całą stronę do przeglądarki a później tylko odświeża swoje bloki komunikując się najczęściej z REST API.',
  },
  {
    question:
      'Skrót ORM rozwija się?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Object Relational Mapping',
    a2: 'Object Reconstruct Mapping',
    a3: 'Object Relational Model',
    a4: 'Object Relational Macro',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'ORM to biblioteka, która mapuje tabele na klasy a rekordy na obiekty. W wyniku zapytania SELECT dostajemy listę obiektów odpowiedniej klasy.',
  },
  {
    question:
      'Przykładem biblioteki typu ORM nie jest?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'NSubstitude',
    a2: 'Entity Framework',
    a3: 'NHiberante',
    a4: 'PDO',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'NSubstitude to biblioteka do tworzenia mocków w C#.',
  },
  {
    question:
      'Zasada "dependency injection" mówi, aby zależności wstrzykiwać z zewnątrz przez?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'konstruktor',
    a3: 'setter',
    a4: 'pole',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najczęściej konstruktory, ale można również wstrzykiwać zależności przez setter lub pole (łamiemy zasadę hermetyzacji).',
  },
  {
    question:
      'Na obrazku klasa Repository jest dla klasy Server?',
    cat:'Inżynieria oprogramowania',
    url: '/images/repo.png',
    a1: 'Zależnością miękką',
    a2: 'Zależnością twardą',
    a3: 'Klasą pochodną',
    a4: 'żadne z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Na obrazku klasa RepositoryMock jest dla klasy Repository?',
    cat:'Inżynieria oprogramowania',
    url: '/images/repo.png',
    a1: 'Klasą pochodną',
    a2: 'Zależnością miękką',
    a3: 'Zależnością twardą',
    a4: 'żadne z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Typ Repository może zostać określony mianem typu abstrakcyjnego?',
    cat:'Inżynieria oprogramowania',
    url: '/images/repo.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'UML to skrót od?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Unified Modeling Language',
    a2: 'Unified Mapping Language',
    a3: 'Universal Modeling Language',
    a4: 'Universal Mapping Language',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'UML to standard wielu diagramów, które służą do projektowania i dokumentowania oprogramowania.',
  },
  {
    question:
      'Diagram UML przedstawiający relacje między klasami to',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Diagram klas',
    a2: 'Diagram ERD',
    a3: 'Diagram sekwencji',
    a4: 'Diagram aktywności',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Diagram ERD służy do przedstawiania budowy baz danych. Diagram sekwencji i aktywności opisuje działanie aplikacji.',
  },
  {
    question:
      'Diagram UML przedstawiający relacje między tabelami bazy danych to',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Diagram ERD',
    a2: 'Diagram klas',
    a3: 'Diagram sekwencji',
    a4: 'Diagram aktywności',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Diagram klas przedstawia relacje między klasami. Diagram sekwencji i aktywności opisuje działanie aplikacji.',
  },
  {
    question:
      'Diagram UML przedstawiający przepływ sterowania w klasach to',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Diagram sekwencji',
    a2: 'Diagram ERD',
    a3: 'Diagram klas',
    a4: 'Diagram aktywności',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Diagram UML przedstawiający funkcjonalności projektu to',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Diagram przypadków użycia',
    a2: 'Diagram ERD',
    a3: 'Diagram klas',
    a4: 'Diagram aktywności',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Diagram UML na ilustracji to?',
    cat:'Inżynieria oprogramowania',
    url: '/images/usagediagram.png',
    a1: 'Diagram przypadków użycia',
    a2: 'Diagram ERD',
    a3: 'Diagram klas',
    a4: 'Diagram aktywności',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Diagram UML na ilustracji to?',
    cat:'Inżynieria oprogramowania',
    url: '/images/sekdiagram.png',
    a1: 'Diagram sekwencji',
    a2: 'Diagram ERD',
    a3: 'Diagram klas',
    a4: 'Diagram aktywności',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W diagramie klas UML pola protected oznacza się symbolem?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: '#',
    a2: '-',
    a3: '+',
    a4: '@',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '+ oznacza elementy publiczne, # elementy chronione, - elementy prywatne',
  },
  {
    question:
      'W diagramie klas UML pola private oznacza się symbolem?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: '-',
    a2: '#',
    a3: '+',
    a4: '@',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '+ oznacza elementy publiczne, # elementy chronione, - elementy prywatne',
  },
  {
    question:
      'W diagramie klas UML pola public oznacza się symbolem?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: '+',
    a2: '#',
    a3: '-',
    a4: '@',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '+ oznacza elementy publiczne, # elementy chronione, - elementy prywatne',
  },
  {
    question:
      'Języki, w których programista definiuje co chce uzyskać to języki?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'deklaratywne',
    a2: 'imperatywne',
    a3: 'obiektowe',
    a4: 'proceduralne',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Języki, w których programista definiuje jak chce uzyskać wynik to języki?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'imperatywne',
    a2: 'deklaratywne',
    a3: 'obiektowe',
    a4: 'proceduralne',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //http rest ect
  {
    question:
      'Skrót HTTP rozwija się?',
    cat:'REST',
    url: '',
    a1: 'Hypertext Transfer Protocol',
    a2: 'Hypermarkup Transfer Protocol',
    a3: 'HTML Transfer Protocol',
    a4: 'Hyper Transfer Protocol',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Protokół HTTP pracuje na porcie?',
    cat:'REST',
    url: '',
    a1: '80',
    a2: '60',
    a3: '21',
    a4: '100',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'HTTPS pracuje na 443',
  },
  {
    question:
      'Dane przekazywane do protokoły HTTP/HTTPS są w formacie?',
    cat:'REST',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'JSON',
    a3: 'XML',
    a4: 'zwykłego tekstu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Dane odbierane i wysyłane przez HTTP to zawsze tekst. Co w tym tekście jest to inna kwestia.',
  },
  {
    question:
      'Metodą protokołu HTTP/HTTPS odpowiedzialną za pobieranie danych jest?',
    cat:'REST',
    url: '',
    a1: 'GET',
    a2: 'PUT',
    a3: 'POST',
    a4: 'HEAD',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metodą protokołu HTTP/HTTPS odpowiedzialną za wysyłanie danych jest?',
    cat:'REST',
    url: '',
    a1: 'POST',
    a2: 'PUT',
    a3: 'GET',
    a4: 'HEAD',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metodą protokołu HTTP/HTTPS odpowiedzialną za aktualizowanie danych jest?',
    cat:'REST',
    url: '',
    a1: 'PUT',
    a2: 'POST',
    a3: 'GET',
    a4: 'HEAD',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metodą protokołu HTTP/HTTPS odpowiedzialną za usuwanie danych jest?',
    cat:'REST',
    url: '',
    a1: 'DELETE',
    a2: 'REMOVE',
    a3: 'POST',
    a4: 'PATCH',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Kod sukcesu HTTP/HTTPS to?',
    cat:'REST',
    url: '',
    a1: '200',
    a2: '300',
    a3: '400',
    a4: '500',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Kod "nie znaleziono" HTTP/HTTPS to?',
    cat:'REST',
    url: '',
    a1: '404',
    a2: '202',
    a3: '400',
    a4: '102',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'JWT to skrót od?',
    cat:'REST',
    url: '',
    a1: 'JSON Web Token',
    a2: 'JavaScript Web Token',
    a3: 'Java Web Token',
    a4: 'JavaScript Web Type',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'JWT służy do autoryzacji/logowania do stron za pomocą REST API.',
  },
  {
    question:
      'API typu REST wymienia dane w formacie zwanym?',
    cat:'REST',
    url: '',
    a1: 'JSON',
    a2: 'XML',
    a3: 'HTML',
    a4: 'JWT',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chodzi o format JSON, który jest dużo lżejszy niż XML.',
  },
  {
    question:
      'REST to skrót od?',
    cat:'REST',
    url: '',
    a1: 'Representational State Transfer',
    a2: 'Representational Schema Transfer',
    a3: 'Representational State Transmision',
    a4: 'Representational Schema Transmision',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Programem do testowania REST API jest?',
    cat:'REST',
    url: '',
    a1: 'Postman',
    a2: 'TeamViewer',
    a3: 'Putty',
    a4: 'HxDView',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Postman to wygodne narzędzie do testowania API.',
  },
  {
    question:
      'Protokół HTTPS to HTTP stosujący szyfrowanie?',
    cat:'REST',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W zwykłym HTTP wszystko wysyłane jest gołym tekstem. Narzędzia takie jak WireShark pozwalają podejrzeć zawartość pakietu.',
  },
  //technologie webowe
  {
    question:
      'Wirus, który podszywa się pod legalny i bezpieczny program to?',
    cat:'Webowe',
    url: '',
    a1: 'Koń trojański',
    a2: 'Robak',
    a3: 'Ransomware',
    a4: 'Spyware',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Atakiem na strony internetowe polegającym na obciążeniu serwera dużą ilością zapytań jest',
    cat:'Webowe',
    url: '',
    a1: 'DDoS',
    a2: 'XSS',
    a3: 'Hijacking',
    a4: 'Phishing',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Podatność stron internetowych polegająca na wstrzykiwaniu własnego kodu do strony to?',
    cat:'Webowe',
    url: '',
    a1: 'XSS',
    a2: 'SQL Injection',
    a3: 'Hijacking',
    a4: 'Session poisoning',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli nie filtrujemy odpowiednio danych wejściowych ktoś może podać w formularzu kod JavaScript, który w pewnych sytuacjach/zależnie od logiki aplikacji może coś zrobić.',
  },
  {
    question:
      'Dodanie zapytania SQL do istniejącego już zapytania nazywane jest?',
    cat:'Webowe',
    url: '',
    a1: 'SQL Injection',
    a2: 'XSS',
    a3: 'Hijacking',
    a4: 'Session poisoning',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Bardzo trudny atak do wykonania w dzisiejszych czasach. Wyobraźmy sobie, że hasło z formularza HTML jest doklejane jako string do zapytania SQL w klauzuli WHERE. Co się stanie jak ktoś wpisze w pole z hasłem jakieś dziwne zapytanie SQL?',
  },
  {
    question:
      'Atakiem polegającym na zmuszeniu przeglądarki ofiary do wykonania pewnej akcji jest?',
    cat:'Webowe',
    url: '',
    a1: 'CSRF',
    a2: 'XSS',
    a3: 'CQRS',
    a4: 'ASMAX',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Językiem typowo server-side jest?',
    cat:'Webowe',
    url: '',
    a1: 'PHP',
    a2: 'JavaScript',
    a3: 'C#',
    a4: 'CSS',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'PHP da się uruchomić tylko z pomocą serwera APACHE. Oczywiście nie potrzebujemy fizycznego serwera, ponieważ mamy XAMPA oraz UsbWebServer.',
  },
  {
    question:
      'Bootstrap to?',
    cat:'Webowe',
    url: '',
    a1: 'framework z gotowymi stylami CSS',
    a2: 'framework do sprawniejszego manipulowania elementami drzewa DOM',
    a3: 'framework do operacji asynchronicznych w JS',
    a4: 'framework do operacji na REST API w JS',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Bootstrap bazuje na selektorach?',
    cat:'Webowe',
    url: '',
    a1: 'klasy',
    a2: 'id',
    a3: 'nazwy',
    a4: 'atrybutu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap kontenerem ze stałą szerokością jest?',
    cat:'Webowe',
    url: '',
    a1: '.container',
    a2: '.container-fixed',
    a3: '.container-fluid',
    a4: '.container-static',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap system siatki bazuje na _ kolumnach?',
    cat:'Webowe',
    url: '',
    a1: '12',
    a2: '16',
    a3: '14',
    a4: '10',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap klasą nadającą tabeli tak zwany styl zebry (w paski) jest?',
    cat:'Webowe',
    url: '',
    a1: '.table-striped',
    a2: '.table-zebra',
    a3: '.table-bordered',
    a4: '.table-mixed',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chodzi o styl, gdzie co drugi wiersz ma delikatni mocniejszy cień.',
  },
  {
    question:
      'W BootStrap klasą nadającą tabeli obramowanie jest?',
    cat:'Webowe',
    url: '',
    a1: '.table-bordered',
    a2: '.table-bold',
    a3: '.table-striped',
    a4: '.table-border',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap klasą nadającą elementowy <div> styl przypominający powiadomienie jest?',
    cat:'Webowe',
    url: '',
    a1: '.alert-xxx',
    a2: '.notice-xxx',
    a3: '.warning-xxx',
    a4: '.info-xxx',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap klasą tworzącą ciemny pasek nawigacyjny jest?',
    cat:'Webowe',
    url: '',
    a1: '.navbar-inverse',
    a2: '.navbar-dark',
    a3: '.navbar-black',
    a4: '.navbar-default',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap pluginem do tworzenia wyskakujących okienek jest?',
    cat:'Webowe',
    url: '',
    a1: 'Modal',
    a2: 'MessageBox',
    a3: 'Window',
    a4: 'Popup',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap klasa konwertująca napis na jego odpowiednik dużymi literami to?',
    cat:'Webowe',
    url: '',
    a1: 'text-uppercase',
    a2: 'uppercase',
    a3: 'text-capitalize',
    a4: 'ucase',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap klasa tworząca przycisk w kolorze czerwonym to?',
    cat:'Webowe',
    url: '',
    a1: 'btn-danger',
    a2: 'btn-warning',
    a3: 'btn-secondary',
    a4: 'btn-primary',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W BootStrap klasa tworząca przycisk z samym obramowaniem to?',
    cat:'Webowe',
    url: '',
    a1: '.btn-outline-primary',
    a2: '.btn-primary-outline',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W BootStrap wymaga do działania biblioteki jQuery?',
    cat:'Webowe',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Obecne wersje radzą sobie bez jQuery',
  },
  //javascript
  {
    question:
      'Biblioteką Javascript, która służy do asynchronicznej komunikacji z serwerem?',
    cat:'Javascript',
    url: '',
    a1: 'AJAX',
    a2: 'jQuery',
    a3: 'Vue.js',
    a4: 'Angular',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Biblioteką Javascript, która ułatwia manipulowanie drzewem DOM jest?',
    cat:'Javascript',
    url: '',
    a1: 'jQuery',
    a2: 'AJAX',
    a3: 'React',
    a4: 'Angular',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'JavaScript pozwala przypisać funkcję do zmiennej?',
    cat:'Javascript',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Oczywiście np. w formie wyrażenia lambda.',
  },
  {
    question:
      'W JS typami prymitywnymi są',
    cat:'Javascript',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'String',
    a3: 'Number',
    a4: 'Boolean',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W JS operatory "===" oraz "==" działają tak samo?',
    cat:'Javascript',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W JS pętle "for in" oraz "for of" działają tak samo?',
    cat:'Javascript',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pętla "for in" zwraca indeks a "for of" zwraca wartość z kolekcji.',
  },
  {
    question:
      'Pobranie długości tablicy w JavaScript wygląda następująco?',
    cat:'Javascript',
    url: '',
    a1: 'let size = tablica.length;',
    a2: 'let size = tablica.length();',
    a3: 'let size = tablica.count;',
    a4: 'let size = tablica.size;',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Funkcja "Math.random()" zwraca?',
    cat:'Javascript',
    url: '',
    a1: 'wartość z przedziału 0 do 1',
    a2: 'wartość z przedziału 0 do +inf',
    a3: 'wartość z przedziału 0 do RAND_MAX',
    a4: 'wartość z przedziału 0 do 10',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Interpolacja stringa w języku JavaScript odbywa się za pomocą składni?',
    cat:'Javascript',
    url: '',
    // eslint-disable-next-line
    a1: '`Ala ma ${count} kotów`',
    // eslint-disable-next-line
    a2: '"Ala ma ${count} kotów"',
    // eslint-disable-next-line
    a3: '\'Ala ma ${count} kotów\'',
    a4: 'w JS nie ma takiego mechanizmu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aplikacje Web wykonane we frameworku Angular lub bibliotece React i działające na domyślnych ustawieniach portów można uruchomić na lokalnym serwerze, wpisując w przeglądarce?',
    cat:'Javascript',
    url: '',
    a1: 'localhost:3000 (React) lub localhost:4200 (Angular)',
    a2: 'localhost:8080 (React) lub localhost:8000 (Angular)',
    a3: 'localhost:5001 (React) lub localhost:8080 (Angular)',
    a4: 'localhost:8000 (React) lub localhost:49887 (Angular)',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Który z testów funkcji "fun1" zakończy się sukcesem?',
    cat:'Javascript',
    url: '/images/jesttests.png',
    a1: 'C',
    a2: 'D',
    a3: 'B',
    a4: 'A',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Uruchomienie aplikacji React wymaga środowiska Node.Js?',
    cat:'Javascript',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Nazwa komponentu React musi zaczynać się od dużej litery?',
    cat:'Javascript',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Pisząc w technologii React by nadać klasę elementowi należy posłużyć się atrybutem',
    cat:'Javascript',
    url: '',
    a1: 'className',
    a2: 'class',
    a3: 'classes',
    a4: 'elementClass',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pisząc w technologii React by nadać klasę elementowi styl bezpośrednio w znaczniku należy użyć konstrukcji',
    cat:'Javascript',
    url: '',
    a1: 'style={{color:"red"}}',
    a2: 'style={{color:red}}',
    a3: 'style={color:"red"}',
    a4: 'style={color:red}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pisząc w technologii React by przypisać do zdarzenia onClick własną funkcję należy posłużyć się konstrukcją',
    cat:'Javascript',
    url: '',
    a1: 'onClick={myFunc}',
    a2: 'onClick={muFunc()}',
    a3: 'onClick={{myFunc}}',
    a4: 'onClick={{myFunc()}}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pisząc w technologii React utworzyć zmienną stanu w komponencie funkcyjnym należy użyć hooka',
    cat:'Javascript',
    url: '',
    a1: 'useState()',
    a2: 'setState()',
    a3: 'useRef()',
    a4: 'setRef()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Komenda uruchamiająca aplikację React na serwerze deweloperskim to',
    cat:'Javascript',
    url: '',
    a1: 'npm start',
    a2: 'npm run dev',
    a3: 'npm start dev',
    a4: 'npm serve',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Argument "props" w React służy do',
    cat:'Javascript',
    url: '',
    a1: 'przekazywania wartości z rodzica do komponentów zagnieżdżonych',
    a2: 'ustawiania stanu dziecka przez rodzica',
    a3: 'ustawiania właściwości obiektu klasy',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W react w przypadku dynamicznego budowania listy elementów np za pomocą funkcji map(), każdemu elementowi należy nadać?',
    cat:'Javascript',
    url: '',
    a1: 'unikatową wartość atrybutu key',
    a2: 'unikatową wartość atrybutu id',
    a3: 'unikatową wartość atrybutu class',
    a4: 'nic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Jaki rodzaj elementu zostanie wyrenderowany przez poniższy kod React?',
    cat:'Javascript',
    url: '/images/react1.png',
    a1: 'h1',
    a2: 'ReactDom',
    a3: 'Component',
    a4: 'div',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem, który służy do warunkowego renderowania elementów w React jest?',
    cat:'Javascript',
    url: '',
    a1: '&&',
    a2: '?',
    a3: '??',
    a4: '||',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Operatorem, który służy do warunkowego renderowania elementów w React jest?',
    cat:'Javascript',
    url: '',
    a1: '?:',
    a2: '?',
    a3: '$',
    a4: '||',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Hookiem React, który poprawia optymalizację pamięci jest?',
    cat:'Javascript',
    url: '',
    a1: 'useMemo',
    a2: 'useReducer',
    a3: 'useContext',
    a4: 'useRef',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Błędem w poniższym kodzie React jest?',
    cat:'Javascript',
    url: '/images/react2.png',
    a1: 'nazwa komponentu',
    a2: 'sposób zapisu argumentów funkcji',
    a3: 'zapis return zamiast return()',
    a4: 'średnik po klamrze zamykającej funkcję',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Na obrazku widzimy definicję?',
    cat:'Javascript',
    url: '/images/react4.png',
    a1: 'zmiennej stanowej',
    a2: 'zmiennej',
    a3: 'tablicy',
    a4: 'listy',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Jeżeli w React dynamicznie zmienimy kolor czcionki za pomocą konstrukcji "getElementById..." to zmiana stanu w komponencie?',
    cat:'Javascript',
    url: '/images/react3.png',
    a1: 'cofnie style do domyślnej wersji',
    a2: 'style zostaną nietknięte',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Po wykonaniu poniższego kodu na ekranie pojawi się napis?',
    cat:'Javascript',
    url: '/images/react6.png',
    a1: 'React is 10 times...',
    a2: 'React is 5 + 5 times...',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Komponenty klasowe React są zobowiązane do posiadania metody?',
    cat:'Javascript',
    url: '',
    a1: 'render()',
    a2: 'return()',
    a3: 'onAppear()',
    a4: 'onLoad()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Mechanizm wykorzystywany do renderowania elementów JSX w React to?',
    cat:'Javascript',
    url: '',
    a1: 'Babel',
    a2: 'JSX Runtime',
    a3: 'JSX Compiler',
    a4: 'Paper',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Kod na obrazku wypisze?',
    cat:'Javascript',
    url: '/images/react7.png',
    a1: '{make: \'Ford\', model: \'Mustang\'}',
    a2: '{{make: \'Ford\', model: \'Mustang\'}}',
    a3: '{car: \'Ford\', car: \'Mustang\'}}',
    a4: '{car: {make: \'Ford\', model: \'Mustang\'}}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Każde definicja komponentu funkcyjnego React musi zaczynać się od słowa kluczowego "function"?',
    cat:'Javascript',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W poniższym kodzie React oba onClick\'i działają tak samo?',
    cat:'Javascript',
    url: '/images/react5.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pierwszy zapis jest poprawny, ponieważ przypisujemy do zdarzenia referencję na funkcję. W drugim przypadku od razu ją wywołujemy dlatego też w konsoli zobaczymy wypisywany ciąg tuż po wyrenderowaniu komponentu.',
  },
  //bazy danych
  {
    question:
      'Co to jest DBMS',
    cat:'Bazy danych',
    url: '',
    a1: 'system zarządzania bazą danych',
    a2: 'obiektowy język programowania',
    a3: 'kaskadowy arkusz stylów',
    a4: 'strukturalny język zapytań do bazy danych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'DBMS - DataBase Management System, MySQL jest przykładem DBMS',
  },
  {
    question:
      'Krotka w bazach relacyjnych to',
    cat:'Bazy danych',
    url: '',
    a1: 'wiersz tabeli',
    a2: 'kolumna tabeli',
    a3: 'komórka tabeli',
    a4: 'cała tabela',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Atrybut w relacyjnych bazach danych to',
    cat:'Bazy danych',
    url: '',
    a1: 'kolumna tabeli',
    a2: 'wiersz tabeli',
    a3: 'komórka tabeli',
    a4: 'cała tabela',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Kolumna/Kolumny jednoznacznie identyfikujące rekord w tabeli relacyjnej bazy danych to',
    cat:'Bazy danych',
    url: '',
    a1: 'klucz podstawowy',
    a2: 'klucz obcy',
    a3: 'klucz naturalny',
    a4: 'klucz sztuczny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Kolumna wykorzystywana do tworzenia relacji w relacyjnych bazach danych to',
    cat:'Bazy danych',
    url: '',
    a1: 'klucz obcy',
    a2: 'klucz podstawowy',
    a3: 'klucz naturalny',
    a4: 'klucz sztuczny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Klucz naturalny w bazach relacyjnych to',
    cat:'Bazy danych',
    url: '',
    a1: 'kolumna, która jest naturalnym atrybutem przechowywanej encji',
    a2: 'kolumna, która została dodana by jednoznacznie identyfikować rekord',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Kluczem sztucznym jest np. Id, ponieważ nie ma ono nic wspólnego z encją przechowywaną w tabeli. Kluczem naturalnym będzie np. PESEL, ponieważ jest powiązany z przechowywaną encją (np. studentem) i jest unikalną wartością jednoznacznie identyfikującą rekord.',
  },
  {
    question:
      'Relacja wiele do wielu wymaga',
    cat:'Bazy danych',
    url: '',
    a1: 'trzeciej tabeli łączącej',
    a2: 'dwóch kluczy obcych',
    a3: 'dwóch kluczy podstawowych',
    a4: 'dwóch kluczy sztucznych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'O tym, że każda komórka tabeli w bazie relacyjnej musi przechowywać jedną wartość mówi',
    cat:'Bazy danych',
    url: '',
    a1: 'Pierwsza postać normalna',
    a2: 'Druga postać normalna',
    a3: 'Trzecia postać normalna',
    a4: 'Czwarta postać normalna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przykład: Mamy tabele Oceny. Kolumna Ocena przechowuje zawsze jedną ocenę, a nie oceny po przecinku. Jeżeli uczeń dostał kolejną ocenę to pojawia się nowy rekord w tabeli.',
  },
  {
    question:
      'O tym, że każda tabela musi przechowywać dane tylko tej konkretnej encji mówi',
    cat:'Bazy danych',
    url: '',
    a1: 'Druga postać normalna',
    a2: 'Pierwsza postać normalna',
    a3: 'Trzecia postać normalna',
    a4: 'Czwarta postać normalna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli tabela nazywa się Students to nie przechowuje ich adresów czy ocen.',
  },
  {
    question:
      'O tym, że każda kolumna może być zależna tylko od klucza głównego mówi',
    cat:'Bazy danych',
    url: '',
    a1: 'Trzecia postać normalna',
    a2: 'Pierwsza postać normalna',
    a3: 'Druga postać normalna',
    a4: 'Czwarta postać normalna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przykład z brutto, netto i tara. Nie ma sensu trzymać w bazie wszystkich trzech wartości jak dwie da się wyliczyć na bazie trzeciej. Nie ma sensu trzymać w bazie daty urodzenia jeżeli da się ją wyciągnąć z numeru PESEL.',
  },
  {
    question:
      'Każda relacja w relacyjnych bazach danych wymaga klucza obcego?',
    cat:'Bazy danych',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Procedura i funkcja to to samo?',
    cat:'Bazy danych',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Procedura nie zwraca wartości.',
  },
  {
    question:
      'Tak zwane aplikacje CRUDowe to',
    cat:'Bazy danych',
    url: '',
    a1: 'aplikacje skupiające się na dodawaniu/czytaniu/aktualizowaniu/usuwaniu danych z bazy',
    a2: 'aplikacje oparte na REST API',
    a3: 'aplikacje typowo frontendowe',
    a4: 'aplikacje tworzące strukturę bazy danych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Aplikacje crudowe to aplikacje, które nie wykonują za dużo logiki a jedynie zapisują i odczytują dane z bazy.',
  },
  {
    question:
      'ENUM oraz SET jako typy kolumn są równoznaczne?',
    cat:'Bazy danych',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W przypadku enuma, musimy wybrać jedną konkretną wartość. W przypadku zbioru możemy do pola wstawić kilka opcji zdefiniowanych w zbiorze.',
  },
  {
    question:
      'Klauzula SQL służąca do modyfikowania struktury tabeli to?',
    cat:'Bazy danych',
    url: '',
    a1: 'alter',
    a2: 'update',
    a3: 'modify',
    a4: 'change',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Klauzula SQL służąca do usuwania tabel to?',
    cat:'Bazy danych',
    url: '',
    a1: 'drop',
    a2: 'delete',
    a3: 'remove',
    a4: 'erase',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Klauzula "limit" w SQL przyjmuje do _ parametrów?',
    cat:'Bazy danych',
    url: '',
    a1: '2',
    a2: '3',
    a3: '1',
    a4: '4',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wiele osób nie wie, że limit przyjmuje opcjonalny parametr, którym jest offset w tabeli. "limit 2, 6" oznacza weź 6 elementów, ale zacznij od trzeciego.',
  },
  {
    question:
      'W SQL sortowanie malejące oznaczamy?',
    cat:'Bazy danych',
    url: '',
    a1: 'ASC',
    a2: 'DESC',
    a3: 'UP',
    a4: 'DOWN',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //php
  {
    question:
      'Rozszerzenie plików PHP to?',
    cat:'PHP',
    url: '',
    a1: '*.php',
    a2: '*.p',
    a3: '*.pp',
    a4: '*.html',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      '"index.php" ma większy priorytet niż "index.html"?',
    cat:'PHP',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pliki php mają większy priorytet.',
  },
  {
    question:
      'Operatorem konkatenacji w PHP jest?',
    cat:'PHP',
    url: '',
    a1: '.',
    a2: '+',
    a3: '^',
    a4: '|',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Może to być niespodzianka, ale łącznie stringów odbywa się za pomocą "."',
  },
  {
    question:
      'W php definicję funkcji poprzedzamy słowem kluczowym?',
    cat:'PHP',
    url: '',
    a1: 'function',
    a2: 'func',
    a3: 'def',
    a4: 'proc',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W php odwołując się do pola klasy musimy użyć konstrukcji?',
    cat:'PHP',
    url: '',
    a1: '$this->...',
    a2: '$self->...',
    a3: '$self.',
    a4: '$this.',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W php słowa kluczowe this oraz self są równoznaczne?',
    cat:'PHP',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'self odnosi się do składowych statycznych klasy',
  },
  {
    question:
      'W php aby zdefiniować tablicę musimy użyć konstrukcji?',
    cat:'PHP',
    url: '',
    a1: '$t = array(1,2,3);',
    a2: '$t = {1,2,3};',
    a3: '$t = [1,2,3];',
    a4: '$t = (1,2,3);',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W php aby pobrać rozmiar tablicy użyjemy funkcji?',
    cat:'PHP',
    url: '',
    a1: 'count();',
    a2: 'size();',
    a3: 'length();',
    a4: 'len();',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W php aby otworzyć plik do odczytu należy posłużyć się składnią?',
    cat:'PHP',
    url: '',
    a1: 'fopen("time.txt","r");',
    a2: 'open("time.txt","r");',
    a3: 'fopen("time.txt");',
    a4: 'open("time.txt");',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Błędną nazwą zmiennej w języku PHP jest?',
    cat:'PHP',
    url: '',
    a1: '$click-counter',
    a2: '$click_counter',
    a3: '$clickCounter',
    a4: '$clickcounter',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Do zmiennych statycznych w PHP odwołujemy się za pomocą operatora?',
    cat:'PHP',
    url: '',
    a1: '::',
    a2: '.',
    a3: '->',
    a4: '@',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby w PHP odnieść się do wartości formularza należy jako indeksu tablicy $_GET/$_POST użyć?',
    cat:'PHP',
    url: '',
    a1: 'wartości atrybutu name danego inputa',
    a2: 'wartości atrybutu class danego inputa',
    a3: 'wartości atrybutu id danego inputa',
    a4: 'wartości atrybutu index danego inputa',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pętla foreach w PHP ma następującą składnie:?',
    cat:'PHP',
    url: '',
    a1: 'foreach ($collection as $item)',
    a2: 'foreach ($item as $collection)',
    a3: 'foreach ($collection in $item)',
    a4: 'foreach ($item in $collection)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W PHP funkcji "rand()" można określić przedział losowania?',
    cat:'PHP',
    url: '',
    a1: 'za pomocą dwóch opcjonalnych parametrów',
    a2: 'za pomocą metody modulo-dodawanie znanej z C++',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  //python
  {
    question:
      'Rozszerzenie plików źródłowych języka Python to?',
    cat:'Python',
    url: '',
    a1: '*.py',
    a2: '*.p',
    a3: '*.pp',
    a4: '*.ph',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Python został zaimplementowany w języku?',
    cat:'Python',
    url: '',
    a1: 'C',
    a2: 'C++',
    a3: 'C#',
    a4: 'F#',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najczęściej mamy do czynienia z implementacją CPython.',
  },
  {
    question:
      'Błędną nazwą zmiennej w języku Python jest?',
    cat:'Python',
    url: '',
    a1: 'click-counter',
    a2: 'click_counter',
    a3: '_clickCounter',
    a4: 'clickCounter',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy w języku Python typy danych są istotne?',
    cat:'Python',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Oczywiście. To, że można ich nie precyzować to nie oznacza, że ich nie ma.',
  },
  {
    question:
      'Funkcją konwertującą int na string w języku Python jest?',
    cat:'Python',
    url: '',
    a1: 'str()',
    a2: 'to_string()',
    a3: 'raw()',
    a4: 'string()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python aby parsować string na int trzeba posłużyć się funkcją',
    cat:'Python',
    url: '',
    a1: 'int()',
    a2: 'parseInt()',
    a3: 'Int.Parse()',
    a4: 'ParseInt()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python aby parsować string na float trzeba posłużyć się funkcją',
    cat:'Python',
    url: '',
    a1: 'float()',
    a2: 'parseFloat()',
    a3: 'Float.Parse()',
    a4: 'ParseFloat()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby zamienić liczbę 0x10 na system dziesiętny w Pythonie należy posłużyć się konstrukcją ?',
    cat:'Python',
    url: '',
    a1: 'int("10", 16)',
    a2: 'int(10, 16)',
    a3: 'int(10, 10)',
    a4: 'int("10", 10)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Pierwszy parametr funkcji "int()" to wartość w formie stringu, drugi to aktualna podstawa.',
  },
  {
    question:
      'Funkcja "float()" konwertuje wartość do liczby typu float. Która z poniższych konstrukcji jest poprawna?',
    cat:'Python',
    url: '',
    a1: 'Wszystkie pozostałe',
    a2: 'float(2)',
    a3: 'float(2.8)',
    a4: 'float("2.8")',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby zamienić liczbę 10 na system ósemkowy w Pythonie należy posłużyć się konstrukcją ?',
    cat:'Python',
    url: '',
    a1: 'oct(10)',
    a2: 'hex(10)',
    a3: 'bin(10)',
    a4: 'dec(10)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy w języku Python można pisać kod realizujący jakieś zadanie poza funkcją?',
    cat:'Python',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Podobnie jak w JavaScript kod można pisać od razu bez funkcji.',
  },
  {
    question:
      'Odpowiednikiem słowa kluczowego "this" w Pythonie jest?',
    cat:'Python',
    url: '',
    a1: 'self',
    a2: 'me',
    a3: 'base',
    a4: 'ptr',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chodzi o słowo kluczowe "self" przekazywane jako argument do każdej metody klasy. W C++ this jest też przekazywane jako pierwszy argument, ale nie widać tego w kodzie.',
  },
  {
    question:
      'W pythonie każda funkcja musi przyjmować jako pierwszy argument "self"?',
    cat:'Python',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jest tak tylko i wyłącznie w przypadku metod klasy.',
  },
  {
    question:
      'Kolekcja o nazwie "tuple" w Pythonie jest mutowalna?',
    cat:'Python',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tuple typu () nie pozwala po zainicjowaniu modyfikować swojej zawartości.',
  },
  {
    question:
      'Kolekcja o nazwie "list" w Pythonie jest mutowalna?',
    cat:'Python',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'List typu [] pozwala po zainicjowaniu modyfikować swoją zawartość.',
  },
  {
    question:
      'W języku Python aby sprawdzić długość kolekcji należy posłużyć się funkcją?',
    cat:'Python',
    url: '',
    a1: 'len()',
    a2: 'length()',
    a3: 'size()',
    a4: 'count()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python logiczne zaprzeczenie wykonujemy za pomocą?',
    cat:'Python',
    url: '',
    a1: 'not',
    a2: '!',
    a3: '~',
    a4: '\\',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'np. if 2 not in list: ...',
  },
  {
    question:
      'W języku Python aby dodać element na koniec listy należy posłużyć się metodą?',
    cat:'Python',
    url: '',
    a1: 'append()',
    a2: 'add()',
    a3: 'push()',
    a4: 'insert()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python istnieje różnica między cudzysłowami a apostrofami?',
    cat:'Python',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W języku PHP istnieje różnica między cudzysłowami a apostrofami?',
    cat:'Python',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Ile znaków wypisze "print(tekst[2:5])" w języku python zakładając, że string ma minimum 6 znaków?',
    cat:'Python',
    url: '',
    a1: '3',
    a2: '4',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Przedział jest prawostronnie otwarty.',
  },
  {
    question:
      'Konstrukcja języka Python "print("ASD"[-1:])" wypisze?',
    cat:'Python',
    url: '',
    a1: 'D',
    a2: 'S',
    a3: 'A',
    a4: 'program zakończy się błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Funkcja języka python, która konwertuje znaki w stringu na ich duże odpowiedniki to?',
    cat:'Python',
    url: '',
    a1: 'string.upper()',
    a2: 'string.toUpper()',
    a3: 'string.toUpperCase()',
    a4: 'żadna z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Funkcja języka python, która pozwala podzielić string na kawałki względem podanego separatora to?',
    cat:'Python',
    url: '',
    a1: 'string.split()',
    a2: 'string.slice()',
    a3: 'string.cut()',
    a4: 'żadna z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Konstruktor klasy w języku Python ma nazwę?',
    cat:'Python',
    url: '',
    a1: '__init__',
    a2: '__create__',
    a3: '__self__',
    a4: 'taką jak nazwa klasy',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby w języku Python wywołać konstruktor klasy bazowej należy użyć konstrukcji?',
    cat:'Python',
    url: '',
    a1: 'super().__init__()',
    a2: 'base.__init__()',
    a3: 'super().create()',
    a4: 'base.create()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python aby utworzyć obiekt klasy ABC należy posłużyć się zapisem?',
    cat:'Python',
    url: '',
    a1: 'obj = ABC()',
    a2: 'obj = new ABC()',
    a3: 'obj = ABC',
    a4: 'obj = new ABC',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python gdy definiowana klasa A dziedziczy z klasy B posługujemy się zapisem',
    cat:'Python',
    url: '',
    a1: 'class A(B)',
    a2: 'class A: B',
    a3: 'class A<B>',
    a4: 'class A{B}',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python definicję klasy rozpoczynamy od słowa kluczowego',
    cat:'Python',
    url: '',
    a1: 'class',
    a2: 'def',
    a3: 'let',
    a4: 'type',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python definicję funkcji rozpoczynamy od słowa kluczowego',
    cat:'Python',
    url: '',
    a1: 'def',
    a2: 'class',
    a3: 'let',
    a4: 'type',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python aby dołączyć inny moduł należy posłużyć się słowem kluczowym',
    cat:'Python',
    url: '',
    a1: 'import',
    a2: 'include',
    a3: 'using',
    a4: 'crate',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pętlą niedostępną w języku Python jest?',
    cat:'Python',
    url: '',
    a1: 'for()',
    a2: 'for..range()',
    a3: 'while()',
    a4: 'do while()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python instrukcja warunkowa przybiera formę:',
    cat:'Python',
    url: '',
    a1: 'if:..elif:..else:',
    a2: 'if:..else if:..else:',
    a3: 'if:..elseif:..else:',
    a4: 'if:..else:..else:',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python wyrażenie lambda przyjmuje formę:',
    cat:'Python',
    url: '',
    a1: 'func = lambda a, b: a + b',
    a2: 'func = (a,b): a + b',
    a3: 'func = (a,b) => a + b',
    a4: 'func = lambda a, b => a + b',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python konstrukcja odpowiedzialna za wycinanie podłańcuchów łańcucha tekstowego to',
    cat:'Python',
    url: '',
    a1: 'operator [start:end]',
    a2: 'substr(start:end)',
    a3: 'operator (start:end)',
    a4: 'substring(start:end)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python metoda na stringu pozwalająca usunąć białe znaki na obu końcach łańcucha to',
    cat:'Python',
    url: '',
    a1: 'strip()',
    a2: 'trim()',
    a3: 'cut()',
    a4: 'slice()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Która konstrukcja w języku Python to lista?',
    cat:'Python',
    url: '',
    a1: '[1,2,3,4]',
    a2: '(1,2,3,4)',
    a3: '{1,2,3,4}',
    a4: 'żadna z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Która konstrukcja w języku Python to tak zwany tuple?',
    cat:'Python',
    url: '',
    a1: '(1,2,3,4)',
    a2: '[1,2,3,4]',
    a3: '{1,2,3,4}',
    a4: 'żadna z wymienionych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python domyślna wartość typów referencyjnych to?',
    cat:'Python',
    url: '',
    a1: 'None',
    a2: 'Null',
    a3: 'null',
    a4: 'none',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"None" to odpowiednik "null" z innych języków.',
  },
  {
    question:
      'W języku Python kolekcje "set" oraz "list" są takie same?',
    cat:'Python',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'W zbiorze nie mogą występować duplikaty.',
  },
  {
    question:
      'W języku Python aby zainicjować listę dziesięcioma wartościami 0 należy posłużyć się konstrukcją?',
    cat:'Python',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'a = [0,0,0,0,0,0,0,0,0,0]',
    a3: 'a = [0]*10',
    a4: 'for i in range(0, 10): a.append(0)',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W języku Python pętla "for i in range(0, 10)" wykona się?',
    cat:'Python',
    url: '',
    a1: '10 razy',
    a2: '11 razy',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Range generuje przedział prawostronnie otwarty czyli [0, 10) w tym wypadku.',
  },
  {
    question:
      'W języku metody __repr__() oraz __str__() służą temu samemu celowi?',
    cat:'Python',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Obie metody mogą służyć do tego samego. Obie zwracają tekstową reprezentacje obiektu. __str__() zwraca powinno zwracać reprezentacje użyteczną dla użytkownika natomiast __repr__() reprezentacje przydatną programiście.',
  },
  {
    question:
      'W języku Python aby wewnątrz funkcji możliwe było zapisanie wartości do globalnej zmiennej należy oznaczyć ją w tejże funkcji słowem kluczowym:',
    cat:'Python',
    url: '',
    a1: 'global',
    a2: 'static',
    a3: 'extern',
    a4: 'main',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jeżeli zapiszemy w funkcji do zmiennej "a", która jest globalna nie oznaczając jej słowem kluczowym "global" język utworzy zmienną lokalną o tej nazwie.',
  },
  {
    question:
      'Interpolacji stringów w języku python można dokonać składnią?',
    cat:'Python',
    url: '',
    a1: 'f"Ala ma {count} kotów!"',
    a2: '$"Ala ma {count} kotów!"',
    a3: '#"Ala ma {count} kotów!"',
    a4: '&"Ala ma {count} kotów!"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to element języka eliminujący formatowanie stringów za pomocą ich konkatenacji ze zmiennymi.',
  },
  {
    question:
      'W języku Python aby otworzyć plik należy posłużyć się konstrukcją',
    cat:'Python',
    url: '',
    a1: 'f = open("plik.txt")',
    a2: 'f = read("plik.txt")',
    a3: 'f = fopen("plik.txt")',
    a4: 'f = with("plik.txt")',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Menedżerem pakietów dla języka Python jest',
    cat:'Python',
    url: '',
    a1: 'pip',
    a2: 'cpython',
    a3: 'jpython',
    a4: 'nuget',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //windows/linux cmd
  {
    question:
      'Polecenie wiersza poleceń Windows służące do wyświetlania zawartości katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'dir',
    a2: 'cd',
    a3: 'ls',
    a4: 'pwd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do wyświetlania zawartości katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'ls',
    a2: 'cd',
    a3: 'dir',
    a4: 'pwd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Windows służące do przełączania katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cd',
    a2: 'dir',
    a3: 'ls',
    a4: 'pwd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do przełączania katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cd',
    a2: 'ls',
    a3: 'dir',
    a4: 'pwd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Windows służące do tworzenia katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'mkdir',
    a2: 'create',
    a3: 'cd',
    a4: 'dir',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do tworzenia katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'mkdir',
    a2: 'create',
    a3: 'ls',
    a4: 'dir',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Windows służące do cofania się w strukturze katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cd ../cd..',
    a2: 'cd ./cd .',
    a3: 'ls',
    a4: 'pwd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do cofania się w strukturze katalogów to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cd ..',
    a2: 'cd..',
    a3: 'cd.',
    a4: 'bd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Spacja po cd jest istotna :)',
  },
  {
    question:
      'Polecenie wiersza poleceń Windows służące do wyświetlania aktualnej ścieżki to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cd',
    a2: 'chdsk',
    a3: 'ls',
    a4: 'pwd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do wyświetlania aktualnej ścieżki to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'pwd',
    a2: 'cd',
    a3: 'chmod',
    a4: 'ls',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Windows służące do czyszczenia okna konsoli to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cls',
    a2: 'clear',
    a3: 'c',
    a4: 'clearis',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do czyszczenia okna konsoli to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'clear',
    a2: 'cls',
    a3: 'c',
    a4: 'clearis',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Windows służące do sprawdzenia adresacji IP to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'ipconfig',
    a2: 'ifconfig',
    a3: 'iconfig',
    a4: 'netstat',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie wiersza poleceń Linux służące do sprawdzenia adresacji IP to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'ifconfig',
    a2: 'ipconfig',
    a3: 'iconfig',
    a4: 'netstat',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie systemu linux pozwalające usunąć plik lub katalog to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'rm',
    a2: 'remove',
    a3: 'rd',
    a4: 'del',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie systemu linux pozwalające zmienić nazwę pliku to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'mv',
    a2: 'rm',
    a3: 'chn',
    a4: 'rename',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jak zmienić nazwę pliku? Przenieść go w to samo miejsce pod inną nazwą.',
  },
  {
    question:
      'Polecenie systemu linux pozwalające przenieść plik to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'mv',
    a2: 'rm',
    a3: 'cp',
    a4: 'ls',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie systemu windows pozwalające przenieść plik to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'move',
    a2: 'mv',
    a3: 'cp',
    a4: 'dir',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie systemu linux pozwalające skopiować plik to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'cp',
    a2: 'rm',
    a3: 'mv',
    a4: 'ls',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Polecenie systemu windows pozwalające skopiować plik to?',
    cat:'Windows/Linux',
    url: '',
    a1: 'copy',
    a2: 'rm',
    a3: 'cp',
    a4: 'ls',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  //pytania ogólne
  {
    question:
      'Jak przekazać callback do funkcji w C#?',
    cat:'C#',
    url: '',
    a1: 'trzeba posłużyć się odpowiednim delegatem',
    a2: 'trzeba posłużyć się odpowiednim wskaźnikiem',
    a3: 'trzeba posłużyć się "boxingiem"',
    a4: 'jest to nie możliwe',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Np do metody FirstOrDefault() w Linq podajemy lambde, która jest predykatem (Predicate). ',
  },
  {
    question:
      'Jak przekazać callback do funkcji w C/C++?',
    cat:'C/C++',
    url: '',
    a1: 'trzeba posłużyć się odpowiednim wskaźnikiem',
    a2: 'trzeba posłużyć się odpowiednim delegatem',
    a3: 'trzeba posłużyć się odpowiednią referencja',
    a4: 'to nie możliwe',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Lambda jest dobrym narzędziem do tworzenia krótkich callbacków?',
    cat:'C/C++',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Znak "\\n" oznacza?',
    cat:'C/C++',
    url: '',
    a1: 'znak nowej linii',
    a2: 'znak powrotu kursora',
    a3: 'znak tabulacji',
    a4: 'znak ucieczki',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W teorii znak ten przenosi kursor w pionie linie niżej, ale nie cofa do początku wiersza. W praktyce zostało to uproszczone i znak ten wykonuje obie operacje.',
  },
  {
    question:
      'Znak "\\r" oznacza?',
    cat:'C/C++',
    url: '',
    a1: 'znak powrotu kursora',
    a2: 'znak nowej linii',
    a3: 'znak tabulacji',
    a4: 'znak ucieczki',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Znak ten przenosi kursor do początku wiersza.',
  },
  {
    question:
      'System kodowania znaków, który przypisuje liczbą od 0 do 127 znaki to?',
    cat:'C/C++',
    url: '',
    a1: 'ASCII',
    a2: 'UTF',
    a3: 'UNICODE',
    a4: 'CRT',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najbardziej podstawowy rodzaj kodowania znaków to ASCII. UNICODE to ogromne rozszerzenie zawierające znaki specjalne każdego języka oraz wiele innych symboli.',
  },
  {
    question:
      'Poniższa tabelka przedstawia?',
    cat:'C/C++',
    url: '/images/ascii.gif',
    a1: 'tabele ASCII',
    a2: 'tabele UNICODE',
    a3: 'tablice kodów base64',
    a4: 'tablicę kodów base32',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Najbardziej podstawowy rodzaj kodowania znaków to ASCII. UNICODE to ogromne rozszerzenie zawierające znaki specjalne każdego języka oraz wiele innych symboli.',
  },
  {
    question:
      'Które z poniższych wymagań projektu to wymaganie niefunkcjonalne?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'bezpieczeństwo',
    a2: 'obsługa baz SQL',
    a3: 'obsługa REST API',
    a4: 'szyfrowanie haseł metodą SHA256',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wymagania niefunkcjonalne to np. bezpieczeństwo, schludny design, dostępność na wielu platformach.',
  },
  {
    question:
      'Które z poniższych wymagań projektu to wymaganie niefunkcjonalne?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'schludny wygląd',
    a2: 'obsługa baz SQL',
    a3: 'obsługa REST API',
    a4: 'szyfrowanie haseł metodą SHA256',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wymagania niefunkcjonalne to np. bezpieczeństwo, schludny design, dostępność na wielu platformach.',
  },
  {
    question:
      'Powinno się unikać częstego komunikowania się z bazą danych?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie pytamy bazy danych w pętli. Lepiej zrobić to raz a dobrze. Unikniemy w ten sposób problemów z wydajnością i przeciążania łącza/bazy danych.',
  },
  {
    question:
      'Powinno się unikać częstego komunikowania się z zewnętrznym API?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Nie pytamy API w pętli. Lepiej zrobić to raz a dobrze. Unikniemy w ten sposób problemów z wydajnością i przeciążania łącza lub serwera z API',
  },
  {
    question:
      'Biblioteka ORM czyli Object Relational Mapping jest wydajniejsza niż czyste zapytanie SQL?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'ORM wiele rzeczy ułatwia i automatyzuje, ale nigdy nie wiem jakie zapytanie wysłał pod spodem do bazy danych.',
  },
  {
    question:
      'Powinno się unikać korzystania ze wskaźników?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Wskaźniki są głównym źródłem błędów i luk w oprogramowaniu. Nowoczesne języki takie jak RUST podchodzą do tematu pamięci bardzo rygorystycznie i ograniczają możliwości wskaźników do potrzebnego minimum.',
  },
  {
    question:
      'Powinno się przekazywać parametry funkcji przez referencje jeżeli nie potrzebujemy kopii wartości?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli przekazujemy coś do funkcji a nie potrzebujemy by ta wykonała swoją kopię tej wartości to przekazujmy parametry przez referencję. Unikniemy wtedy zbędnych kopii w pamięci.',
  },
  {
    question:
      'Jednostka wykonująca kod to?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'wątek',
    a2: 'proces',
    a3: 'task',
    a4: 'konsola',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wątek jest jednostką wykonującą kod. Każdy program dostaje minimum jeden wątek. Każdy wątek ma swój context czyli stan procesora. Zamiana wątku na inny podmienia context na procesorze.',
  },
  {
    question:
      'Które z poniższych stwierdzeń o procesie i wątku jest prawdziwe?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'Proces to zbiór zawierający minimum jeden wątek',
    a2: 'Watek to zbiór zawierający minimum jeden proces',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Proces jest uruchamiany przez system z jednym wątkiem. Programista może uruchomić więcej wątków i robić z nimi co chce.',
  },
  {
    question:
      'Programista może programowo otworzyć wiele wątków w trakcie trwania programu?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Każdy program ma minimum jeden wątek?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Tylko wątek główny programu może dotykać elementów UI?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Bardzo dobrze widać to w C#. Dotykanie UI w metodzie asynchronicznej zwracającej Task kończy się rzuceniem wyjątku.',
  },
  {
    question:
      'Jeżeli wątek główny zakończy się (return w main zostanie osiągnięty) to program czeka na pozostałe wątki?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Jeżeli wyjdziemy z funkcji main (program się zakończy) to każdy wątek jest zabijany.',
  },
  {
    question:
      'Od programisty zależy czy wątek główny poczeka na zakończenie wątków dodatkowych?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Wątek główny powinien czekać na zakończenie wątków pobocznych lub sam zlecić ich zabicie.',
  },
  {
    question:
      'Zrównoleglenie polega na?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'podzieleniu zadania na części i wykonaniu każdej z nich na innym wątku',
    a2: 'podzieleniu zadania na części i wykonaniu każdej z nich w innym procesie',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Wymiana danych między wątkami przebiega swobodnie?',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Wątki wykonują zadania. Nie możemy odpytywać wątku o dane lub coś innego w dowolnym momencie. W najlepszym przypadku dostaniemy zły wynik, albo wynik nie pełny.',
  },
  {
    question:
      'Biblioteką języka C++ do tworzenia wątków jest?',
    cat:'C/C++',
    url: '',
    a1: 'std::thread',
    a2: 'Thread',
    a3: 'Task',
    a4: 'threading',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'std::thread to banalna w użyciu biblioteka obiektowa do tworzenia wątków.',
  },
  {
    question:
      'Biblioteką języka C# do tworzenia wątków jest?',
    cat:'C#',
    url: '',
    a1: 'Task',
    a2: 'std::thread',
    a3: 'Promise',
    a4: 'threading',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Task<T> to typ, służący do tworzenia wątków w C#.',
  },
  {
    question:
      'Rejestr procesora to?',
    cat:'Niskopoziomowe',
    url: '',
    a1: 'komórka pamięci w procesorze',
    a2: 'cache procesora',
    a3: 'instrukcja procesora',
    a4: 'rdzeń procesora',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Rejestr to mała komórka pamięci w procesorze o rozmiarze np jednego inta. Jest to najszybsza pamięć w komputerze.',
  },
  {
    question:
      'Część procesora nazywana ALU to?',
    cat:'Niskopoziomowe',
    url: '',
    a1: 'jednostka arytmetyczno logiczna',
    a2: 'jednostka sterująca',
    a3: 'kontroler pamięci',
    a4: 'kooprocesor',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'ALU - Arithmetic Logic Unit',
  },
  {
    question:
      'Część procesora nazywana CU to?',
    cat:'Niskopoziomowe',
    url: '',
    a1: 'jednostka sterująca',
    a2: 'jednostka arytmetyczno logiczna',
    a3: 'kontroler pamięci',
    a4: 'kooprocesor',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'CU - Control Unit',
  },
  {
    question:
      'Część procesora nazywana FPU to?',
    cat:'Niskopoziomowe',
    url: '',
    a1: 'kooprocesor',
    a2: 'jednostka sterująca',
    a3: 'jednostka arytmetyczno logiczna',
    a4: 'kontroler pamięci',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Jest to kooprocesor. FPU - Floating Point Unit.',
  },
  {
    question:
      'Część procesora odpowiedzialna za operacje zmiennoprzecinkowe to?',
    cat:'Niskopoziomowe',
    url: '',
    a1: 'kooprocesor',
    a2: 'jednostka licząca',
    a3: 'jednostka arytmetyczno logiczna',
    a4: 'mostek północny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Dawniej kooprocesor był osobnym układem. Dziś jest częścią procesora. Ma on swój zestaw instrukcji.',
  },
  //bhp
  {
    question:
      'Natężenie dźwięku na stanowisku pracy w biurze nie może przekraczać?',
    cat:'BHP',
    url: '',
    a1: '45 dB',
    a2: '55 dB',
    a3: '50 dB',
    a4: '40 dB',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Natężenie dźwięku na stanowisku pracy nie może przekraczać?',
    cat:'BHP',
    url: '',
    a1: '75 dB',
    a2: '55 dB',
    a3: '60 dB',
    a4: '65 dB',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Natężenie światła na stanowisku pracy powinno oscylować koło?',
    cat:'BHP',
    url: '',
    a1: '500 lx',
    a2: '400 lx',
    a3: '600 lx',
    a4: '700 lx',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Temperatura powietrza przy stanowisku pracy nie powinna być niższa niż?',
    cat:'BHP',
    url: '',
    a1: '18 stopni C',
    a2: '17 stopni C',
    a3: '19 stopni C',
    a4: '16 stopni C',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Kategorią zagrożeń na stanowisku pracy nie są?',
    cat:'BHP',
    url: '',
    a1: 'zagrożenia biologiczne',
    a2: 'zagrożenia chemiczne',
    a3: 'zagrożenia psychofizyczne',
    a4: 'zagrożenia fizyczne',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Kategorie: fizyczne, chemiczne, psychofizyczne.',
  },
  {
    question:
      'Zagrożeniem w środowiku pracy przypadającym do kategori zagrożeń chemicznych jest?',
    cat:'BHP',
    url: '',
    a1: 'alergia',
    a2: 'niedostateczne oświetlenie',
    a3: 'oparzenie',
    a4: 'porażenie prądem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Do zagrożeń chemicznych należy np. alergia na środki czystości',
  },
  {
    question:
      'Zagrożeniem w środowiku pracy przypadającym do kategori zagrożeń psychofizycznych jest?',
    cat:'BHP',
    url: '',
    a1: 'stres',
    a2: 'hałas',
    a3: 'szkodliwe działanie monitorów',
    a4: 'porażenie prądem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W tej kategori mamy do czynienia głównie ze stresem i przemęczeniem.'
  },
  {
    question:
      'Zagrożeniem w środowiku pracy przypadającym do kategori zagrożeń fizycznych jest',
    cat:'BHP',
    url: '',
    a1: 'wszystkie pozostałe',
    a2: 'hałas',
    a3: 'pożar',
    a4: 'upadek',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W tej kategori mamy do czynienia jeszcze z porażeniem prądem, niedostatecznym oświetleniem, szkodliwym działaniem monitorów oraz skaleczeniem.',
  },
  {
    question:
      'Odległość twarzy od monitora powinna wynosić minimum?',
    cat:'BHP',
    url: '',
    a1: '40cm',
    a2: '50cm',
    a3: '60cm',
    a4: '70cm',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Minimalna odległość klawiatury od krawędzi blatu to?',
    cat:'BHP',
    url: '',
    a1: '10cm',
    a2: '15cm',
    a3: '5cm',
    a4: '20cm',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Wilgotność przy stanowisku komputerowym nie może być niższa niż?',
    cat:'BHP',
    url: '',
    a1: '40%',
    a2: '50%',
    a3: '60%',
    a4: '30%',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Powierzchnia przypadająca na stanowisko pracy nie powinna być mniejsza niż?',
    cat:'BHP',
    url: '',
    a1: '2m2',
    a2: '3m2',
    a3: '1m2',
    a4: '2.5m2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Pracując przy komputerze należy?',
    cat:'BHP',
    url: '',
    a1: 'robić przerwę 5 minut co godzinę',
    a2: 'robić przerwę 5 minut co dwie godziny',
    a3: 'robić przerwę 10 minut co godzinę',
    a4: 'robić przerwę 10 minut co dwie godziny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Odległość między pracownikami siedzącymi tyłem do siebie powinna wynosić minimum?',
    cat:'BHP',
    url: '',
    a1: '80cm',
    a2: '90cm',
    a3: '100cm',
    a4: '120cm',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Stół powinien spełniać warunek?',
    cat:'BHP',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'wysokość stołu powinna pozwalać na swobodną pozycję z zachowaniem kąta prostego między ramieniem i przedramieniem',
    a3: 'kolana i uda siedzącego nie powinny dotykać blatu',
    a4: 'powierzchnia blatu powinna być jasna i matowa',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Fotel powinien spełniać warunek?',
    cat:'BHP',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'powinien być stabilny z możliwością obrotu o 350 stopni',
    a3: 'powinien pozwalać na swobodne poruszanie się',
    a4: 'powinien posiadać podłokietniki',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Poza tym powinien mieć regulowaną wysokość i wyprofilowane oparcie.',
  },
  {
    question:
      'Prawidłową i ergonomiczną pozycję pracy przy komputerze zapewni krzesło, którego',
    cat:'BHP',
    url: '',
    a1: 'oparcie zapewnia lordozę w odcinku lędźwiowym',
    a2: 'oparcie w odcinku szyi jest pochylone do przodu o 40stopni',
    a3: 'podłokietniki są 30 cm wyżej niż blat',
    a4: 'podłokietniki są 20 cm niżej niż blat',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Informatykowi na stanowisku pracy nie wolno?',
    cat:'BHP',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'spożywać posiłków',
    a3: 'palić tytoniu',
    a4: 'zastawiać otworów wentylacyjnych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Do środków ochrony zbiorowej zaliczamy?',
    cat:'BHP',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'oświetlenie',
    a3: 'wentylację i klimatyzację',
    a4: 'oddymianie i instalację odgromową',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Do środków ochrony indywidualnej zaliczamy?',
    cat:'BHP',
    url: '',
    a1: 'środki ochrony głowy',
    a2: 'wszelkiego rodzaju klimatyzację',
    a3: 'oddymianie',
    a4: 'wentylację',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Do środków ochrony indywidualnej zaliczamy?',
    cat:'BHP',
    url: '',
    a1: 'wszystkie wymienione',
    a2: 'środki ochrony głowy',
    a3: 'środki ochrony kończyn',
    a4: 'środki ochrony słuchu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Poza tym: środki ochrony twarzy i oczy oraz środki ochrony układu oddechowego.',
  },
  //szyfry i kodowania
  {
    question:
      'Wartość zakodowaną za pomocą "base64" da się odkodować?',
    cat:'Kryptografia',
    url: '',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Które zdanie o funkcji skrótu jest nieprawdziwe?',
    cat:'Kryptografia',
    url: '',
    a1: '"hash" uzyskany z funkcji skrótu jest odwracalny',
    a2: '"hash" uzyskany z funkcji skrótu jest nieodwracalny',
    a3: 'wynik funkcji skrótu to tak zwany "hash"',
    a4: 'funkcja skrótu przypisuje dowolnie dużej wartości krótką wartość o stałym rozmiarze',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Funkcją skrótu nie jest?',
    cat:'Kryptografia',
    url: '',
    a1: 'base64',
    a2: 'adler32',
    a3: 'md5',
    a4: 'sha-x',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Suma kontrolna to?',
    cat:'Kryptografia',
    url: '',
    a1: 'wartość obliczana na bazie danych, służąca do oceny czy dane te nie zostały uszkodzone lub zmodyfikowane',
    a2: 'zwykła funkcja skrótu',
    a3: 'synonim do słowa "hash"',
    a4: 'algorytm szyfrujący',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Suma kontrolna to specjalna wartość liczona z pewnej porcji danych. Służy do weryfikacji ich poprawności. Jeżeli transmitujemy coś np. przez bluetooth oprócz danych wysyłamy też do drugiego urządzenia sumę kontrolną tych danych. Drugie urządzenie liczy swoją sumę i porównuje ją z tą otrzymaną dzięki czemu wie czy dane zostały uszkodzone w drodze.',
  },
  {
    question:
      'Szyfr różni się od funkcji skrótu tym, iż?',
    cat:'Kryptografia',
    url: '',
    a1: 'da się go odwrócić',
    a2: 'nie da się go odwrócić',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Szyfr da się odwrócić. Szyfrogram to zaszyfrowana wiadomość.',
  },
  {
    question:
      'Podpis cyfrowy to?',
    cat:'Kryptografia',
    url: '',
    a1: 'sposób potwierdzania autentyczności danych',
    a2: 'kolejna metodologia szyfrowania danych',
    a3: 'forma sumy kontrolnej',
    a4: 'specjalny certyfikat przyznany konkretnym danym',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Podpis cyfrowy bazuje na szyfrowaniu asymetrycznym. Autor danych szyfruje dane swoim własnym kluczem prywatnym, który jest tajny. Odbiorca szyfruje dokument kluczem publicznym i porównuje zaszyfrowane dane.',
  },
  //pytania do kody na obrazku
  {
    question:
      'Kod C++ na obrazku wyświetli na ekranie napis?',
    cat:'C/C++',
    url: '/images/noseed.png',
    a1: 'Hello World',
    a2: 'Goodbye World',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Aby losowanie zwracało różne wyniki należy je poprawnie zainicjować za pomocą "srand(time(NULL));"',
  },
  {
    question:
      'Program na obrazku wywołany poleceniem "program.exe ASD QWE" wyświetli na ekranie napis?',
    cat:'C/C++',
    url: '/images/mainargs.png',
    a1: 'ASD',
    a2: 'QWE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Pod indeksem 0 tablicy argv znajduje się nazwa pliku wykonywalnego czyli "program.exe".',
  },
  {
    question:
      'Gdy wywołamy program na obrazku poleceniem "program.exe ASD QWE" zmienna argc będzie miała wartość?',
    cat:'C/C++',
    url: '/images/mainargs.png',
    a1: '3',
    a2: '2',
    a3: '1',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Pod indeksem 0 tablicy argv znajduje się nazwa pliku wykonywalnego czyli "program.exe".',
  },
  {
    question:
      'IDE w poniższym fragmencie programu pokazuje błąd, ponieważ',
    cat:'C/C++',
    url: '/images/staticarrayvar.png',
    a1: 'size nie jest stałą',
    a2: 'nie da się zaalokować tak dużej tablicy',
    a3: 'size musi być zmienną bez znaku',
    a4: 'składnia definicji tablicy jest błędna',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Rozmiar podany podczas inicjalizacji tablicy statycznej musi być stałą.',
  },
  {
    question:
      'Na poniższym obrazku widzimy',
    cat:'C/C++',
    url: '/images/alloc2darray.png',
    a1: 'proces alokowania tablicy 2D gdzie oba wymiary równe są "size"',
    a2: 'proces alokowania tablicy gdzie wymiar to "size"',
    a3: 'proces alokowania tablicy 2D gdzie każda podtablica ma inny wymiar',
    a4: 'proces zwalniania pamięci, która była przeznaczona na tablicę 2D',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Alokujemy tutaj tablicę 2D, która w obu wymiarach ma ten sam rozmiar.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C/C++',
    url: '/images/ucharcircle1.png',
    a1: '0',
    a2: '256',
    a3: '-128',
    a4: 'program zakończy się błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przekraczamy zakres unsigned chara, więc następuje powrót na początek zakresu.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C/C++',
    url: '/images/ucharcircle2.png',
    a1: '255',
    a2: '-1',
    a3: '0',
    a4: 'program zakończy się błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przekraczamy zakres unsigned chara, więc następuje powrót na koniec zakresu.',
  },
  {
    question:
      'Ile razy zobaczymy napis "Hello world!" na ekranie?',
    cat:'C/C++',
    url: '/images/infinitewhile.png',
    a1: 'nieskończenie wiele, ponieważ pętla jest nieskończona',
    a2: '2',
    a3: '1',
    a4: 'ani razu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda wartość inna niż 0 lub false jest równoznaczna prawdzie w C/C++.',
  },
  {
    question:
      'Ile razy zobaczymy napis "Hello world!" na ekranie?',
    cat:'C/C++',
    url: '/images/nowhile.png',
    a1: 'ani razu',
    a2: 'nieskończenie wiele, ponieważ pętla jest nieskończona',
    a3: '2',
    a4: '1',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Każda wartość inna niż 0 lub false jest równoznaczna prawdzie w C/C++.',
  },
  {
    question:
      'Co zobaczymy na ekranie po wykonaniu poniższego programu?',
    cat:'C/C++',
    url: '/images/printcharfromint.png',
    a1: 'B',
    a2: '66',
    a3: 'A',
    a4: 'wystąpi błąd programu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zgodnie z tablicą ASCII pod liczbę 66 podmapowany jest znak \'B\'. Specyfikator "%c" nakazuje interpretacje liczby jako znak.',
  },
  {
    question:
      'Ile razy zobaczymy napis "LOOP" na ekranie?',
    cat:'C/C++',
    url: '/images/infinitedowhile.png',
    a1: 'żadna z pozostałych opcji nie jest poprawna',
    a2: '0',
    a3: '1',
    a4: '2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W momencie sprawdzenia warunku licznik będzie miał wartość -1. Następnie -2, -3 itd. Tylko 0 to logiczny fałsz.',
  },
  {
    question:
      'Ile razy zobaczymy napis "LOOP" na ekranie?',
    cat:'C/C++',
    url: '/images/dowhile.png',
    a1: '1',
    a2: '0',
    a3: '3',
    a4: '2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W momencie sprawdzenia warunku licznik będzie miał wartość 0 co daje fałsz, ale pętla do while najpierw wykonuje iteracje a później sprawdza warunek. Pętla do while zawsze wykona się minimum raz.',
  },
  {
    question:
      'Ile razy zobaczymy napis "LOOP" na ekranie?',
    cat:'C/C++',
    url: '/images/while.png',
    a1: '0',
    a2: '1',
    a3: '3',
    a4: '2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W momencie sprawdzenia warunku licznik będzie miał wartość 0 co daje fałsz.',
  },
  {
    question:
      'Zmienna "wynik" będzie miała wartość?',
    cat:'C/C++',
    url: '/images/strcmp.png',
    a1: '>0',
    a2: '<0',
    a3: '0',
    a4: 'wystąpi błąd kompilacji',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wynik jest równy 0 gdy stringi są równe. Wynik >0 jeżeli pierwszy różniący się znak ma większą wartość w pierwszym stringu. Przez wartość rozumiemy reprezentację liczbową danego znaku.',
  },
  {
    question:
      'Na ekranie zostanie wypisane?',
    cat:'C/C++',
    url: '/images/strlen.png',
    a1: '14',
    a2: '15',
    a3: '13',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Funkcja ta zwraca długość napisu pod wskaźnikiem, ale nie liczy do długości 0 znajdującego się na końcu stringa.',
  },
  {
    question:
      'W poniższym kodzie błąd powoduje?',
    cat:'C/C++',
    url: '/images/dereferencevoidptr.png',
    a1: 'próba wyłuskania wskaźnika typu void*',
    a2: 'próba wyłuskania do innego typu zmiennej',
    a3: 'niepoprawna kombinacja operator-operand',
    a4: 'to, że wskaźnik może być wartością NULL',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '"void" to typ nie określony. Wskaźnik tego typu służy tylko do przechowywania adresu. Chcąc go wyłuskać należy wykonać castowanie na inny typ. W tym przypadku będzie to "int*".',
  },
  {
    question:
      'Poniższa konstrukcja pozwala warunkowo włączyć lub wyłączyć część kodu?',
    cat:'C/C++',
    url: '/images/ifdef.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Działa tu zasada znana z normalnych instrukcji warunkowych. W tym przypadku użyliśmy makra kompilatora. Jeżeli będzie ono zdefiniowane dołączony zostanie "iostream", jeżeli nie "cstdio".',
  },
  {
    question:
      'Poniższy fragment kodu pokazuje',
    cat:'C/C++',
    url: '/images/ifdebug.png',
    a1: 'Wszystkie pozostałe',
    a2: 'jak pozbyć szybko pozbyć się z kodu wynikowego elementów potrzebnych tylko w trakcie dewelopmentu',
    a3: 'jak usunąć część kodu zmieniając jedno makro',
    a4: 'jak podmienić/dodać fragment kodu pod pewnym warunkiem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Działa tu zasada znana z normalnych instrukcji warunkowych. W tym przypadku użyliśmy makra kompilatora. Jeżeli będzie ono zdefiniowane dołączony zostanie "iostream", jeżeli nie "cstdio".',
  },
  {
    question:
      'Jaką wartość zobaczymy na ekranie?',
    cat:'C/C++',
    url: '/images/macro.png',
    a1: '5',
    a2: '6',
    a3: '4',
    a4: '7',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Macro TO NIE FUNKCJA! Marco SUM(1,2) zostanie przed kompilacją podmienione na 1 + 2 co w konsekwencji da 1 + 2 * 2.',
  },
  {
    question:
      'Jaką wartość zobaczymy na ekranie?',
    cat:'C/C++',
    url: '/images/passtofunc.png',
    a1: '1 2 2',
    a2: '1 1 1',
    a3: '1 2 1',
    a4: '1 1 2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przekazywanie parametru do funkcji przez wartość, referencje i wskaźnik. Sposób na zwracanie wartości z funkcji bez słowa kluczowego "return".',
  },
  {
    question:
      'Jaką wartość zobaczymy na ekranie?',
    cat:'C/C++',
    url: '/images/lambda.png',
    a1: '10 20 99',
    a2: '0 0 99',
    a3: '0 10 20',
    a4: 'program zakończy się błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Nawias kwadratowy w lambdach języka C++ służy do przechwytywania zmiennych z otaczającego lambdę zakresu.',
  },
  {
    question:
      'Jaką wartość zobaczymy na ekranie?',
    cat:'C/C++',
    url: '/images/policpp.png',
    a1: 'BAZOWA/ChildA/ChildB/ChildA/ChildB',
    a2: 'BAZOWA/ChildA/ChildB/BAZOWA/BAZOWA',
    a3: 'BAZOWA/ChildA/ChildB/ChildA/BAZOWA',
    a4: 'BAZOWA/ChildA/ChildB/BAZOWA/ChildB',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Mamy tu do czynienia z polimorfizmem w C++. Słowo kluczowe "virtual" mówi programowi, że ma wybrać metodę do wykonania na bazie typu po prawej stronie operatora "new".',
  },
  {
    question:
      'Jaką wartość zobaczymy na ekranie?',
    cat:'C/C++',
    url: '/images/policpp2.png',
    a1: 'ChildA/ChildB/ChildA',
    a2: 'BAZOWA/BAZOWA/BAZOWA',
    a3: 'BAZOWA/ChildA/BAZOWA',
    a4: 'BAZOWA/ChildA/ChildB',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Mamy tu do czynienia z polimorfizmem w C++. Słowo kluczowe "virtual" mówi programowi, że ma wybrać metodę do wykonania na bazie typu po prawej stronie operatora "new".',
  },
  {
    question:
      'Na obrazku dostrzec można?',
    cat:'C/C++',
    url: '/images/kompozycja.png',
    a1: 'wzorzec kompozyt',
    a2: 'wzorzec fasada',
    a3: 'wzorzec budowniczy',
    a4: 'wzorzec singleton',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Klasa Point jest zależnością klasy MyApp. Jej obiekt jest wkomponowany w pole klasy MyApp.',
  },
  {
    question:
      'Na obrazku dostrzec można zastosowanie zasady?',
    cat:'C/C++',
    url: '/images/kompozycja.png',
    a1: 'dependency injection',
    a2: 'dependency inversion',
    a3: 'polimorfizm',
    a4: 'dekorację',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Klasa Point jest zależnością klasy MyApp. Jej obiekt jest wkomponowany w pole klasy MyApp za pomocą dependency injection.',
  },
  {
    question:
      'Na obrazku dostrzec można?',
    cat:'C#',
    url: '/images/singleton.png',
    a1: 'wzorzec singleton',
    a2: 'wzorzec kompozyt',
    a3: 'wzorzec prototyp',
    a4: 'wzorzec most',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Na obrazku widać klasyczne podejście do budowy wzorca singleton. Elementy charakterystyczne to prywatny konstruktor oraz statyczne pole na samego siebie.',
  },
  {
    question:
      'Środowisko podkreśla "Add" na poniższym obrazku, ponieważ?',
    cat:'C#',
    url: '/images/delegatereturnaction.png',
    a1: 'delegat typu Action służy do przechowywania metod, które zwracają void',
    a2: 'delegat typu Action służy do przechowywania metod, które zwracają bool',
    a3: 'nie podano typu zwracanego w parametrze generycznym typu Action',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Do delegatu typu "Action" możemy przypisywać tylko metody, które zwracają void (nic nie zwracają). Wszystkie parametry generyczne dotyczą parametrów funkcji.',
  },
  {
    question:
      'Środowisko podkreśla fragment kodu na poniższym obrazku, ponieważ?',
    cat:'C#',
    url: '/images/delegatepredicateint.png',
    a1: 'delegat typu Predicate służy do przechowywania metod, które zwracają bool',
    a2: 'delegat typu Predicate służy do przechowywania metod, które zwracają void',
    a3: 'nie podano typu zwracanego w parametrze generycznym typu Predicate',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Do delegatu typu "Predicate" możemy przypisywać tylko metody, które zwracają bool. Wszystkie parametry generyczne dotyczą parametrów funkcji.',
  },
  {
    question:
      'Środowisko podkreśla "Add" na poniższym obrazku, ponieważ?',
    cat:'C#',
    url: '/images/delegatefuncnotenoughparams.png',
    a1: 'nie podano typu zwracanego w parametrze generycznym typu Func',
    a2: 'delegat typu Func służy do przechowywania metod, które zwracają bool',
    a3: 'delegat typu Func służy do przechowywania metod, które zwracają void',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Do delegatu typu "Func" możemy przypisywać tylko metody, które zwracają cokolwiek. Ostatni parametr generyczny odnosi się do typu zwracanego przypisywanej metody.',
  },
  {
    question:
      'Podpowiedź środowiska mówi nam, że typ "Task" w konstruktorze przyjmuje?',
    cat:'C#',
    url: '/images/taskparam.png',
    a1: 'funkcję do wykonania, która nic nie zwraca i nie przyjmuje żadnych argumentów',
    a2: 'funkcję do wykonania, która nic nie zwraca i przyjmuje dowolną ilość parametrów',
    a3: 'funkcję do wykonania, która wraca bool i przyjmuje dowolną ilość parametrów',
    a4: 'funkcję do wykonania, która wraca bool i nie przyjmuje żadnych argumentów',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Podpowiedź środowiska mówi nam, że metoda "FirstOrDefault" przyjmuje?',
    cat:'C#',
    url: '/images/firstordefaultparam.png',
    a1: 'funkcję do wykonania, która zwraca bool i przyjmuje jednego inta',
    a2: 'funkcję do wykonania, która wraca inta, i przyjmuje jednego boola',
    a3: 'funkcję do wykonania, która nic nie zwraca i przyjmuje inta oraz boola',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Funkcja ta przyjmuje w parametrze funkcję zwracającą bool, która przyjmuje inta (inta, ponieważ jest to lista intów). Cała metoda FirstOrDefault zwróci pierwszy int z listy, który z callbacka da prawdę.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/reflist.png',
    a1: '4',
    a2: '2',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Lista w C# to typ referencyjny. Więc instancja listy została przekazana do funkcji przez referencję.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/passbyvalue.png',
    a1: '2',
    a2: '4',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Typ int jest typem wartościowym. W momencie przekazania do funkcji tworzy ona sobie jego kopię.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/arraynocopy.png',
    a1: '7',
    a2: '1',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Tablica jest typem referencyjnym. Do "tablica2" przypisaliśmy kopię referencji(wskaźnika) a nie kopię zawartości. Mamy więc dwie zmienne z tym samym adresem.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/linq1.png',
    a1: '4',
    a2: '3',
    a3: '5',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda FirstOrDefault() biblioteki LINQ zwraca pierwszy element kolekcji, który z podanego callbacka zwraca prawdę. Jeżeli kolekcja jest pusta lub żaden element nie spełnia wymogów zwracana jest domyślna wartość danego typu.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/linq2.png',
    a1: '0',
    a2: '1',
    a3: '2',
    a4: 'wystąpi błąd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda FirstOrDefault() biblioteki LINQ zwraca pierwszy element kolekcji, który z podanego callbacka zwraca prawdę. Jeżeli kolekcja jest pusta lub żaden element nie spełnia wymogów zwracana jest domyślna wartość danego typu.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/linq3.png',
    a1: 'wystąpi błąd',
    a2: '0',
    a3: '1',
    a4: '2',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda First() biblioteki LINQ zwraca pierwszy element kolekcji, który z podanego callbacka zwraca prawdę. Jeśli kolekcja jest pusta lub żaden element nie spełnia rzucany jest wyjątek.',
  },
  {
    question:
      'Czy w wyniku działania poniższego kodu rzucony zostanie wyjątek "NullReferenceException"?',
    cat:'C#',
    url: '/images/linq4.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Zmienna result otrzyma wartość "null". Próba wywołania właściwości "Name" zakończy się podanym wyjątkiem.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/linq5.png',
    a1: '2',
    a2: '0',
    a3: '1',
    a4: '3',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda Where() w przeciwieństwie do obu metod First() nie kończy się po znalezieniu pierwszego dopasowania. Zwraca ona IEnumerable<T> dlatego też należy użyć na wyniku ToList() aby przypisać wynik do zmiennej typu Listy.',
  },
  {
    question:
      'Poniższa konstrukcja wypisze wszystkie elementy kolekcji?',
    cat:'C#',
    url: '/images/linq6.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Poniższa konstrukcja pozwala sprawdzić czy element znajduje się w kolekcji?',
    cat:'C#',
    url: '/images/linq7.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'W niniejszej konstrukcji zastosowano sortowanie?',
    cat:'C#',
    url: '/images/linq.png',
    a1: 'rosnąco',
    a2: 'malejąco',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Do sortowania malejąco służy OrderByDescending()',
  },
  {
    question:
      'Wartość zmiennej "liczba" po powrocie z funkcji "Inkrementuj()" będzie miała wartość?',
    cat:'C#',
    url: '/images/refkw.png',
    a1: '3',
    a2: '2',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Słowo kluczowe "ref" służy do typów wartościowych do funkcji na zasadzie referencji.',
  },
  {
    question:
      'W obu klasach na rysunku zastosowano?',
    cat:'C#',
    url: '/images/props.png',
    a1: 'hermetyzację',
    a2: 'polimorfizm',
    a3: 'właściwości',
    a4: 'dziedziczenie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Klasa po prawej wykorzystuje do hermetyzacji właściwości, które są jak na ten moment unikalne dla C#. Oczywiście w klamrach od get i set można dorzucić więcej logiki niż tylko przypisanie.',
  },
  {
    question:
      'Co wyświetli się na ekranie?',
    cat:'C#',
    url: '/images/exceptions1.png',
    a1: 'DivideByZeroException: Dzielisz przez zero! (3)',
    a2: 'Exception: Dzielisz przez zero! (2)',
    a3: '0',
    a4: 'program się nie skompiluje',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'W tym przypadku zostanie złapany bardziej precyzyjny wyjątek, ponieważ został wskazany wcześniej.',
  },
  {
    question:
      'Dlaczego IDE podkreśla nazwę wyjątku?',
    cat:'C#',
    url: '/images/exceptions2.png',
    a1: 'Ponieważ wcześniejsza konstrukcja z "Exception" łapie już wszystko',
    a2: 'Nazwa wyjątku jest błędna',
    a3: 'Nie można używać wielu konstrukcji "catch" z jednym "try"',
    a4: 'Wyjątek został źle zdefiniowany',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Łapanie "Exception" łapie wszystkie wyjątki z niego dziedziczące, a jak wiadomo w C# każdy wyjątek dziedziczy z "Exception".',
  },
  {
    question:
      'Dlaczego IDE podkreśla nazwę interfejsu?',
    cat:'C#',
    url: '/images/interface.png',
    a1: 'Ponieważ klasa dziedzicząca z intefejsu nie implementuje wszystkich jego składowych',
    a2: 'Ponieważ metody wskazywane przez interfejs są prywatne',
    a3: 'Ponieważ puste ciało metody nie jest uznawane za implementację',
    a4: 'Żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wszystkie metody wymienione w interfejsie muszą zostać zaimplementowane choćby pustym ciałem.',
  },
  {
    question:
      'Dlaczego IDE podkreśla nazwę klasy?',
    cat:'C#',
    url: '/images/abstract.png',
    a1: 'Ponieważ klasa dziedzicząca z klasy abstrakcyjnej nie implementuje wszystkich jego składowych',
    a2: 'Ponieważ metody wskazywane przez klase abstrakcyjną nie są virtualne',
    a3: 'Ponieważ puste ciało metody nie jest uznawane za implementację',
    a4: 'Żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Wszystkie metody wymienione w klasie abstrakcyjnej muszą zostać zaimplementowane choćby pustym ciałem.',
  },
  {
    question:
      'Po wykonaniu poniższego kodu na ekranie pokaże się?',
    cat:'C#',
    url: '/images/csharppoli.png',
    a1: 'Base/ChildA/ChildB/ChildA/ChildB',
    a2: 'Base/ChildA/ChildB/Base/Base',
    a3: 'Base/ChildA/ChildB/Base/ChildB',
    a4: 'Base/ChildA/ChildB/ChildA/Base',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zastosowano tutaj standardowy polimorfizm. Dzięki "virtual" wywołanie metody zależy od typu obiektu a nie typu referencji (zmiennej).',
  },
  {
    question:
      'Po wykonaniu poniższego kodu na ekranie pokaże się?',
    cat:'C#',
    url: '/images/csharppoli2.png',
    a1: 'Base/ChildA/ChildB/',
    a2: 'Base/Base/Base',
    a3: 'Base/Base/ChildB',
    a4: 'Base/ChildA/Base',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zastosowano tutaj standardowy polimorfizm. Dzięki "virtual" wywołanie metody zależy od typu obiektu a nie typu referencji (zmiennej).',
  },
  {
    question:
      'Po wykonaniu poniższego kodu na ekranie pokaże się?',
    cat:'C#',
    url: '/images/csharppoli3.png',
    a1: 'Base/ChildA/ChildB/',
    a2: 'Base/Base/Base',
    a3: 'Base/Base/ChildB',
    a4: 'Base/ChildA/Base',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zastosowano tutaj standardowy polimorfizm. Dzięki "virtual" wywołanie metody zależy od typu obiektu a nie typu referencji (zmiennej).',
  },
  {
    question:
      'Wynikiem działania poniższego kodu będzie?',
    cat:'C#',
    url: '/images/operatoroverload.png',
    a1: '[4;6]',
    a2: '[1;2]',
    a3: '[3;4]',
    a4: 'wystąpi błąd',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zastosowano tutaj standardowe przeciążenie operatora "+".',
  },
  {
    question:
      'Dlaczego metoda "PrintMe()" podkreśla na czerwono pola "x" oraz "y"',
    cat:'C#',
    url: '/images/static.png',
    a1: 'nie można korzystać w składowych statycznych z elementów niestatycznych',
    a2: 'zarówno "x" jak i "y" nie są zainicjowane domyślną wartością',
    a3: 'zarówno "x" jak i "y" oznaczono jako prywatne',
    a4: 'zarówno "x" jak i "y" powinno się poprzedzić słowem kluczowym "this"',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Elementy statycznie nie mogą używać elementów niestatycznych klasy (potrzebny jest obiekt). W drugą stronę relacja jest możliwa.',
  },
  {
    question:
      'Poniższa klasa sugeruje budowę?',
    cat:'C#',
    url: '/images/tree1.png',
    a1: 'drzewa binarnego',
    a2: 'kolejki',
    a3: 'stosu',
    a4: 'mapy',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Chodzi o drzewo. Każdy węzeł drzewa oprócz wartości posiada dwie referencje (w c++ były by to wskaźniki) na opcjonalne dzieci. Drzewo jest binarne bo każdy węzeł ma maksymalnie dwoje dzieci',
  },
  {
    question:
      'Czy w poniższej implementacji zastosowano zasadę wstrzykiwania zależności?',
    cat:'C#',
    url: '/images/nodi.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Klasa "MailSender" jest wstawiona w pole klasy "MailMessage" na sztywno i nie da się jej zmienić z zewnątrz.',
  },
  {
    question:
      'Czy w poniższej implementacji zastosowano zasadę wstrzykiwania zależności?',
    cat:'C#',
    url: '/images/di.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Klasa "MailSender" jest wstawiona w pole klasy "MailMessage" przez konstruktor.',
  },
  {
    question:
      'Poniższą funkcję można nazwać poprawnym testem jednostkowym?',
    cat:'C#',
    url: '/images/unittest.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Poniższa asercja zakończy się?',
    cat:'C#',
    url: '/images/assertthrow.png',
    a1: 'negatywnie',
    a2: 'pozytywnie',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Asercja oczekuje rzucenia konkretnego wyjątku ("DivideByZero"), ale podaliśmy do funkcji poprawne dane, więc nie zostanie on rzucony. Test nie przejdzie.',
  },
  {
    question:
      'Poniższa asercja zakończy się?',
    cat:'C#',
    url: '/images/assertthrow2.png',
    a1: 'pozytywnie',
    a2: 'negatywnie',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Asercja oczekuje rzucenia konkretnego wyjątku ("DivideByZero"). Zgodnie z implementacją funkcji "divide" zostanie on rzucony, ponieważ b = 0',
  },
  {
    question:
      'Poniższe dwie asercje są równoznaczne?',
    cat:'C#',
    url: '/images/assert.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Różnią się tylko zapisem. Chociaż w przypadku typów referencyjnych należy uważać tak samo jak z operatorem "==" oraz metodą "Equals()".',
  },
  {
    question:
      'Poniższy test zakończy się sukcesem gdy?',
    cat:'C#',
    url: '/images/jest1.png',
    a1: 'wynik dzielenia będzie wynosił 3',
    a2: 'dzielenie wykona się bez błędów',
    a3: 'operacja rzuci wyjątek',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Poniższy test zakończy się sukcesem gdy?',
    cat:'C#',
    url: '/images/jest2.png',
    a1: 'operacja rzuci wyjątek',
    a2: 'wynik dzielenia będzie wynosił 3',
    a3: 'dzielenie wykona się bez błędów',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Aby test zakończył się sukcesem w miejsce "x" oraz "y" możemy wstawić odpowiednio?',
    cat:'C#',
    url: '/images/jest3.png',
    a1: '"11223366111" oraz "11"',
    a2: '"2312376524" oraz "23"',
    a3: '"ABCDEF98765" oraz "AB"',
    a4: 'wszystkie pozostałe kombinację są ok',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Co się stanie w momencie wciśnięcia przycisku PrintBtn?',
    cat:'C#',
    url: '/images/throwcallback.png',
    a1: 'zostanie rzucony wyjątek "NotImplementedException"',
    a2: 'nie stanie się nic',
    a3: 'program zacznie drukować pierwszy element listy "listItems"',
    a4: 'wyświetli się komunikat z błędem',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Visual Studio potrafi sam (po wciśnięciu tab po napisaniu += ) wygenerować pustą funkcje przypisywaną do zdarzenia. Funkcja ta zazwyczaj zawiera rzucenie wyjątku o braku implementacji.',
  },
  {
    question:
      'Zakładając, że na obrazku widzimy uproszczony diagram klas możemy powiedzieć, że?',
    cat:'C#',
    url: '/images/zaleznosc.png',
    a1: 'klasa Dokument jest zależnością klasy Katalog',
    a2: 'klasa Katalog jest zależnością klasy Dokument',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Zakładając, że na obrazku widzimy uproszczony diagram klas możemy powiedzieć, że?',
    cat:'C#',
    url: '/images/innerclass.png',
    a1: 'klasa Silnik jest klasą wewnętrzną klasy Samochód',
    a2: 'klasa Samochód jest klasą wewnętrzną klasy Silnik',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: '',
  },
  {
    question:
      'Dlaczego metoda "Point()" przedstawionej klasy nie ma typu zwracanego?',
    cat:'C#',
    url: '/images/umlclass.png',
    a1: 'ponieważ jest to konstruktor',
    a2: 'ponieważ jest to destruktor',
    a3: 'typu "void" nie wskazujemy na diagramie',
    a4: 'typ zwracany jest dynamiczny',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Po wywołaniu, poniższego skryptu języka Python w konsoli otrzymamy?',
    cat:'C#',
    url: '/images/pythonnoglobal.png',
    a1: '2',
    a2: '3',
    a3: 'wyjątek o braku definicji zmiennej "liczba"',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Aby wewnątrz funkcji języka Python odwołać się do zmiennej globalnej trzeba ją wcześniej w jej wnętrzu oznaczyć słowem kluczowym "global"',
  },
  {
    question:
      'Po wywołaniu, poniższego skryptu języka Python w konsoli otrzymamy?',
    cat:'Python',
    url: '/images/pythonglobal.png',
    a1: '3',
    a2: '2',
    a3: 'wyjątek o braku definicji zmiennej "liczba"',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Aby wewnątrz funkcji języka Python odwołać się do zmiennej globalnej trzeba ją wcześniej w jej wnętrzu oznaczyć słowem kluczowym "global"',
  },
  {
    question:
      'Po wywołaniu, poniższego skryptu języka Python w konsoli otrzymamy?',
    cat:'Python',
    url: '/images/mutlist.png',
    a1: '[0,2,3]',
    a2: '[1,2,3]',
    a3: 'treść błędu o tym, że lista jest tylko do odczytu',
    a4: 'nie otrzymamy nic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Po wywołaniu, poniższego skryptu języka Python w konsoli otrzymamy?',
    cat:'Python',
    url: '/images/tuplemut.png',
    a1: 'treść błędu o tym, że tuple jest tylko do odczytu',
    a2: '[0,2,3]',
    a3: '[1,2,3]',
    a4: 'nie otrzymamy nic',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Tuple to w Pythonie kontener read-only',
  },
  {
    question:
      'Dwie klasy języka Python przedstawione na obrazku mogą posłużyć do budowy?',
    cat:'Python',
    url: '/images/pythontree.png',
    a1: 'drzewa',
    a2: 'listy',
    a3: 'sterty',
    a4: 'stosu',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Obie klasy to najprostsze implementacje węzłów drzewa z jednym oraz dwojgiem dzieci.',
  },
  {
    question:
      'Klasa języka Python przedstawiona na obrazku...',
    cat:'Python',
    url: '/images/pythoninherit.png',
    a1: 'wszystkie pozostałe',
    a2: 'dziedziczy z klasy "Exception"',
    a3: 'wywołuje w konstruktorze konstruktor klasy bazowej',
    a4: 'posiada jedno pole',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Klasa języka Python przedstawiona na obrazku...',
    cat:'Python',
    url: '/images/pythoninherit.png',
    a1: 'jest definicją własnego wyjątku',
    a2: 'pozwala rzucać w swoich metodach wyjątki klasy "Exception"',
    a3: 'dziedziczy z interfejsu "Exception"',
    a4: 'przeciąża konstruktor klasy bazowej',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Które zdanie na temat poniższego skryptu języka Python jest nieprawdziwe',
    cat:'Python',
    url: '/images/pythonobject.png',
    a1: 'zmienna "ast" jest typu "string"',
    a2: 'zmienna "parser" jest typu "Parser"',
    a3: '"CodeSyntaxError" to nazwa wyjątku, który może zostać rzucony przez kod umieszczony w sekcji "try"',
    a4: 'zakomentowany fragment kodu wypisuje wiadomość, którą niesie łapany wyjątek',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Nie mamy takiej pewności. Możliwe, że jest to obiekt jakieś klasy, która przeciąża metodę "__repr__()" i dlatego da się go wypisać jak tekst.',
  },
  {
    question:
      'Poniższy fragment kody języka Python wygeneruje string zakończyny sekwencją ", "?',
    cat:'Python',
    url: '/images/pythonsubstring.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Ostatnia linia kodu odpowiada funkcji substring z innych języków.',
  },
  {
    question:
      'Konstrukcja "with" przedstawiona na obrazku powoduje w tym wypadku',
    cat:'Python',
    url: '/images/with.png',
    a1: 'że plik zostanie automatycznie zamknięty po wyjściu z niej',
    a2: 'każda próba otwarcia pliku w innym programie nie powiedzie się',
    a3: 'program sprawdzi czy plik nie jest używany w innym programie przed próbą otwarcia',
    a4: 'plik zostanie otwarty w trybie readonly',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Czy konstrukcja zapisana poniżej jest poprawna z punktu widzenia C#?',
    cat:'C#',
    url: '/images/nomutstring.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Stringi w C# są wartościami readonly.',
  },
  {
    question:
      'Czy konstrukcja zapisana poniżej jest poprawna z punktu widzenia C#?',
    cat:'C#',
    url: '/images/nomutstring2.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Stringi w C# są wartościami readonly.',
  },
  {
    question:
      'Czy implementacja zaprezentowana poniżej pozwala wykonać kopię typu referencyjnego?',
    cat:'C#',
    url: '/images/refcpy.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Klasa posiada metodę kopiującą, która alokuje nowy obiekt z takimi samymi wartościami. Jednak jest to tylko płytka kopia.',
  },
  {
    question:
      'Czy implementacja zaprezentowana poniżej pozwala wykonać kopię typu referencyjnego?',
    cat:'C#',
    url: '/images/refcpy2.png',
    a1: 'TAK',
    a2: 'NIE',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Klasa posiada metodę kopiującą, która alokuje nowy obiekt z takimi samymi wartościami, które też są kopiowane. Jest to kopia głęboka.',
  },
  {
    question:
      'Poniższy program wypisze na ekranie?',
    cat:'C/C++',
    url: '/images/dereferenceintptr.png',
    a1: '3',
    a2: '2',
    a3: '1',
    a4: '4',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Przesuwamy się w pamięci o (2*rozmiar inta) bajtów i wyłuskujemy wartość.',
  },
  {
    question:
      'Poniższy program wypisze na ekranie?',
    cat:'C/C++',
    url: '/images/sizeof.png',
    a1: '1',
    a2: '2',
    a3: '8',
    a4: '4',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zwracana jest ilość bajtów potrzebna do zapisania w pamięci wartości danego typu.',
  },
  {
    question:
      'Poniższy program wypisze na ekranie?',
    cat:'C/C++',
    url: '/images/sizeofstruct.png',
    a1: '8',
    a2: '16',
    a3: '32',
    a4: '24',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zwracana jest ilość bajtów potrzebna do zapisania w pamięci wartości danego typu. W przypadku tej struktury będzie to suma rozmiarów typów, z których składa się ta struktura.',
  },
  {
    question:
      'Ostatnim wypisanym elementem będzie?',
    cat:'C/C++',
    url: '/images/charloop.png',
    a1: '127',
    a2: '128',
    a3: '1',
    a4: '0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zakres chara to -128 do 127. Po dodaniu do 127 liczby 1 zakres się "przekręca" i dostajemy -128.',
  },
  {
    question:
      'Ostatnim wypisanym elementem będzie?',
    cat:'C/C++',
    url: '/images/charloop2.png',
    a1: '-128',
    a2: '128',
    a3: '127',
    a4: '-127',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zakres chara to -128 do 127. Po dodaniu do 127 liczby 1 zakres się "przekręca" i dostajemy -128.',
  },
  {
    question:
      'Ostatnim wypisanym elementem będzie?',
    cat:'C/C++',
    url: '/images/charloop3.png',
    a1: '-99',
    a2: '-100',
    a3: '-101',
    a4: '1',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zakres chara to -128 do 127. Po dodaniu do 127 liczby 1 zakres się "przekręca" i dostajemy -128.',
  },
  {
    question:
      'Ostatnim wypisanym elementem będzie?',
    cat:'C/C++',
    url: '/images/charloop4.png',
    a1: '-99',
    a2: '-100',
    a3: '-101',
    a4: '1',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zakres chara to -128 do 127. Po dodaniu do 127 liczby 1 zakres się "przekręca" i dostajemy -128.',
  },
  {
    question:
      'W obu przypadkach zostanie wypisane to samo?',
    cat:'C#',
    url: '/images/csequals.png',
    a1: 'NIE',
    a2: 'TAK',
    a3: '',
    a4: '',
    tag: 'NIEOFICJALNE',
    type: '2A',
    desc: 'Operator porownuje adresy w referencji. Metodę Equals możemy przeciążyć i zaimplementować dowolnie.',
  },
  {
    question:
      'Po wykonaniu poniższego programu w języku C# na ekranie zobaczymy?',
    cat:'C#',
    url: '/images/doubleparse1.png',
    a1: 'błąd',
    a2: 'Goodbye World',
    a3: 'Hello World',
    a4: 'kompilacja się nie powiedzie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda parse domyślnie oczekuje przecinka jako separatora części całkowitej i ułamkowej.',
  },
  {
    question:
      'Po wykonaniu poniższego programu w języku C# na ekranie zobaczymy?',
    cat:'C#',
    url: '/images/doubleparse2.png',
    a1: 'Goodbye World',
    a2: 'błąd',
    a3: 'Hello World',
    a4: 'kompilacja się nie powiedzie',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Metoda parse domyślnie oczekuje przecinka jako separatora części całkowitej i ułamkowej.',
  },
  {
    question:
      'Po wykonaniu poniższego programu w języku C# na ekranie zobaczymy?',
    cat:'C#',
    url: '/images/trynary.png',
    a1: '-1',
    a2: '1',
    a3: '0',
    a4: '10',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zastosowano to bardzo użyteczny operator trynarny nazywany mini ifem.',
  },
  {
    question:
      'Po wykonaniu poniższego programu w języku Python na ekranie zobaczymy?',
    cat:'C#',
    url: '/images/trynaryp.png',
    a1: '-1',
    a2: '1',
    a3: '0',
    a4: '10',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: 'Zastosowano to bardzo użyteczny operator trynarny nazywany mini ifem.',
  },
  {
    question:
      'Ile razy w konsoli zobaczymy napis "ASD"?',
    cat:'Javascript',
    url: '/images/jsvar.png',
    a1: '5',
    a2: '3',
    a3: '4',
    a4: 'wystąpi błąd, ponieważ nie zainicjowaliśmy zmiennej i w drugiej pętli',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Ile razy w konsoli zobaczymy napis "ASD"?',
    cat:'Javascript',
    url: '/images/jslet.png',
    a1: '3',
    a2: '5',
    a3: '4',
    a4: 'wystąpi błąd, ponieważ nie zainicjowaliśmy zmiennej i w drugiej pętli',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Która wartość zostanie wyświetlona w wyniku działania skryptu PHP?',
    cat:'PHP',
    url: '/images/sumaphp.png',
    a1: '15',
    a2: '10',
    a3: '5',
    a4: '0',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Zapisana w PHP instrukcja spowoduje wypisanie typu',
    cat:'PHP',
    url: '/images/gettypephp.png',
    a1: 'double',
    a2: 'string',
    a3: 'boolean',
    a4: 'integer',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Przedstawiona metoda jest implementacją algorytmu',
    cat:'PHP',
    url: '/images/fun1php.png',
    a1: 'odracającego napis',
    a2: 'wszukującego znak w napisie',
    a3: 'sprawdzającego czy napis jest palindromem',
    a4: 'sortującego znaki w napisie względem kodów ASCII',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Rezultatem wykonania przedstawionego fragmentu kodu jest wypisanie liczb z przedziału od 2 do 20, które są',
    cat:'Javascript',
    url: '/images/pierwsze.png',
    a1: 'pierwsze',
    a2: 'parzyste',
    a3: 'podzielne przez wartość zmiennej "test"',
    a4: 'podzielne przez wartość zmiennej "check"',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W metodach klasy GoldCustomer są widoczne jedynie pola',
    cat:'C#',
    url: '/images/customerclass.png',
    a1: 'GoldPoints, Name, Id',
    a2: 'GoldPoints, Name',
    a3: 'GoldPoints',
    a4: 'GoldPoints, Name, Id, Age',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Które zdarzenie jest wygenerowane, gdy nieaktywne okno lub kontrolka zostaje kliknięta myszą?',
    cat:'Webowe',
    url: '',
    a1: 'focus',
    a2: 'keyup',
    a3: 'validating',
    a4: 'blur',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Mechanizm obietnic (ang. promises) w języku JavaScript ma na celu?',
    cat:'Javascript',
    url: '',
    a1: 'obsłużyć funkcjonalność związaną z kodem asynchronicznym',
    a2: 'zastąpić mechanizm dziedziczenia w programowaniu obiektowym',
    a3: 'obsłużyć przechwytywanie błędów aplikacji',
    a4: 'poprawić czytelność kodu synchronicznego',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Metoda poszukiwań w tablicach posortowanych, która polega na podzieleniu tablicy na kilka bloków i wyszukaniu liniowym tylko w tym bloku, w którym docelowy element może się znajdować, w języku angielskim nosi nazwę?',
    cat:'Algorytmy',
    url: '',
    a1: 'Jump search',
    a2: 'Ternary search',
    a3: 'Binary search',
    a4: 'Exponential search',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Poniższy fragment pliku konfiguracyjnego to?',
    cat:'C#',
    url: '/images/manifest.png',
    a1: 'plik "AnroidManifest.xml" systemu Android',
    a2: 'plik "Info.plist" z uprawnieniami iOS',
    a3: 'plik zasobów stylów dla systemu Android',
    a4: 'żadne z pozostałych',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Zadaniem interpretera jest:',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'wykonanie skryptu instrukcja po instrukcji',
    a2: 'analiza składniowa całego programu',
    a3: 'optymalizacja kodu w celu szybszego wykonania',
    a4: 'przetłumaczenie kodu na kod maszynowy',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Przedstawiony zapis w języku Python prezentuje:',
    cat:'Python',
    url: '/images/pierwiastki.png',
    a1: 'tablicę asocjacyjną',
    a2: 'strukturę',
    a3: 'kolejkę LIFO',
    a4: 'stos',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Przedstawiony zapis w języku C# oznacza definicję klasy Car, która:',
    cat:'C#',
    url: '/images/vehicle.png',
    a1: 'dziedziczy po Vehicle',
    a2: 'jest klasą bazową',
    a3: 'korzysta z pól prywatnych klasy Vehicle',
    a4: 'jest zaprzyjaźniona z klasą Vehicle',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Założenie programowania obiektowego polegające na ukrywaniu składowych klasy tak, aby były one dostępne tylko metodom tej klasy lub funkcjom zaprzyjaźnionym, to',
    cat:'C#',
    url: '',
    a1: 'hermetyzacja',
    a2: 'wyjątki',
    a3: 'dziedziczenie',
    a4: 'polimorfizm',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Stosowanie wzorca Obserwator w programowaniu aplikacji WEB ma na celu:',
    cat:'Inżynieria oprogramowania',
    url: '',
    a1: 'powiadamianie obiektów o zmianie stanu innych obiektów',
    a2: 'dopasowanie interfejsu użytkownika do różnych typów użytkowników',
    a3: 'obsługę funkcji synchronicznych w kodzie aplikacji',
    a4: 'obserwowanie interakcji użytkownika i wysyłanie wyjątków',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Rezultatem wykonania przedstawionego fragmentu kodu jest wypisanie liczb z przedziału od 2 do 20, które są',
    cat:'Javascript',
    url: '/images/pierwsze_kod.png',
    a1: 'pierwsze',
    a2: 'parzyste',
    a3: 'podzielne przez wartość zmiennej test',
    a4: 'podzielne przez wartość zminnej check',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Przedstawiony wykres obrazuje wyniki testów:',
    cat:'Inżynieria oprogramowania',
    url: '/images/wydajnosc.png',
    a1: 'wydajnościowych',
    a2: 'bezpieczeństwa',
    a3: 'użyteczności',
    a4: 'funkcjonalności',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Przedstawiony fragment kodu w języku Java wypełnia tablicę wartościami:',
    cat:'C/C++',
    url: '/images/tp.png',
    a1: '2, 4, 6, 8, 10, 12, 14, 16, 18, 20',
    a2: '1, 2, 3, 4, 5, 6, 7, 8, 9, 10',
    a3: '2, 2, 2, 2, 2, 2, 2, 2, 2, 2',
    a4: '0, 1, 2, 3, 4, 5, 6, 7, 8, 9',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'Z tabeli przedstawiającej złożoność obliczeniową algorytmów sortowania na dowolnym, dużym, zbiorze wejściowym (ponad 100 elementów) wynika, że najszybszą metodą jest algorytm sortowania:',
    cat:'Algorytmy',
    url: '/images/zl.png',
    a1: 'przez zliczanie',
    a2: 'przez scalanie',
    a3: 'kubełkowego',
    a4: 'bąbelkowego',
    tag: 'OFICJALNE',
    type: '4A',
    desc: '',
  },
  //google dev tools
  {
    question:
      'W konsoli google chrome aby odwołać się do obecnie zaznaczonego elementu należy posłużyć się konstrukcją?',
    cat:'Webowe',
    url: '',
    a1: '$0',
    a2: '$this',
    a3: '$selected',
    a4: '$0',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
  {
    question:
      'W konsoli google chrome aby zobaczyć jakie eventy wykonują się na danym elemencie nalezy posłużyć się funkcją?',
    cat:'Webowe',
    url: '',
    a1: 'monitorEvents()',
    a2: 'events()',
    a3: 'see()',
    a4: 'monitorElement()',
    tag: 'NIEOFICJALNE',
    type: '4A',
    desc: '',
  },
];

export default questions;
